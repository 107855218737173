// MainChildren
const MainChildren = () => import("@/components/main-children.vue");
// 礼品卡列表
const giftCardList = () => import("@/views/gift-card/list");

//新建礼品卡
const createGiftCard = () => import("@/views/gift-card/create");

//编辑礼品卡
const editGiftCard = () => import("@/views/gift-card/edit");

//礼品卡详情
const giftCardDetail = () => import("@/views/gift-card/gift-card-detail");

// 兑换码详情
const redemptionCodeDetail = () =>
  import("@/views/gift-card/redemption-code-detail");

//礼品卡订单
const giftCardOrderList = () => import("@/views/gift-card/order-list");

const giftCardAllOrderList = () => import("@/views/gift-card/all-order-list");


export default {
  path: "gift-card",
  name: "礼品卡",
  component: MainChildren,
  children: [
    {
      path: "list",
      name: "礼品卡管理",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: giftCardList,
    },
    {
      path: "create-gift-card",
      name: "新建礼品卡",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: createGiftCard,
    },
    {
      path: "edt-gift-card",
      name: "编辑礼品卡",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: editGiftCard,
    },
    {
      path: "gift-card-detail",
      name: "礼品卡详情",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: giftCardDetail,
    },

    {
      path: "redemption-code-detail",
      name: "兑换码详情",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: redemptionCodeDetail,
    },

    {
      path: "order-list",
      name: "礼品卡订单",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: giftCardOrderList,
    },
    {
      path: "all-order-list",
      name: "礼品卡订单",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: giftCardAllOrderList,
    },
  ],
};
