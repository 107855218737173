<!--
 * @Author: sky.li
 * @LastEditors: sky.li
 * @Description: 商品分类列表
 * @注意：此组件只能在内部变化，不能在外部变化,当前业务为两级菜单，所以只做两级处理，并不做多级处理
 -->
<template>
  <el-cascader
    :options="options"
    :placeholder="placeholder"
    v-model="categoryActive"
    @change="setValue"
    @visible-change="visibleChange"
    :props="{
      checkStrictly: true,
      value: 'id',
      label: 'category_name',
      children: 'sub_product_categories',
      multiple: multiple,
    }"
    clearable
  ></el-cascader>
</template>

<script>
import { mapActions, mapState } from "vuex";

function getVal(e) {
  const [parentId, id] = e;
  let val = 0;
  if (!id) {
    val = parentId;
  } else {
    val = id;
  }
  return val;
}

function getActive(obj = {}, value) {
  let categoryActive;
  if (value || value === 0) {
    const categoryInfo = obj[value];
    if (categoryInfo) {
      if (!categoryInfo.parent_id) {
        categoryActive = [categoryInfo.id];
      } else {
        categoryActive = [categoryInfo.parent_id, categoryInfo.id];
      }
    }
  }
  return categoryActive;
}

export default {
  created() {
    console.log("goods category");
    this.getList();
  },
  data() {
    return {
      // options: [], // 分类多级显示所需数据
      categoryActive: [0], // 当前选中菜单
    };
  },
  computed: {
    ...mapState({
      options: function (state) {
        let re = JSON.parse(JSON.stringify(this.list[this.type] || []));

        if (this.style === "SINGLE") {
          re = re.map((item) => {
            delete item.sub_product_categories;
            return item;
          });
          if (this.multiple) {
            re.shift();
          }
          return re;
        }
        if (this.multiple) {
          ;
          re.shift();
          return re;
        } else {
          ;
          return re || [];
        }
      },
      list: (state) => state.mallGoodsCategory.list || {},
      obj: (state) => state.mallGoodsCategory.obj || {},
      style: (state) => state.mallGoodsCategory.style,
    }),
  },
  props: {
    value: {
      type: [String, Number, Array],
      default: 0,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "请选择分类",
    },
    type: {
      type: String,
      default: "shop",
    },
  },

  methods: {
    ...mapActions({
      getGoodsCategory: "mallGoodsCategory/getGoodsCategory",
      getStyle: "mallGoodsCategory/getStyle",
    }),
    async getList() {
      await this.getStyle();
      await this.getGoodsCategory(false);
      this.checkActive();
    },
    setValue(e) {
      let val;
      if (this.multiple) {
        val = e.map(getVal);
      } else {
        val = getVal(e);
      }
      ;
      this.$emit("input", val);
      this.$emit("change", val);
    },
    visibleChange(e) {
      this.$emit("visibleChange", e);
    },
    checkActive() {
      let val;
      if (this.multiple) {
        if ((val = this.value.map((item) => getActive(this.obj, item)))) {
          this.categoryActive = val;
        }
      } else {
        if ((val = getActive(this.obj, this.value))) {
          this.categoryActive = val;
        }
      }
    },
  },
  watch: {
    value: {
      handler() {
        this.checkActive();
      },
      deep: true,
    },
  },
};
</script>

<style lang="sass" scoped></style>
