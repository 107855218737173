/*
 * @Author: sky.li
 * @LastEditors: sky.li
 * @Description:
 * @可以输入预定的版权声明、个性签名、空行等
 */
/**
 * User: sheyude
 * Time: 上午 10:11
 *
 */

// 状态管理
import Vue from "vue";
import Vuex from "vuex";

import leftMenu from "./leftMenu";
import roleConfig from "./role-config";
import test from "./test";
import storage from "@/serving/storage.serving";
import simplet from "./simplet";
import drawer from "./drawer";
import vip from "./vip";
import unite from "./unite";
import mall from "./mall" ;
import visual from './visual/visual'
Vue.use(Vuex);

/**
 * 同步保存vuex 到缓存
 * @param {*} store
 */
const myPlugin = (store) => {
  store.subscribe((mutation, state) => {
    Object.keys(state).forEach((item) => {
      storage.set(state[item], item);
    });
  });
};

// 定义一个容器
let store = new Vuex.Store({
  modules: {
    // 接收各种模块
    leftMenu,
    // lang,
    roleConfig,
    test,
    simplet,
    drawer,
    vip,
    unite,
    visual,
    ...mall,
  },
  plugins: [myPlugin],
});

export default store;
