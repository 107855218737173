var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-cascader',{attrs:{"options":_vm.options,"placeholder":_vm.placeholder,"props":{
    checkStrictly: true,
    value: 'id',
    label: 'category_name',
    children: 'sub_product_categories',
    multiple: _vm.multiple,
  },"clearable":""},on:{"change":_vm.setValue,"visible-change":_vm.visibleChange},model:{value:(_vm.categoryActive),callback:function ($$v) {_vm.categoryActive=$$v},expression:"categoryActive"}})
}
var staticRenderFns = []

export { render, staticRenderFns }