import http from "@/serving/http.serving";

export default {
  // 群指令 列表
  list: (params) =>
    http.get(`/v1/merculetManagement/community/chatRobotCommands`, params),
  // 新增 群指令
  add: (params) =>
    http.post(`/v1/merculetManagement/community/chatRobotCommands`, params),
  // 编辑 群指令
  edit: (params) =>
    http.put(
      `/v1/merculetManagement/community/chatRobotCommands/${params.id}`,
      params
    ),
  // 删除 群指令
  delete: (params) =>
    http.delete(
      `/v1/merculetManagement/community/chatRobotCommands/${params.id}`,
      params
    ),
  // 群指令 详情
  details: (params) =>
    http.get(`/v1/merculetManagement/community/chatRobotCommands/${params.id}`),
};
