/**
 * 状态值处理
 */

 /**
 * author 盼飞
 */
class Status {
    constructor() {}
  
    /**
     * 设置 sessionStorage
     * @param value 状态值
     */
    getUserStatus(value) {
        let val = value
        switch(value)
        {
            case -3:
                // 邮箱验证中
                val = 'emailValidation'
                break;
            case -2:
                // 邮箱已验证未填充信息
                val = 'infoFilled'
                break;
            case -1:
                // 审核失败
                val = 'failed'
                break;
            case 1:
                // 审核通过
                val ='passed'
                break;
            default:
                // 邮箱验证中
                val = 'emailValidation'
        }
        return val
    }
  
    
  }
  export default new Status();
  