/**
 * @author Tianyuan.li
 * @email Tianyuan.li@magicwindow.cn
 * @date 1/11/19 11:45 AM
 * @description 视频素材库管理
 */

const vod = {
  path: "vod",
  meta: {
    name: "内容素材库",
    login: true,
    Authentication: true,
    noVerifyToken: false
  },
  component: () => import("@/components/main-children.vue"),
  children: [
    {
      path: "index",
      meta: {
        name: "内容素材库"
      },
      component: () => import("@/views/spt/vodManager/index.vue")
    },
    {
      path: "auth-failed",
      meta: {
        name: "审核失败记录"
      },
      component: () => import("@/views/spt/vodManager/auth-failed.vue")
    }
  ]
};

export default vod;
