import common from "./common.yaml";
import open from "./open.yaml";
import uat from "./uat.yaml";
import login from "./login.yaml";
import accountCenterTop from "./accountCenterTop.yaml";
import activity from "./activity.yaml";
import uav from "./uav.yaml";
import appList from "./app-list.yaml";
import statistics from "./statistics.yaml";
import system from "./system.yaml";
import event from "./event.yaml";
import components from "./components.yaml";
import reportForm from "./reportForm.yaml";
import newm from "./new.yaml";
import question from "./question.yaml";
import five31 from "./five31.yaml";
import platformInterests from "./platformInterests.yaml";
import certificate from "./certificate.yaml";
import simpletAuthorize from "./simpletAuthorize.yaml";
import sptCms from "./sptCms.yaml";
import visual from "./visual.yaml";
import power from "./power.yaml";
import authorization from "./authorization.yaml";
import market from "./market.yaml";
import dataReport from "./data-report.yaml";
import group from "./group.yaml";
import systemSetup from "./systemSetup.yaml";

import wallet from "./wallet-manage.yaml";

import leftMenu from "./left-menu.yaml";

import stationed from "./stationed.yaml";

import homePage from "./homePage.yaml";
import docking from "./docking.yaml";
import mall from "./mall.yaml";
import bonus from "./bonus.yaml";
import userManage from "./user-manage.yaml";
import robot from "./robot.yaml";
import activityCenter from "./activityCenter.yaml";
import operatingAssistant from "./operating-assistant.yaml";
import dashBoard from "./dashBoard.yaml";
import miniProgram from "./miniProgram.yaml";
import activitySign from "./activitySign.yaml";

// ===== 水晶星球部分命名前面加spt前缀 =====
import sptSimplet from "./sptSimplet.yaml";

import contract from "./contract.yaml";

// 语言模块
let objects = {
  common,
  uat,
  open,
  activity,
  statistics,
  login,
  accountCenterTop,
  uav,
  appList,
  system,
  event,
  wallet,
  components,
  reportForm,
  leftMenu,
  newm,
  question,
  five31,
  platformInterests,
  certificate,
  stationed,
  homePage,
  docking,
  simpletAuthorize,
  sptSimplet,
  sptCms,
  visual,
  power,
  authorization,
  market,
  dataReport,
  contract,
  group,
  mall,
  bonus,
  userManage,
  robot,
  activityCenter,
  operatingAssistant,
  dashBoard,
  miniProgram,
  activitySign,
  systemSetup
};

let object = {};
Object.keys(objects).forEach(key => {
  object = Object.assign(object, objects[key]);
});

export default object;
