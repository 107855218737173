/*
 * @Author: your name
 * @Date: 2020-08-11 13:43:38
 * @LastEditTime: 2023-09-14 15:37:46
 * @LastEditors: Zhaoheng && hellolad@126.com
 * @Description: In User Settings Edit
 * @FilePath: /mw-merculet-saas-web/src/config/hardCode.js
 */
const isProd = process.env.VUE_APP_EVENT === "prod";

const tickets = new Set(["yjbl", "green", "chml"]);
const queryUserStars = new Set(["smart", "lec"]);

//暂时只开放给碧要星球打样用，其他星球都不开，到时候这个功能收M水晶开放
const publishWhitelist = new Set(["m"]);

exports.hasTicket = function (input) {
  if (!isProd) {
    return true;
  }
  if (!input) {
    return false;
  }
  if (tickets.has(input.toLocaleLowerCase())) {
    return true;
  }
  return false;
};
// 创客模式
exports.isCreateGeek = function (input) {
  // if (!isProd) {
  //   return true;
  // }
  return input === "CREATE_GEEK";
};

exports.hasQueryUser = function (input) {
  if (!isProd) {
    return true;
  }
  if (!input) {
    return false;
  }
  if (queryUserStars.has(input.toLocaleLowerCase())) {
    return true;
  }
  return false;
};

exports.openPublishWhitelist = function (input) {
  if (!isProd) {
    return true;
  }
  if (!input) {
    return false;
  }
  if (publishWhitelist.has(input.toLocaleLowerCase())) {
    return true;
  }
  return false;
};

exports.hasHerActivity = function (data, shortName) {
  const passShortNames = ["CYB", "olrea", "JAM"];

  if (!passShortNames.includes(shortName)) {
    const foundIndex = data.findIndex((item) => item.url === "/main/statistics-manager");
    if (foundIndex > -1) {
      const foundedIndex = data[foundIndex].children.findIndex((item) => item.url === "/main/order-statistics/activity-statistics");
      if (foundedIndex > -1) {
        data[foundIndex].children.splice(foundedIndex, 1);
      }
    }
  }
  return data;
};

// 是否有共筹
exports.hasCrowdFund = function (data, shortName) {
  const passShortNames = ["YJBL", "M"];
  if (isProd && !passShortNames.includes(shortName)) {
    const foundIndex = data.findIndex((item) => item.url === "/main/star-co-creation-manage");
    if (foundIndex > -1) {
      data.splice(foundIndex, 1);
    }
  }
  return data;
};

exports.hasQueryUserLec = function (data, shortName) {
  const passShortNames = ["LEC", "SMART"];
  if (isProd && !passShortNames.includes(shortName)) {
    const foundIndex = data.findIndex((item) => item.url === "/main/user-manager");
    if (foundIndex > -1) {
      const foundedIndex = data[foundIndex].children.findIndex((item) => item.url === "/main/user/for-query-user");
      if (foundedIndex > -1) {
        data[foundIndex].children.splice(foundedIndex, 1);
      }
    }
  }
  return data;
};

exports.hasLedger = function (data, shortName) {
  const passShortNames = ["YJBL"];
  if (isProd && !passShortNames.includes(shortName)) {
    const foundIndex = data.findIndex((item) => item.url === "/main/ledger");
    if (foundIndex > -1) {
      data.splice(foundIndex, 1);
    }
  }
  return data;
};

exports.hasGiftCard = function (data, shortName) {
  shortName = shortName.toUpperCase();
  const passShortNames = ["CYB", "XIXI", "SIYUE", "YJBL", "CHML", "BYS", "MOGO"];
  if (!passShortNames.includes(shortName)) {
    const foundIndex = data.findIndex((item) => item.url === "/main/gift-card");
    if (foundIndex > -1) {
      data.splice(foundIndex, 1);
    }
    const foundMainIndex = data.findIndex((item) => item.url === "/main/cooperation-manager");
    if (foundMainIndex > -1) {
      const foundedIndex = data[foundMainIndex].children.findIndex((item) => item.url === "/main/planet-cooperation/unit/gift-card-list");
      if (foundedIndex > -1) {
        data[foundMainIndex].children.splice(foundedIndex, 1);
      }
    }
  }
  return data;
};

exports.hasStarTransaction = function (data, isEco) {
  if (!isEco) {
    const foundIndex = data.findIndex((item) => item.url === "/main/eco/eco-transaction");
    if (foundIndex > -1) {
      data.splice(foundIndex, 1);
    }
  }
  return data;
};

// 是否有角色管理的菜单
exports.hasPermission = function (data, shortName) {
  shortName = shortName.toUpperCase();
  const passShortNames = ["CHML", "YJBL"];
  if (isProd && !passShortNames.includes(shortName)) {
    const foundIndex = data.findIndex((item) => item.url === "/main/account-manager");
    if (foundIndex > -1) {
      const foundedIndex = data[foundIndex].children.findIndex((item) => item.url === "/main/system-setup/role-permissions-management");
      if (foundedIndex > -1) {
        data[foundIndex].children.splice(foundedIndex, 1);
      }
    }
  }
  return data;
};

const defaultShortNames = ["CYB", "JUTWS", "XIXI", "SIYUE", "YJBL", "CHML", "CESHI", "FOFO"];

// X计划所有支持的uatShortname
// const passXPlanShortNames = ["GOBI", "XINGN", ...defaultShortNames];

function _hasFunction(passShortNames) {
  let userInfo = sessionStorage.getItem("userInfo");
  try {
    userInfo = JSON.parse(userInfo);
  } catch (e) {}
  // const shortName = userInfo.app_uat.short_name.toUpperCase();
  return userInfo.eco_group_member_type;
}

function _hasSomeMenu(data, shortName, url, fatherUrl, passShortNames = passXPlanShortNames) {
  shortName = shortName.toUpperCase();
  if (!passShortNames.includes(shortName)) {
    const foundIndex = data.findIndex((item) => item.url === fatherUrl);
    if (foundIndex > -1) {
      const foundedIndex = data[foundIndex].children.findIndex((item) => item.url === url);
      if (foundedIndex > -1) {
        data[foundIndex].children.splice(foundedIndex, 1);
      }
    }
  }
  return data;
}

function _hasSomeMenuOfEcoGroupMemberType(data, ecoGroupMember, url, fatherUrl) {
  if (!ecoGroupMember) {
    const foundIndex = data.findIndex((item) => item.url === fatherUrl);
    if (foundIndex > -1) {
      const foundedIndex = data[foundIndex].children.findIndex((item) => item.url === url);
      if (foundedIndex > -1) {
        data[foundIndex].children.splice(foundedIndex, 1);
      }
    }
  }
  return data;
}
//数据统计下是否有的二级菜单 合伙人统计 统计明细 X计划概览 合伙人数据 项目数据 用户数据
//合伙人统计
exports.hasPartnerStatistic = function (data, shortName) {
  return _hasSomeMenuOfEcoGroupMemberType(data, shortName, "/main/order-statistics/partner-statistic", "/main/statistics-manager");
};
//统计明细
exports.hasStatisticDetail = function (data, shortName) {
  return _hasSomeMenuOfEcoGroupMemberType(data, shortName, "/main/order-statistics/statistic-detail", "/main/statistics-manager");
};
//X计划概览
exports.hasXplanOverview = function (data, shortName) {
  return _hasSomeMenuOfEcoGroupMemberType(data, shortName, "/main/order-statistics/xplan-overview", "/main/statistics-manager");
};

//x计划统计
exports.statisticsManagerhasXplanStatistics = function (data, shortName) {
  return _hasSomeMenuOfEcoGroupMemberType(data, shortName, "/main/order-statistics/xplan-statistics", "/main/statistics-manager");
};
//合伙人数据
exports.hasPartnerData = function (data, shortName) {
  return _hasSomeMenuOfEcoGroupMemberType(data, shortName, "/main/order-statistics/partners", "/main/statistics-manager");
};
//项目数据
exports.hasProjectData = function (data, shortName) {
  return _hasSomeMenuOfEcoGroupMemberType(data, shortName, "/main/order-statistics/projects", "/main/statistics-manager");
};

// 是否有赞助的菜单
exports.hasActivityCrowdFund = function (data, shortName) {
  return _hasSomeMenuOfEcoGroupMemberType(data, shortName, "/main/operating-assistant/activitys-crowdFunding", "/main/sale-plugin-manager");
};

// 是否有X计划战队的菜单（营销插件下）
exports.hasXplanTeam = function (data, shortName) {
  return _hasSomeMenuOfEcoGroupMemberType(data, shortName, "/main/operating-assistant/xplan-team", "/main/sale-plugin-manager");
};

// 是否有X计划统计的菜单（生态下）
exports.hasXplanStatistics = function (data, shortName) {
  return _hasSomeMenuOfEcoGroupMemberType(data, shortName, "/main/eco/xplan-statistics", "/main/eco");
};

// 是否有赞助的菜单
exports.hasActivityMessage = function (data, shortName) {
  return _hasSomeMenuOfEcoGroupMemberType(data, shortName, "/main/operating-assistant/support-message", "/main/sale-plugin-manager");
};

// 是否有战队宣言的菜单
exports.hasTeamMessage = function (data, shortName) {
  return _hasSomeMenuOfEcoGroupMemberType(data, shortName, "/main/operating-assistant/team-message", "/main/sale-plugin-manager");
};

// 是否有充值设置的菜单
exports.hasChargeSetting = function (data, shortName) {
  return _hasSomeMenuOfEcoGroupMemberType(data, shortName, "/main/operating-assistant/charge-setting", "/main/sale-plugin-manager");
};

// 是否有战队关系的菜单
exports.hasTeamRelation = function (data, shortName) {
  const passShortNames = ["LIVE", ...defaultShortNames];
  return _hasSomeMenu(data, shortName, "/main/distribute/team-relation", "/main/distribute-manager", passShortNames);
};

// 是否有X计划 返回bool
exports.hasCrowdFundBoolean = function () {
  return _hasFunction();
};

// 是否有礼品卡 返回bool值
exports.hasGiftCardBoolean = function () {
  const passShortNames = ["YJBL", "CHML", "BYS", "MOGO", "CESHI"];
  return _hasFunction(passShortNames);
};

// 是否有首次支付足额绑定
exports.firstFullPayBindBoolean = function () {
  return _hasFunction();
};

// 是否有扫码查看积分页面（只有生态星球才有）
exports.hasCheckPoints = function (data, shortName) {
  return _hasSomeMenuOfEcoGroupMemberType(data, shortName, "/main/operating-assistant/check-points", "/main/sale-plugin-manager");
};

// 营销插件下的合伙人申请
exports.hasPartnerApplyFor = function (data, shortName) {
  return _hasSomeMenuOfEcoGroupMemberType(data, shortName, "/main/operating-assistant/partner-applyFor", "/main/sale-plugin-manager");
};

exports.hasRolePermissionMenu = function (data, permission) {
  // console.log(data, permission);
  const path = "/main/system-setup/role-permissions-management";
  const found = permission.find((item) => item.path === path);
  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    if (item.children && item.children.length) {
      const foundIndex = item.children.findIndex((item) => item.url === path);
      if (foundIndex && !found) {
        item.children.splice(foundIndex, 1);
      }
    }
  }
  return data;
};
