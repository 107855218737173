/*
 * @Author: your name
 * @Date: 2021-09-23 20:05:10
 * @LastEditTime: 2021-09-24 18:39:35
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /mw-merculet-saas-web/src/api/setting.js
 */
import { base } from "@/config/index";
import http from "@/serving/http.serving";

export default {
  // 保存／编辑签到配置接口
  getBackgroundSettingList: () => http.httpGet(`${base}/setting/bg/list`),
  getBackgroundSettingCreate: (params) =>
    http.httpPost(`${base}/setting/bg/save`, params),
  getBackgroundSettingDelete: (params) =>
    http.httpGet(`${base}/setting/bg/del`, params),
  getIconShareList: () => http.httpGet(`${base}/icon/share/list`),
  setIconShareList: (params) => http.httpPost(`${base}/icon/share/saveOrUpdate`, params),
  shareresume: (params) => http.httpPost(`${base}/icon/share/resume`, params),

  getDomainConfig: () => http.httpGet(`${base}/setting/getDomainConfig`),
};
