// 星战队管理

const MainChildren = () => import("@/components/main-children.vue");

// 分销商品
const distributionSaleGoods = () => import("../views/group/distribution-sale/goods");

//会员卡分销
const distributionVipcard = () => import("@/views/group/distribution-sale/vipCards");

//战队关系设置
const teamRelationshipSettings = () => import("@/views/group/distribution-sale/team-relationship-settings");

//会员购买返利
const productRebate = () => import("@/views/group/distribution-sale/product-rebate");

//会员购买返利
const productRebateOrder = () => import("@/views/group/distribution-sale/product-rebate-order");

//商品供应激励
const supplyIncentive = () => import("@/views/group/distribution-sale/supply-incentive");

// 商品分销订单
const distributionSaleOrder = () => import("../views/group/distribution-sale/order");

// 会员卡分销订单
const distributionSaleVipcardsOrder = () => import("@/views/group/distribution-sale/vipcards-order");

// 分销业绩
const distributionSaleIncome = () => import("../views/group/distribution-sale/income");

// 分销等级佣金
const distributionSaleLevel = () => import("../views/group/distribution-sale/level");

// 分销设置
const distributionSaleSettings = () => import("../views/group/distribution-sale/settings");

// 提现记录
const distributionSaleWithdraw = () => import("../views/group/distribution-sale/apply-withdraw");
// 分销员提现记录
const distributorWithdrawRecord = () => import("../views/group/distribution-sale/distributor_withdraw_record");

// 门票分销
const distributionSaleTickets = () => import("../views/group/distribution-sale/ticket-list");

// 门票分销订单
const distributionSaleTicketsOrder = () => import("../views/group/distribution-sale/ticket-order");

export default {
  path: "distribute",
  name: "menu.distribute_manage.index",
  meta: {
    login: true,
    Authentication: true,
  },
  component: MainChildren,
  children: [
    {
      path: "goods",
      name: "menu.distribute_manage.goods",
      meta: {
        login: true,
        Authentication: true,
      },
      component: distributionSaleGoods,
    },
    {
      path: "order",
      name: "menu.distribute_manage.order",
      meta: {
        login: true,
        Authentication: true,
      },
      component: distributionSaleOrder,
    },
    {
      path: "vipcards-order",
      name: "会员卡分销订单",
      meta: {
        login: true,
        Authentication: true,
      },
      component: distributionSaleVipcardsOrder,
    },
    {
      path: "vipCards",
      name: "会员卡分销",
      meta: {
        login: true,
        Authentication: true,
      },
      component: distributionVipcard,
    },
    {
      path: "team-relationship-settings",
      name: "战队关系设置",
      meta: {
        login: true,
        Authentication: true,
      },
      component: teamRelationshipSettings,
    },
    {
      path: "supply-incentives",
      name: "商品供应激励",
      meta: {
        login: true,
        Authentication: true,
      },
      component: supplyIncentive,
    },
    {
      path: "product-rebate",
      name: "会员购买返利",
      meta: {
        login: true,
        Authentication: true,
      },
      component: productRebate,
    },
    {
      path: "product-rebate-order",
      name: "会员返利订单",
      meta: {
        login: true,
        Authentication: true,
      },
      component: productRebateOrder,
    },
    {
      path: "income",
      name: "menu.distribute_manage.income",
      meta: {
        login: true,
        Authentication: true,
      },
      component: distributionSaleIncome,
    },
    {
      path: "level",
      name: "menu.distribute_manage.level",
      meta: {
        login: true,
        Authentication: true,
      },
      component: distributionSaleLevel,
    },
    {
      path: "setting",
      name: "menu.distribute_manage.setting",
      meta: {
        login: true,
        Authentication: true,
      },
      component: distributionSaleSettings,
    },
    {
      path: "withdraw",
      name: "menu.distribute_manage.withdraw",
      meta: {
        login: true,
        Authentication: true,
      },
      component: distributionSaleWithdraw,
    },
    // 分销员提现记录
    {
      path: "distributor_withdraw_record",
      name: "menu.distribute_manage.distributor_withdraw_record",
      meta: {
        login: true,
        Authentication: true,
      },
      component: distributorWithdrawRecord,
    },
    // 合伙人业绩
    {
      path: "partner-performance",
      name: "menu.distribute_manage.partner-performance",
      meta: {
        login: true,
        Authentication: true,
      },
      component: () => import("../views/group/distribution-sale/partner-performance"),
    },
    // 合伙人配置
    {
      path: "partner-setting",
      meta: {
        name: "合伙人配置",

        login: true,
        Authentication: true,
      },
      component: () => import("../views/group/distribution-sale/partner-setting"),
    },
    // 合伙人业绩详情
    {
      path: "partner-performance-details",
      meta: {
        name: "合伙人业绩详情",

        login: true,
        Authentication: true,
      },
      component: () => import("../views/group/distribution-sale/partner-performance-details"),
    },
    {
      path: "tickets",
      name: "menu.distribute_manage.tickets",
      meta: {
        login: true,
        Authentication: true,
      },
      component: distributionSaleTickets,
    },
    {
      path: "ticket-order",
      name: "menu.distribute_manage.ticket-order",
      meta: {
        login: true,
        Authentication: true,
      },
      component: distributionSaleTicketsOrder,
    },
    {
      path: "distribution-template-manage",
      name: "自定义分销模版管理",
      meta: {
        login: true,
        Authentication: true,
      },
      component: () => import("../views/group/distribution-sale/distribution-template-manage"),
    },
    {
      path: "distribution-template",
      name: "自定义分销模版",
      meta: {
        login: true,
        Authentication: true,
      },
      component: () => import("../views/group/distribution-sale/distribution-template"),
    },
    {
      path: "team-relation",
      name: "战队关系",
      meta: {
        login: true,
        Authentication: true,
      },
      component: () => import("../views/group/distribution-sale/team-relation"),
    },
  ],
};
