<!--
说明：

-->

<!--
@update-time 5-11
@user tianyuan.li
@content 增加参数 fileSize:number width:number height:number fileType:array
fileSize： 单位为k 限制文件最大多少k 0为不限，默认0
width: 图片宽度 0为不限，默认0
height：图片高度 0为不限，默认0
file-type：图片类型， 写明具体图片类型，如 image/png,image/jpg,image/jpeg []为不限，默认[]
-->
<template>
  <el-upload
    ref='elUpload'
    :disabled="disabled"
    class="avatar-uploader"
    :action="action"
    :data="qiniu"
    :show-file-list="false"
    :on-error="handleAvatarError"
    :on-move="onRemove"
    :on-success="handleAvatarSuccess"
    :before-upload="beforeAvatarUpload"
  >
    <div
      v-if="imageUrl && uploadType == 'default'"
      class="avatar"
      :style="{ width: outerWidth + 'px',height: outerHeight + 'px', backgroundImage: `url(${imageUrl})` }"
    ></div>
    <i
      v-if="!imageUrl && uploadType == 'default'"
      class="el-icon-plus avatar-uploader-icon"
      :style="{ width: outerWidth + 'px',height: outerHeight + 'px',lineHeight:lineHeight+'px' }"
    ></i>
    <el-button v-if="uploadType == 'btn'" type="text">
      {{ $t(text) }}
      <i class="el-icon-upload el-icon--right"></i>
    </el-button>
  </el-upload>
</template>

<script>
import publicApi from "@/api/public";
import axios from 'axios'

async function LoadImageAsync(url) {
  var promise = new Promise(function (resolve, reject) {
    var image = new Image();
    image.onload = function () {
      resolve(image);
    };
    image.onerror = function () {
      reject(new Error("Could not load image "));
    };
    image.src = url;
  });
  return promise;
}

export default {
  name: "mw-upload",
  props: {
    value: {
      type: String,
    },
    text: {
      type: String,
      default: "uat.addform.update_icon",
    },
    uploadType: {
      type: String,
      default: "default",
    },
    fileSize: {
      type: Number,
      default: 0,
    },
    //图片存放的文件夹名称
    folderName: {
      type: String,
      default: "",
    },
    //图片存放的后缀名
    fileSuffix: {
      type: String,
      default: "",
    },
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
    fileType: {
      type: Array,
      default: () => [],
    },
    onRemove: {
      type: Function,
      default: function (obj) {
        console.log(obj);
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    outerWidth: {
      type: String,
      default: "178",
    },
    outerHeight:{
      type: String,
      default: "178",
    },
    lineHeight:{
      type: String,
      default: "178",
    }
  },
  data() {
    return {
      action: "",
      domain: "",
      imageUrl: "",
      qiniu: {
        token: "",
      },
    };
  },
  created() {
    // this.getpreupload(); //当页面有多个图片可以上传的时候，出现性能问题，改成需要的时候再获取
    this.imageUrl = this.value;
    if (process.env.VUE_APP_EVENT == "prod") {
      this.action = "https://upload.qiniup.com";
    } else {
      this.action = "https://upload.qiniup.com";
    }
  },
  watch: {
    value(newVlaue) {
      this.imageUrl = newVlaue;
    },
  },
  mounted() {},
  methods: {
    getpreupload() {
      let that = this
      return new Promise((resolve, reject) => {
        publicApi.preupload().then(
          (response) => {
            if (response.code === 0 && response.data) {
              let data = response.data;
              that.qiniu.token = data.uptoken;
              if (that.folderName) {
                that.qiniu.key = that.folderName + "/" + data.key;
              }
              if (that.fileSuffix) {
                if (!that.qiniu.key) {
                  that.qiniu.key = data.key;
                }
                that.qiniu.key = that.qiniu.key + "." + that.fileSuffix;
              }
              that.domain = data.domain;
            }
            resolve();
          },
          (err) => {
            reject();
          }
        );
      });
    },
    
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      if (res.key) {
        let url = `${this.domain}${res.key}`;
        this.$emit("input", url);
        // this.$emit("getFile", file.raw);
        try {
          this.$parent.$parent.$parent.validates();
        } catch (e) {}
      }
    },
    async beforeAvatarUpload(file) {
      await this.getpreupload()

      const validFileType =
          this.fileType.length > 0
            ? this.fileType.indexOf(file.type) >= 0
            : true;
        const isLimit = this.fileSize ? file.size / 1024 < this.fileSize : true;
        let isWidth = true;
        let isHeight = true;
        return new Promise((resolve, reject) => {
          if (this.width || this.height) {
            LoadImageAsync(window.URL.createObjectURL(file))
              .then((image) => {
                isWidth = this.width ? image.width <= this.width : true;
                isHeight = this.height ? image.height <= this.height : true;
                if (validFileType && isLimit && isWidth && isHeight) {
                  resolve();
                }
              })
              .catch((error) => {
                reject({
                  validFileType,
                  isLimit,
                  isWidth,
                  isHeight,
                });
              });
          } else {
            if (validFileType && isLimit) {
              resolve();
            } else {
              if (!validFileType) {
                this.$message.error(this.$t("common.uploadErr2"));
              }
              if (!isLimit) {
                // this.$message.error(this.$t("common.uploadErr3"));
                if(Math.round(this.fileSize / 1000) <= 0){
                  this.$message.error(`请检查您的文件上传大小，最大为${this.fileSize}KB`);
                }else{
                  this.$message.error("请检查您的文件上传大小，最大为" + Math.round(this.fileSize / 1000) + "M");
                }
              }
              return;
              //reject({ validFileType, isLimit });
            }
          }
        });
    },
    handleAvatarError(type) {
      console.log(type);
      this.$message.error("上传失败");
      this.$emit("input", "");
    },
  },
};
</script>

<style scoped>
.avatar {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
</style>
