/*
 * @Author: sky.li
 * @LastEditors: sky.li
 * @Description:
 * @可以输入预定的版权声明、个性签名、空行等
 */

const MainChildren = () => import("@/components/main-children.vue");

export default {
  path: "activity-manager",
  name: "activity-manager.name",
  component: MainChildren,
  children: [
    {
      path: "prize",
      meta: {
        login: true,
        name: "抽奖活动"
      },
      component: MainChildren,
      children: [
        {
          path: "add",
          meta: {
            name: "新建抽奖活动",
            login: true
          },
          component: () => import("@/views/activity-manage/prize/prize-add")
        },
        {
          path: "edit",
          meta: {
            name: "编辑抽奖活动",
            login: true
          },
          component: () => import("@/views/activity-manage/prize/prize-edit")
        },
        {
          path: "list",
          meta: {
            name: "抽奖活动",
            login: true
          },
          component: () => import("@/views/activity-manage/prize/prize-list")
        },
        {
          path: "records/:id",
          name: "抽奖信息",
          meta: {
            login: true
          },
          component: () => import("@/views/activity-manage/prize/prize-records")
        },
        {
          path: "info/:id",
          meta: {
            name: "抽奖详情",
            login: true
          },
          component: () => import("@/views/activity-manage/prize/prize-info")
        },
        {
          path: "recordsInfo/:id",
          meta: {
            name: "抽奖信息",
            login: true
          },
          component: () =>
            import("@/views/activity-manage/prize/prize-record-info")
        }
      ]
    }
  ]
};
