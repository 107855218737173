/*
 * @Author: sky.li
 * @LastEditors: Please set LastEditors
 * @Description:
 * @可以输入预定的版权声明、个性签名、空行等
 */

export default {
  
  path: "assets",
  meta: {
    name: "资产管理",

    login: true,
    Authentication: true
  },
  component: () => import("@/components/main-children"),
  children: [
    {
      path: "uat_manage",
      meta: {
        name: "水晶管理",
        login: true,
        Authentication: true
      },
      component: () => import("@/views/certificate/certificate-index")
    },
    {
      path: "mall_assets",
      meta: {
        name: "商户资产",

        login: true,
        Authentication: true
      },
      component: () => import("@/views/group/mall/property")
    },
    {
      path: "vod-recharge",
      meta: {
        name: "视频流量充值",

        login: true,
        Authentication: true
      },
      component: () => import("@/views/assets/vod-recharge/index.vue")
    },
    {
      path: "pay_setting",
      meta: {
        name: "支付设置",

        login: true,
        Authentication: true
      },
      component: () => import("@/views/group/setting-pay")
    },
    {
      path: "sms-statistical",
      meta: {
        name: "短信统计",

        login: true,
        Authentication: true
      },
      component: () => import("@/views/assets/sms-statistical")
    },
  ]
};
