/**
 * @author Tianyuan.li
 * @email Tianyuan.li@magicwindow.cn
 * @date 2018/4/12 下午12:16
 * @description
 */

export default function (input) {

  let d;
  try {
    d = new Date(input)
  } catch (err) {
    return ''
  }
  let year = d.getFullYear();
  let month = d.getMonth() + 1;
  if (month < 10) {
    month = '0' + month
  }
  let day = d.getDate() < 10 ? '0' + d.getDate() : '' + d.getDate();
  let hour = d.getHours() < 10 ? '0' + d.getHours() : '' + d.getHours();
  let minutes = d.getMinutes() < 10 ? '0' + d.getMinutes() : '' + d.getMinutes();
  let seconds = d.getSeconds() < 10 ? '0' + d.getSeconds() : '' + d.getSeconds();

  return year + '-' + month + '-' + day + ' ' + hour + ':' + minutes + ':' + seconds;
}
