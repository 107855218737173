<!--
 * @Description: 侧滑弹出层组件
 * @Author: panfei.guan
 * @Date: 2018-11-26 16:46:54
 * @LastEditors: panfei.guan
 * @LastEditTime: 2018-11-29 10:50:00
 -->

<template>
  <div class="mer-drawer" :class="{ 'is-fullscreen': fullscreen }" ref="drawer" :style="{ width: width, right: visible ? '1px' : `-${width}` }">
    <div class="mer-drawer__header flex between align-items-center">
      <slot name="title">
        <span class="mer-drawer__title">{{ title }}</span>
      </slot>
      <i class="mer-drawer__close iconfont icon-guanbi" @click="handleClose"></i>
    </div>
    <div class="mer-drawer__body"><slot></slot></div>
    <div class="mer-drawer__footer flex align-items-center" v-if="$slots.footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "MerDrawer",
  model: {
    prop: "visible",
    event: "update",
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },

    width: {
      type: String,
      default: "670px",
    },

    fullscreen: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      show: false,
    };
  },
  created() {},
  beforeDestroy() {
    // 组件实例销毁之前调用
    this.visibleChange(false);
  },
  mounted() {
    this.show = this.visible;
    this.visibleChange(this.visible);
  },
  watch: {
    visible(val) {
      this.visibleChange(val);
    },
  },

  computed: {},

  methods: {
    ...mapActions(["setOverlay"]),
    // 组件打开或关闭
    visibleChange(val) {
      if (val) {
        // 组件打开触发open事件
        this.$emit("open", val);
      } else {
        // 组件关闭触发open事件
        this.$emit("close", val);
      }
      // 修改store中的数据显示隐藏遮罩层
      this.setOverlay(val);

      this.show = val;
      // this.$emit('change', this.show);
    },
    handleClose() {
      this.show = false;
      this.$emit("update:visible", this.show);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "ele/var.scss";
.mer-drawer {
  height: calc(100% - 60px);
  background-color: #fff;
  position: fixed;
  top: 61px;
  right: 1px;
  z-index: 2001;
  transition: right 0.2s;
  &.is-fullscreen {
    width: 100% !important;
  }
  .mer-drawer__header {
    height: 60px;
    border-bottom: 1px solid $border;
    padding: 0 22px;
    font-size: 16px;
    box-sizing: border-box;
    .mer-drawer__title {
      color: $color-title;
    }
    .mer-drawer__close {
      color: $color-body;
      cursor: pointer;
      &:hover {
        color: $color-title;
      }
    }
  }
  .mer-drawer__body {
    padding: 30px 20px;
    height: calc(100% - 60px - 66px);
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .mer-drawer__footer {
    height: 66px;
    border-top: 1px solid $border;
    padding: 0 22px;
    box-sizing: border-box;
  }
}
</style>
