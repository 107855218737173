let state = ({
    overlayShow: false,

})

const getters = {
	overlayShow: (state, getters) => state.overlayShow,
}

const actions = {
	setOverlay({commit}, data) {
		commit('setOverlay', data)
	}
}
const mutations = {
	setOverlay(state, data) {
        state.overlayShow = data
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}