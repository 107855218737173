const MainChildren = () => import("@/components/main-children.vue");

//签到列表
const signInList = () => import("../views/activityCenter/signIn/index");

//新建签到
const signInAdd = () => import("../views/activityCenter/signIn/add");

//签到统计
const signInStatistics = () =>
  import("../views/activityCenter/signIn/statistics");

export default {
  path: "signIn",
  name: "签到",
  component: MainChildren,
  children: [
    {
      path: "list",
      name: "signIn.index.name",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
        name: "签到"
      },
      component: signInList
    },
    {
      path: "add",
      name: "signIn.add.name",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: signInAdd
    },
    {
      path: "statistics",
      name: "statistics",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
        name: "签到统计"
      },
      component: signInStatistics
    },
    {
      path: "visual",
      name: "签到页配置",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
        name: "签到页配置"
      },
      component: () => import("@/views/activityCenter/signIn/visual.vue")
    }
  ]
};
