const MainChildren = () => import("@/components/main-children.vue");

import unitRouter from "./unit";

export default {
  path: "planet-cooperation",
  name: "星球赋能",
  component: MainChildren,
  children: [unitRouter]
};
