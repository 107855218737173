/*
 * @Author: sky.li
 * @LastEditors: sky.li
 * @Description:
 * @可以输入预定的版权声明、个性签名、空行等
 */

import http from "@/serving/http.serving";

export default {
  //新增分类
  saveCategory: (params) =>
    http.post(`/v1/merculetManagement/ecshop/product/category/save`, params),
  //获取分类列表
  getCategoryList: (params) =>
    http.get(`/v1/merculetManagement/ecshop/product/category/list`, params),
  // 不区分类型获取分类列表
  getCategoryListAll: (params) =>
    http.get(`/v1/merculetManagement/ecshop/product/category/list/all`, params),
  //删除分类
  deleteCategory: (params) =>
    http.get(`/v1/merculetManagement/ecshop/product/category/delete/${params}`),
  //获取商品分类展示类型
  queryStyle: () =>
    http.get(`/v1/merculetManagement/ecshop/product/category/queryStyle`),
  /**
   *  获取商品分类展示类型
   * @param params {Object}
   * @param params.category_style {String}   SINGLE|MULTILEVEL
   * MULTILEVEL
   * SINGLE: 一级分类
   * MULTILEVEL: 二级分类
   */
  updateStyle: (params) =>
    http.get(
      `/v1/merculetManagement/ecshop/product/category/updateStyle`,
      params
    ),
};
