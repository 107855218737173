const MainChildren = () => import("@/components/main-children.vue");

//拼团列表
const pinList = () => import("../views/activityCenter/pinMall/index");

//拼团详情
const pinDetail = () => import("../views/activityCenter/pinMall/pin-detail");

export default {
  path: "pinMall",
  name: "拼团",
  component: MainChildren,
  children: [
    {
      path: "list",
      meta: {
        name: "多人拼团",

        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: pinList
    },

    {
      path: "detail",
      meta: {
        name: "拼团详情",

        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: pinDetail
    }
  ]
};
