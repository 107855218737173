/*
 * @Author: your name
 * @Date: 2021-03-18 15:41:26
 * @LastEditTime: 2021-03-24 17:40:25
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /mw-merculet-saas-web/src/router/star-co-creation-manage.js
 */
const MainChildren = (resolve) => {
  require.ensure([], () => {
    resolve(require("@/components/main-children.vue"));
  });
};

export default {
  path: "star-co-creation-manage",
  name: "星球共创管理",
  meta: {
    name: "星球共创管理"
  },
  component: MainChildren,

  // component: () => import("@/views/star-co-creation-manage/list"),
  children: [
    {
      path: "",
      meta: {
        name: "星球共创管理",
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("@/views/star-co-creation-manage/list")
    },
    {
      path: "info",
      meta: {
        name: "项目详情",
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("@/views/star-co-creation-manage/info")
    },
    
    {
      path: "sub-project-list",
      meta: {
        name: "项目详情",
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("@/views/star-co-creation-manage/sub-project-list")
    },
  ]
};
