<!--
说明：

-->

<!--
@update-time 5-11
@user tianyuan.li
@content 增加参数 fileSize:number width:number height:number fileType:array
fileSize： 单位为k 限制文件最大多少k 0为不限，默认0
width: 图片宽度 0为不限，默认0
height：图片高度 0为不限，默认0
file-type：图片类型， 写明具体图片类型，如 image/png,image/jpg,image/jpeg []为不限，默认[]
default-image: 默认图片，没有图片时的默认图片，如果没有，显示+
callback: 上传成功后的回调

@update-time 9-20
@user tianyuan.li
@content
修正默认为正方形，可以重新定义大小
增加默认图片
图片大小，默认为填充全部
-->
<template>
  <div class="uploader-box" :class="{isError:isError}">
    <el-upload
      class="uploader"
      :action="action"
      :show-file-list="false"
      :data="qiniu"
      :on-error="handleAvatarError"
      :on-move="onRemove"
      :on-success="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload"
      :disabled="pageName === 'shelf-class-goods'"
    >
      <div class="upload-box">
        <div
          class="upload-img"
          :style="getBakckground(imageUrl)"
          v-if="imageUrl && uploadType== 'default'"
        ></div>

        <i v-if="!imageUrl||uploadType==='hand'" class="el-icon-plus"></i>
        <el-button v-if="uploadType == 'btn'" size="small">
          <!--{{$t(text)}}-->
          上传图片
          <i class="el-icon-upload el-icon--right"></i>
        </el-button>
      </div>
    </el-upload>
    <slot></slot>
  </div>
</template>

<script>
import publicApi from "@/api/public";

async function LoadImageAsync(url) {
    var promise = new Promise(function(resolve, reject) {
        var image = new Image();
        image.onload = function() {
            resolve(image);
        };
        image.onerror = function() {
            reject(new Error("Could not load image "));
        };
        image.src = url;
    });
    return promise;
}

export default {
    name: "mw-upload",
    props: {
        value: {
            type: String
        },
        text: {
            type: String,
            default: "uat.addform.update_icon"
        },
        uploadType: {
            type: String,
            default: "default"
        },
        fileSize: {
            type: Number,
            default: 0
        },
        width: {
            type: Number,
            default: 0
        },
        height: {
            type: Number,
            default: 0
        },
        fileType: {
            type: Array,
            default: () => []
        },
        onRemove: {
            type: Function,
            default: function(obj) {
                console.log(obj);
            }
        },
	    defaultImage:{
            type: String,
            default: ""
        },
	    isError:{
            type: Boolean,
            default: false
        },callback:{
            type: Boolean,
            default: false
        },
	    successCallback:{
        	type:Function,
            default: function(obj) {
                console.log(obj);
            }
        },
        pageName: {
            type: String,
            default: ''
        }

    },
    data() {
        return {
            action:'//upload.qiniup.com',
            domain: "",
            imageUrl: "",
            qiniu: {
                token: ""
            }
        };
    },
    created() {
        this.imageUrl = this.value;
        this.getpreupload()
    },
    watch: {
        value(newVlaue) {
            this.imageUrl = newVlaue;
        },

    },
    mounted() {},
    methods: {
        async getpreupload() {
            let { code, data } = await publicApi.preupload();
            if (code === 0) {
                this.qiniu.token = data.uptoken;
                this.domain = data.domain;
            } else {
                this.getpreupload();
            }
        },
        handleAvatarSuccess(res, file) {
            this.imageUrl = URL.createObjectURL(file.raw);
            if (res.key) {
                let url = `${this.domain}${res.key}`;
                if(this.callback){
	                this.successCallback(url)

                }else{
	                this.$emit("input", url);

                }
                try {
                    this.$parent.$parent.$parent.validates();
                } catch (e) {}
               this.$emit('callback',url)
            }

        },
        beforeAvatarUpload(file) {

            const validFileType =
                this.fileType.length > 0
                    ? this.fileType.indexOf(file.type) >= 0
					: true;
            const isLimit = this.fileSize
                ? file.size / 1024 < this.fileSize
                : true;
            let isWidth = true;
            let isHeight = true;
            return new Promise((resolve, reject) => {
                if (this.width || this.height) {
                    LoadImageAsync(window.URL.createObjectURL(file))
                        .then(image => {
                            isWidth = this.width
                                ? image.width <= this.width
                                : true;
                            isHeight = this.height
                                ? image.height <= this.height
                                : true;
                            if (
                                validFileType &&
                                isLimit &&
                                isWidth &&
                                isHeight
                            ) {
                                resolve();
                            }
                        })
                        .catch(error => {
                            reject({
                                validFileType,
                                isLimit,
                                isWidth,
                                isHeight
                            });
                        });
                } else {
                    if (validFileType && isLimit) {
                        resolve();
                    } else {

						if (!validFileType) {
							this.$message.error(this.$t('common.uploadErr2'));
						}
						if (!isLimit) {
							// this.$message.error(this.$t('common.uploadErr3'));
                            this.$message.error("请检查您的文件上传大小，最大为" + Math.round(this.fileSize / 1000) + "M");
						}
						return ;
                        //reject({ validFileType, isLimit });
                    }
                }
            });
        },
        handleAvatarError(type) {
            console.log(type);
            this.getpreupload();
            this.$message.error("上传失败");
            this.$emit("input", "");
        },
	    getBakckground(imgUrl){
        	if(imgUrl){
        		return {
        			backgroundImage:`url(${imgUrl})`
                }
            }
            return {}
        }
    }
};
</script>

<style scoped lang="scss">
.upload-box {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  text-align: left;
  border: 1px dashed #ccc;
  overflow: hidden;
  border-radius: 5px;
  &:hover {
    border-color: #564fc1;
    color: #564fc1;

    .el-icon-plus {
      color: #564fc1;
    }
  }
  /*line-height: 100%;*/
  .el-icon-plus {
    position: absolute;
    left: 50%;
    top: 50%;
    display: block;
    margin-top: -7px;
    margin-left: -7px;
  }
}
.uploader {
  .upload-img {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
}
.isError {
  button {
    border-color: #f4516c;
  }
}
</style>
