// MainChildren
const MainChildren = () => import("@/components/main-children.vue");
//直接组队
const teamUpList = () => import("../views/team-up/list.vue");
//解绑战队
const unbundlingTeam = () => import("../views/unbundling-team/index.vue");
//调整战队
const adjustTeam = () => import("@/views/adjust-team/index.vue");
// 生成用户码
const generateUerCode = () => import("../views/generate-usercode/index.vue");
//邀请二维码验证
const verificationQrcode = () =>
  import("../views/verification-qrcode/index.vue");

//批量发送优惠券列表
const sendCouponList = () =>
  import("../views/operating-assistant/send-coupons/index.vue");

//新建批量发送优惠券
const sendCouponAdd = () =>
  import("../views/operating-assistant/send-coupons/add.vue");

//新建批量发送优惠券
const sendCouponDetail = () =>
  import("../views/operating-assistant/send-coupons/detail.vue");

//订单通知
const orderNotification = () =>
  import("@/views/operating-assistant/order-notification/index.vue");

//核销员管理列表
const writeOffList = () =>
  import("../views/operating-assistant/write-off/index.vue");

//新建核销员
const writeOffAdd = () =>
  import("../views/operating-assistant/write-off/add.vue");

//app核销员管理列表
const appWriteOffList = () =>
  import("../views/operating-assistant/write-off-new/index.vue");

//app新建核销员
const appWriteOffAdd = () =>
  import("../views/operating-assistant/write-off-new/add.vue");

//商户端管理员
const businessAdminList = () =>
  import("../views/operating-assistant/business-admin/index.vue");

//新建商户管理员
const businessAdminAdd = () =>
  import("../views/operating-assistant/business-admin/add.vue");

// 公众号设置
const publicAccountSettings = () =>
  import("../views/public-account-settings/index.vue");

// 创建X计划
const createActivitysCrowdFunding = () =>
  import("@/views/activitys-crowdFunding/index");

//编辑X计划
const editActivitysCrowdFunding = () =>
  import("@/views/activitys-crowdFunding/edit");
// X计划列表
const activitysCrowdFunding = () =>
  import("@/views/activitys-crowdFunding/list");
// X计划战队
const xplanTeam = () => import("@/views/activitys-crowdFunding/xplan-team");
// X计划详情
const activitysCrowdFundingDetail = () =>
  import("@/views/activitys-crowdFunding/detail");
//X计划战队详情
const xplanTeamDetail = () =>
  import("@/views/activitys-crowdFunding/xplan-team-detail");
// 项目礼包设置
const projectGiftPackage = () =>
  import("@/views/operating-assistant/gift-package/index");
// 项目礼包设置
const createProjectGiftPackage = () =>
  import("@/views/operating-assistant/gift-package/create");
// 项目礼包设置
const detailProjectGiftPackage = () =>
  import("@/views/operating-assistant/gift-package/detail");
// 合伙人申请
const partnerApplyfor = () =>
  import("@/views/operating-assistant/partner-applyFor");
// 合伙人申请详情
const partnerApplyforDetail = () =>
  import("@/views/operating-assistant/partner-applyFor-detail");
//
const checkPoints = () => import("@/views/operating-assistant/check-points")
import unitRouter from "./unit";

export default {
  path: "operating-assistant",
  name: "运营助手",
  component: MainChildren,
  children: [
    {
      path: "send-coupon",
      name: "operating-assistant.send-coupons.index",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: sendCouponList,
    },
    {
      path: "send-coupon-add",
      name: "operating-assistant.send-coupons.add",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: sendCouponAdd,
    },
    {
      path: "send-coupon-detail",
      name: "operating-assistant.send-coupons.detail",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: sendCouponDetail,
    },
    {
      path: "order-notification",
      name: "operating-assistant.order-notification.index",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: orderNotification,
    },
    {
      path: "write-off",
      name: "operating-assistant.write-off.index",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: writeOffList,
    },
    {
      path: "write-off-add",
      name: "operating-assistant.write-off.add",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: writeOffAdd,
    },
    {
      path: "write-off-new",
      name: "operating-assistant.write-off.index",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: appWriteOffList,
    },
    {
      path: "write-off-add-new",
      name: "operating-assistant.write-off.add",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: appWriteOffAdd,
    },
    {
      path: "business-admin",
      name: "operating-assistant.business-admin.index",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: businessAdminList,
    },
    {
      path: "business-admin-add",
      name: "operating-assistant.business-admin.add",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: businessAdminAdd,
    },
    unitRouter,
    {
      path: "charge-setting",
      name: "充值设置",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("../views/operating-assistant/charge/setting"),
    },
    {
      path: "offline-charge-records",
      meta: {
        name: "手动充值记录",

        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () =>
        import("../views/operating-assistant/charge/offline-charge-records"),
    },
    {
      path: "team-up/list",
      meta: {
        name: "直接组队",

        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: teamUpList,
    },
    // Unbundling team
    {
      path: "unbundling-team/index",
      name: "解绑组队",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: unbundlingTeam,
    },
    {
      path: "adjust-team/index",
      name: "调整战队",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: adjustTeam,
    },
    {
      path: "verification-qrcode",
      name: "二维码验证",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: verificationQrcode,
    },
    {
      path: "generate-usercode",
      name: "生成用户码",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: generateUerCode,
    },
    {
      path: "public-account-settings",
      name: "公众号设置",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: publicAccountSettings,
    },

    {
      path: "create-activitys-crowdFunding",
      name: "新建X计划",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: createActivitysCrowdFunding,
    },
    {
      path: "edit-activitys-crowdFunding",
      name: "编辑X计划",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: editActivitysCrowdFunding,
    },
    {
      path: "activitys-crowdFunding",
      name: "X计划",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: activitysCrowdFunding,
    },
    {
      path: "xplan-team",
      name: "X计划战队",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: xplanTeam,
    },
    {
      path: "xplan-team-detail",
      name: "X计划战队详情",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: xplanTeamDetail,
    },
    {
      path: "team-relation",
      name: "战队关系图",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/components/relation-tree/index.vue"),
    },
    {
      path: "support-message",
      name: "支持留言模版",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () =>
        import("../views/activitys-crowdFunding/support-message"),
    },
    {
      path: "team-message",
      name: "战队宣言模版",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("../views/activitys-crowdFunding/team-message"),
    },

    {
      path: "activitys-crowdFunding-detail",
      name: "X计划详情",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: activitysCrowdFundingDetail,
    },
    {
      path: "partner-applyFor",
      name: "合伙人申请",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: partnerApplyfor,
    },
    {
      path: "partner-applyFor-detail",
      name: "合伙人申请详情",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: partnerApplyforDetail,
    },
    {
      path: "project-gift-package",
      name: "项目礼包设置",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: projectGiftPackage,
    },
    {
      path: "create-project-gift-package",
      name: "创建项目礼包",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: createProjectGiftPackage,
    },

    {
      path: "detail-project-gift-package",
      name: "项目详情",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: detailProjectGiftPackage,
    },
    {
      path: "check-points",
      name: "扫码看积分",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: checkPoints,
    },
  ],
};
