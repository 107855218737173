const MainChildren = () => import("@/components/main-children.vue");

//注册小程序
const miniIndex = () => import("@/views/miniProgram/index.vue");
//认证法人信息
const addCompanyInfo = () => import("@/views/miniProgram/add-company-info.vue"); //小程序基本信息
const addProgramInfo = () => import("@/views/miniProgram/add-program-info.vue");
export default {
  path: "miniProgram",
  name: "注册小程序",
  component: MainChildren,
  children: [
    {
      path: "view",
      name: "miniProgram.name",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: miniIndex
    },
    {
      path: "addCompanyInfo",
      name: "miniProgram.addCompanyInfo",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: addCompanyInfo
    },
    {
      path: "addProgramInfo",
      name: "miniProgram.addProgramInfo",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: addProgramInfo
    }
  ]
};
