/*
 * @Author: sky.li
 * @LastEditors: Please set LastEditors
 * @Description:
 * @可以输入预定的版权声明、个性签名、空行等
 */
const MainChildren = () => import("@/components/main-children.vue");

export default {
  path: "unit",
  component: MainChildren,
  meta: {
    name: "星球互联"
  },
  children: [
    {
      path: "list",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
        name: "星球内容赋能"
      },
      component: () => import("@/views/planet-cooperation/unit/list")
    },
    {
      path: "product-list",
      meta: {
        login: true,
        name: "星球商品赋能",

        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("@/views/planet-cooperation/unit/product-list")
    },
    {
      path: "class-goods-list",
      meta: {
        login: true,
        name: "课程商品赋能",

        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("@/views/planet-cooperation/unit/class-goods-list")
    },
    {
      path: "gift-card-list",
      meta: {
        login: true,
        name: "礼品卡赋能",
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("@/views/planet-cooperation/unit/gift-card-list")
    },
    {
      path: "info/:id",
      meta: {
        login: true,
        name: "互联星球详情",

        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("@/views/planet-cooperation/unit/info")
    },
    {
      path: "unit-articles",
      meta: {
        name: "被赋能管理",

        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("@/views/planet-cooperation/unit/unitArticles")
    },
    {
      path: "product-detail",
      meta: {
        login: true,
        name: "商品详情",

        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("@/views/planet-cooperation/unit/product-detail")
    },
    {
      path: "class-goods-detail",
      meta: {
        login: true,
        name: "课程详情",

        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("@/views/planet-cooperation/unit/class-goods-detail")
    },
  ]
};
