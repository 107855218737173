/*
 * @Author: sky.li
 * @LastEditors: sky.li
 * @Description:
 * @可以输入预定的版权声明、个性签名、空行等
 */
/**
 * 返回全局方法
 * 如果有历史记录，history.go(-1)
 * 否则 改为转跳具体地址
 * this.$goBack('xxxx',index)
 * index 为history.go(-index)，默认为-1
 */
import VueRouter from "@/router";

export default (url, index = 1) => {
  if (window.history.length > index) {
    VueRouter.go(-index);
  } else {
    VueRouter.push(url);
  }
};
