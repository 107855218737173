/*
 * @Author: sky.li
 * @LastEditors: sky.li
 * @Description:
 * @可以输入预定的版权声明、个性签名、空行等
 */
// 社群管理

const MainChildren = (resolve) => {
  require.ensure([], () => {
    resolve(require("@/components/main-children.vue"));
  });
};

// 机器人设置
const robotSetting = (resolve) => {
  require.ensure([], () => {
    resolve(require("../views/chat-ro-bot/robot.vue"));
  });
};

export default {
  path: "community",
  name: "menu.community_manage.index",
  meta: {
    login: true,
    Authentication: true
  },
  component: MainChildren,
  children: [
    {
      path: "robot_setting",
      name: "menu.community_manage.robot_setting",
      meta: {
        login: true,
        Authentication: true
      },
      component: robotSetting
    }
  ]
};
