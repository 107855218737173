import ecshopApi from "@/api/ec-goods";
import supplyApi from "@/api/supply";
import { dynamicDomainReplaceOption } from '@/plugins/tool'

const state = {
  target_account_id: "",
  original_product_id: "",
  addOpenArticleDialogVisible: false, // 添加开放文章的dialog
  selectProductDialogVisible: false, // 添加商品的选择商品dialog
  selectGiftCardDialogVisible: false, //添加礼品卡的dialog
  batchDialogVisible: false, // 选择批次dialog
  setPriceStoreDialogVisible: false, // 设置赋能价格和库存的dialog
  uniteBatchProductData: { list: [] }, // 设置商品赋能的开放商品list
  uniteClassGoodsData: { list: [] }, // 设置商品赋能的开放商品list
  uniteBatchGiftCardsData: {list: []},  // 设置礼品卡商品赋能的开放商品list
  // 获取商品类型
  goodsType: [
    { value: "", label: "全部" },
    { value: "REALITY", label: "实物商品" },
    { value: "VIRTUAL", label: "虚拟商品" },
    { value: "EXCHANGE_PRODUCT", label: "商品兑换" },
    { value: "GIFT", label: "礼品商品" },
    { value: "GIFT_CARD", label: "礼品卡商品" },
  ],
  supplyAccountIds: [],
  enable_batch_ids: [], // 设置某个赋能商品已经添加过的批次ids
  domainDynamicText: "",  // 根据域名获取当前应该使用的文案
  isOpenDomain: true,     // 是否是水晶星球的domain
};

const mutations = {
  setDynamicDomain(state) {
    console.log("window.location.origin", window.location.origin)
    state = dynamicDomainReplaceOption(state)
  },
  setTargetAccountId(state, payload) {
    state.target_account_id = payload.target_account_id;
  },
  setOriginalProductId(state, payload) {
    state.original_product_id = payload.original_product_id;
  },
  setSelectProductDialogVisible(state, payload) {
    state.selectProductDialogVisible = payload;
  },
  setSelectGiftCardDialogVisible(state, payload) {
    state.selectGiftCardDialogVisible = payload;
  },
  setBatchDialogVisible(state, payload) {
    state.batchDialogVisible = payload;
  },
  setPriceStoreDialogVisible(state, payload) {
    state.setPriceStoreDialogVisible = payload;
  },
  setInitState(state) {
    state.original_product_id = "";
    state.selectProductDialogVisible = false;
    state.batchDialogVisible = false;
    state.setPriceStoreDialogVisible = false;
  },
  setUniteBatchProductData(state, payload) {
    state.uniteBatchProductData = payload;
  },
  setUniteClassGoodsData(state, payload) {
    state.uniteClassGoodsData = payload;
  },
  setUniteBatchGiftCardsData(state, payload) {
    state.uniteBatchGiftCardsData = payload;
  },
  // 设置商品来源
  setSupplyAccounts(state, payload) {
    state.supplyAccountIds = payload;
  },
  // 设置某个赋能商品已经添加过的批次ids
  setEnableBatchIds(state, payload) {
    state.enable_batch_ids = payload.enable_batch_ids;
  },
  // 设置添加开放文章的visible
  setAddOpenArticleDialogVisible(state, payload) {
    state.addOpenArticleDialogVisible = payload;
  },
};

const getters = {
  // 获取商品类型
  getGoodTypeValue: (state) => (payload) => {
    const found = state.goodsType.find((item) => item.value === payload.type);
    return found ? found.label : "";
  },
  // 获取商品来源
  getSupplyShortName: (state) => (payload) => {
    const found = state.supplyAccountIds.find(
      (item) => item.id === payload.supply_account_id
    );
    return found ? found.supply_short_name : "自营";
  },
  // 获取批次商品的可用库存
  getBatchProductStoreNum: () => (payload, ele) => {
    let item, row, store_num_objs;
    if (payload) {
      item = payload.item;
      row = payload.row;
      store_num_objs = row.skus;
    } else {
      item = row = ele;
      store_num_objs = item.batch_products;
    }

    if (!item.batch_products.length) {
      return 0;
    }
    const store_num = store_num_objs
      .map((item) => item.store_num)
      .reduce((pre, cur) => {
        return pre + cur;
      }, 0);
    return store_num;
  },
  // 获取批次礼品卡商品的可用库存
  getBatchGiftCardStoreNum: () => (payload, ele) => {
    let item, row, store_num_objs;
    if (payload) {
      item = payload.item;
      row = payload.row;
      store_num_objs = row.skus;
    } else {
      item = row = ele;
      store_num_objs = item.original_product.batch_products;
    }

    if (!item.original_product.batch_products.length) {
      return 0;
    }
    const store_num = store_num_objs
      .map((item) => item.store_num)
      .reduce((pre, cur) => {
        return pre + cur;
      }, 0);
    return store_num;
  },

  // 获取该批次的历史总库存
  getHistoryBatchProductStoreNum: () => (payload) => {
    const { item, row } = payload;
    if (!item.batch_products.length) {
      return 0;
    }
    const store_num = row.skus
      .map((item) => item.total_store_num)
      .reduce((pre, cur) => {
        return pre + cur;
      }, 0);
    return store_num;
  },

  // 获取该批次的历史总库存
  getHistoryBatchGiftCardStoreNum: () => (payload) => {
    const { item, row } = payload;
    if (!item.original_product.batch_products.length) {
      return 0;
    }
    const store_num = row.skus
      .map((item) => item.total_store_num)
      .reduce((pre, cur) => {
        return pre + cur;
      }, 0);
    return store_num;
  },
};

const actions = {
  // 获取赋能的批次商品列表
  async getUniteBatchProductData({ commit }, params) {
    const res = await ecshopApi.uniteBatchProducts(params);
    if (res.code === 0) {
      return commit("setUniteBatchProductData", res.data);
    }
    return null;
  },
   // 获取赋能的课程商品列表
   async getUniteClassGoodsData({ commit }, params) {
    const res = await ecshopApi.uniteBatchProducts(params);
    if (res.code === 0) {
      return commit("setUniteClassGoodsData", res.data);
    }
    return null;
  },

  // 获取礼品卡赋能的批次商品列表
  async getUniteBatchGiftCardsData({ commit }, params) {
    const res = await ecshopApi.uniteBatchGiftCards(params);
    if (res.code === 0) {
      
      return commit("setUniteBatchGiftCardsData", res.data);
    }
    return null;
  },

  // 获取被赋能的商品列表
  async getUniteBatchProductsPageForUnited({ commit }, params) {
    const res = await ecshopApi.uniteBatchProductsPageForUnited(params);
    if (res.code === 0) {
      return commit("setUniteBatchProductData", res.data);
    }
    return null;
  },

  // 获取被赋能的课程列表
  async getUniteClassGoodsPageForUnited({ commit }, params) {
    const res = await ecshopApi.uniteBatchProductsPageForUnited(params);
    if (res.code === 0) {
      return commit("setUniteClassGoodsData", res.data);
    }
    return null;
  },

  // 获取被赋能的商品列表
  async getUniteBatchGiftCardPageForUnited({ commit }, params) {
    const res = await ecshopApi.uniteBatchGiftCardPageForUnited(params);
    if (res.code === 0) {
      return commit("setUniteBatchGiftCardsData", res.data);
    }
    return null;
  },

  // 获取商品来源
  async getSupplyAccounts({ commit }) {
    let tempSupplyAccountIds = [];
    const { code, data } = await supplyApi.supplyAccounts();
    if (code === 0) {
      const supplyAccountIds = [
        { id: null, supply_short_name: "全部" },
        { id: 0, supply_short_name: "自营" },
      ];
      tempSupplyAccountIds = [...supplyAccountIds, ...data];
      return commit("setSupplyAccounts", tempSupplyAccountIds);
    }
    return null;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
