/*
 * @Author: your name
 * @Date: 2020-10-14 16:56:47
 * @LastEditTime: 2020-10-29 20:55:29
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /mw-merculet-saas-web/src/router/live.js
 */
export default {
  path: "plugin-store",
  meta: {
    name: "应用市场",
    noSide:true
  },
  component: () => import("@/views/plugin-store"),
 
};
