const MainChildren = () => import("@/components/main-children.vue");
//新人礼包列表
const userGiftList = () => import("../views/activityCenter/userGift/index");

//新建新人礼包
const userGiftAdd = () => import("../views/activityCenter/userGift/gift-add");

export default {
  path: "userGift",
  name: "新人礼包",
  component: MainChildren,
  children: [
    {
      path: "list",
      name: "userGift.name",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: userGiftList
    },
    {
      path: "add",
      name: "userGift.add.name",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: userGiftAdd
    }
  ]
};
