/*
 * @Author: your name
 * @Date: 2021-06-09 15:35:15
 * @LastEditTime: 2021-09-08 01:50:21
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /mw-merculet-saas-web/src/router/ledger.js 嘿嘿乖
 */

export default {
  path: "ledgerWx",
  name: "分账管理",
  component: () => import("@/components/main-children.vue"),
  children: [
    {
      path: "weChat-account-withdrawal",
      name: "微信分账提现",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("@/views/ledger/weChat-account-withdrawal.vue")
    },
    {
      path: "weChat-account-withdrawal-detail",
      name: "微信分账提现详情",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("@/views/ledger/weChat-account-withdrawal-detail.vue")
    },
  ]
};


