<template>
  <el-select v-model="category" :size="size" @change="handleChange">
    <el-option v-for="item in goodsType" :key="item.value" :label="item.label" :value="item.value"> </el-option>
  </el-select>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    /**
     * size of select, options: large, small, mini
     * @type {{type: string, default: 'small' | 'mimi' | 'large' | 'medium'}}
     */
    size: {
      type: String,
      default: 'small',
    },
  },

  computed: {
    category: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  // exclude_types GIFT_CARD,TICKET

  data() {
    return {
      goodsType: [
        { key: '', value: '', label: '全部' },
        { key: 'REALITY', value: '0', label: '实物商品' },
        { key: 'VIRTUAL', value: '1', label: '虚拟商品' },
        { key: 'EXCHANGE_COUPON', value: '2', label: '兑换卡券' },
        { key: 'GIFT', value: '3', label: '礼品赠送' },
        { key: 'MEDIA', value: '4', label: '课程商品' },
        { key: 'EXCHANGE_PRODUCT', value: '5', label: '商品兑换' },
        // { value: 'TICKET', label: '票务' },
        // { value: 'GIFT_CARD', label: '礼品卡' },
      ], // 商品状态
    };
  },

  methods: {
    handleChange() {
      this.$emit('change');
    },
  },
};
</script>
