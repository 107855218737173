export default function (num) {
  if (isValueNumber(num)) {
    if (num < 10000) {
      return num
    } else if (num >= 10000 && num < 100000000) {
      return num / 10000 + '<span>万</span>';
    } else {
      return num / 100000000 + '<span>亿</span>';
    }
  }

  function isValueNumber(value) {
    if (value === null) {
      console.error('不可为null');
      return false
    } else if (value === '') {
      console.error('不可为空');
      return false
    } else {
      let num = Number(value);
      console.log(num);
      if (isNaN(num)) {
        console.error('类型不对');
        return false
      } else {
        return true;
      }
    }
  }
}
