
/*---------使用语言包-----------*/
import VueI18n from "vue-i18n";
import Vue from 'vue'
// import enLocale from "element-ui/lib/locale/lang/en";
import zhLocale from "element-ui/lib/locale/lang/zh-CN";
// import jaLocale from "element-ui/lib/locale/lang/ja";
Vue.use(VueI18n);

import zhCN from "@/lang/zh-cn";
const i18n = new VueI18n({
    locale: "zh-cn", // 语言标识
    messages: {
      "zh-cn": Object.assign(zhCN, zhLocale) // 中文语言包
      // 'en': Object.assign(EN, enLocale), // 英文语言包
      // 'ja': Object.assign(JA, jaLocale) // 英文语言包
    }
  });
  
  export default i18n