/*
 * @Author: your name
 * @Date: 2021-06-09 15:35:15
 * @LastEditTime: 2021-09-03 19:31:38
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /mw-merculet-saas-web/src/router/ledger.js 嘿嘿乖
 */

export default {
  path: "ledger",
  name: "分账管理",
  component: () => import("@/components/main-children.vue"),
  children: [
    {
      path: "ledger-record-list",
      name: "分账记录",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("@/views/ledger/ledger-record-list.vue")
    },
    {
      path: "allocate-ledger-list",
      name: "分账操作",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("@/views/ledger/allocate-ledger-list.vue")
    },
    {
      path: "allocate-ledger-operation-detail",
      name: "分账操作详情",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("@/views/ledger/allocate-ledger-operation-detail.vue")
    },
    {
      path: "allocate-ledger-operation-add",
      name: "补发分账",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("@/views/ledger/allocate-ledger-operation-add.vue")
    },
    {
      path: "balance-charge-record-list",
      name: "余额充值记录",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("@/views/ledger/balance-charge-record-list.vue")
    },
    {
      path: "ledger-account-list",
      name: "分账账户管理",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("@/views/ledger/ledger-account-list.vue")
    },
    {
      path: "ledger-account-detail",
      name: "分账账户详情",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("@/views/ledger/ledger-account-detail.vue")
    },
    {
      path: "sub-ledger-list",
      name: "分账列表",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("@/views/ledger/sub-ledger-list.vue")
    },
    {
      path: "sub-ledger-detail",
      name: "分账操作详情",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("@/views/ledger/sub-ledger-detail.vue")
    },
    {
      path: "star-account-management",
      name: "星球账户管理",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("@/views/ledger/star-account-management.vue")
    },
    {
      path: "star-account-detail",
      name: "账户详情",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("@/views/ledger/star-account-detail.vue")
    },
    {
      path: "weChat-account-withdrawal",
      name: "微信分账提现",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("@/views/ledger/weChat-account-withdrawal.vue")
    },
    {
      path: "weChat-account-withdrawal-detail",
      name: "微信分账提现详情",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("@/views/ledger/weChat-account-withdrawal-detail.vue")
    },
  ]
};


