/**
 * sanlin shi
 * @param resolve
 * @constructor
 */
let MainChildren = () => import("@/components/main-children.vue");

const userManagementHome = () => import("../views/user-management/home.vue");

//用户列表
const userList = () => import("../views/user-management/user-list.vue");

//用户详情
const UserDetail = () => import("../views/user-management/user-detail.vue");
// 用户标签
const userTag =()=>import('@/views/user-management/user-tag.vue')
//用户行为追踪
const behaviorTracking = () =>
  import("../views/user-management/user-behavior-tracking.vue");

//当前用户的战队成员
const userTeamMember = () =>
  import("../views/user-management/user-team-member.vue");

// 星球主设置
const starOwnerSetting = () =>
  import("../views/user-management/star-owner-setting.vue");

// 运营人员配置
const operatorList = () => import("../views/user-management/operator-list.vue");

const userScoreReport = () =>
  import("../views/user-management/user-score-report.vue");

const crystalRevenueDetails = () =>
  import("@/views/user-management/crystal-revenue-and-expenditure-details")

const whitelist = () => import("../views/user-management/whitelist.vue");

const blacklist = () => import("../views/user-management/blacklist.vue");

export default {
  path: "user",
  name: "user.management.name",
  component: MainChildren,
  children: [
    {
      path: "user-management",
      name: "",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: userManagementHome,
      children: [
        {
          path: "user-detail",
          name: "用户详情",
          meta: {
            login: true,
            Authentication: true,
            noVerifyToken: false
          },
          component: UserDetail
        },
        {
          path: "behavior-tracking",
          name: "用户行为追踪",
          meta: {
            login: true,
            Authentication: true,
            noVerifyToken: false
          },
          component: behaviorTracking
        },
        {
          path: "team-member",
          name: "战队成员列表",
          meta: {
            login: true,
            Authentication: true,
            noVerifyToken: false
          },
          component: userTeamMember
        }
      ]
    },
    {
      path: "user-list",
      name: "userManage.userList",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: userList
    },
    {
      path: "star-card-user-list",
      name: "推荐居民列表",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("../views/user-management/star-card-user-list")
    },
    {
      path: "star-owner-setting",
      name: "userManage.starOwnerSetting",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: starOwnerSetting
    },
    {
      path: "home-owner-link",
      name: "首页星球主链接",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("../views/user-management/home-owner-link")
    },
    {
      path: "operator-list",
      name: "userManage.operatorList",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: operatorList
    },
    {
      path: "user-score-report",
      name: "用户水晶报告",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: userScoreReport,
    },
    {
      path: "user-tag",
      name: "标签管理",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: userTag
    },
    {
      path: "user-tag-detail",
      name: "标签详情",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("@/views/user-management/user-tag-detail.vue")
    },
    {
      path: "user-tag-root",
      name: "标签和用户印象评价权限",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("@/views/user-management/user-tag-root.vue")
    },
    {
      path: "crystal-revenue-and-expenditure-details",
      name: "水晶收支明细",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: crystalRevenueDetails,
    },
    {
      path: "for-query-user",
      name: "用户查询",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("@/views/user-management/for-query-user.vue")
    },
    {
      path: "whitelist",
      name: "发布白名单设置",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: whitelist
    },
    {
      path: "blacklist",
      name: "黑名单设置",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: blacklist
    },
    {
      path: "score-report",
      name: "水晶报告",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("../views/dashBoard/score-report")
    }
  ]
};
