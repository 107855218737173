/*
 * @Author: sky.li
 * @LastEditors: sky.li
 * @Description:
 * @可以输入预定的版权声明、个性签名、空行等
 */
const MainChildren = () => import("@/components/main-children.vue");
//优惠券列表
const couponList = () => import("../views/coupon/index");
//优惠券详情
const couponDetail = () => import("../views/coupon/coupon-detail");
//新建优惠券
const couponAdd = () => import("../views/coupon/coupon-add");
export default {
  path: "coupon",
  name: "优惠券",
  component: MainChildren,
  children: [
    {
      path: "list",
      meta: {
        name: "优惠券",
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: couponList
    },
    {
      path: "add",
      name: "coupon.add.name",
      meta: {
        name: "创建优惠券",
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: couponAdd
    },
    {
      path: "detail",
      meta: {
        name: "优惠券详情",
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: couponDetail
    }
  ]
};
