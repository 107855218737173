/*
 * @Author: sky.li
 * @LastEditors: sky.li
 * @Description:
 * @可以输入预定的版权声明、个性签名、空行等
 */
/**
 * @author Tianyuan.li
 * @email Tianyuan.li@magicwindow.cn
 * @date 2018/4/16 下午8:35
 * @description
 */
import { base } from "@/config/index";

import http from "@/serving/http.serving";

export default {
  getList: (params) => http.httpGet(`${base}/supply/list`, params), // 获取供应商列表
  selectPhysicalStore: (params) =>
    http.httpGet(`${base}/supply/selectPhysicalStore`, params), // 获取供应商列表
  create: (params) => http.httpPost(`${base}/supply/create`, params), // 新建供应商
  update: (params) => http.httpPost(`${base}/supply/update`, params), // 更新供应商
  disableAccount: (params) =>
    http.httpGet(`${base}/supply/disableAccount`, params), // 停用
  enableAccount: (params) =>
    http.httpGet(`${base}/supply/enableAccount`, params), // 启用
  supplyAccounts: (params) =>
    http.httpGet(`${base}/ecshop/supplyAccounts/list`, params), // 启用
  
};
