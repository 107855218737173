<template>
  <div id="app">
    <transition name="el-fade-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
@import "./assets/css/common.scss";
@import "./assets/css/openMobile.scss";

html,
body,
#app {
  min-height: 100vh;
}

.quill-editor {
  display: grid;
  line-height: 20px;
}
</style>
