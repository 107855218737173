const MainChildren = () => import("@/components/main-children.vue");

const List = () => import("../views/topics/list.vue");

const Comments = () => import("../views/topics/comments.vue");

export default {
  path: "topics",
  name: "appList.route.appSet",
  component: MainChildren,
  children: [
    {
      path: "list",
      name: "话题管理",
      meta: {
        login: true,
        Authentication: true
      },
      component: List
    },
    {
      path: "comments",
      name: "话题评论管理",
      meta: {
        login: true,
        Authentication: true
      },
      component: Comments
    },
    {
      path: "accountCenterTop",
      name: "accountCenterTop.name",
      meta: {
        login: true,
        Authentication: true
      },
      component: () => import("../views/accountCenterTop")
    }
  ]
};
