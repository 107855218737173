import settingApi from "@//api/setting";

export function dynamicDomainReplaceOption(state) {
  if (!state) state = {}
  console.log("window.location.origin", window.location.origin);
  const origin = window.location.origin;
  const devDomain = "localhost:8081";
  const testDomain = "merculet-saas-cn.mvpcs.cn";
  const prodDomain = "open.merculet.cn";
  if (
    !origin.endsWith(devDomain) &&
    !origin.endsWith(testDomain) &&
    !origin.endsWith(prodDomain)
  ) {
    state.domainDynamicText = "星球";
    state.isOpenDomain = false;
  } else {
    state.domainDynamicText = "星球";
    state.isOpenDomain = true;
  }
  return {
    ...state,
  }
}


export const getUUID = () => {
  return "xxxx-xxxx-xxxx-xxxx-xxxx".replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

// 获取domainList
export const fetchGetDomainConfig = async () => {
  const res = await settingApi.getDomainConfig()
  console.log("res = > ", res)
  if (res.code == 0) {
    // this.community_domain = "https://" + res.data.community_domain
    return "https://" + res.data.community_domain
  }
}