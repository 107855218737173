// 星球配置

const MainChildren = () => import("@/components/main-children.vue");
//星球介绍
const introduce = () => import("../components/stationed/simplet.vue");
//品牌页设置
const brandSetting = () => import("../views/stationed/brand-setting.vue");
// 所有任务
const growUpAll = () => import("../views/stationed/star-task/index.vue");
// 所有旧任务
const growUpAllOld = () => import("../views/stationed/grow-up-all.vue");

//插件管理
const plugsManage = () => import("../views/stationed/plugs-manage.vue");
const articlePosterList = () =>
  import("../views/spt/market/article-poster-list.vue");
const addArticlePoster = () =>
  import("../views/spt/market/add-article-poster.vue");
const activityPosterList = () =>
  import("../views/spt/market/activity-poster-list.vue");
const addActivityPoster = () =>
  import("../views/spt/market/add-activity-poster.vue");
const productPosterList = () =>
  import("../views/spt/market/product-poster-list.vue");
const addProductPoster = () =>
  import("../views/spt/market/add-product-poster.vue");
const invitePoster = () => import("../views/spt/market/invite-poster.vue");
const visualSetting = () => import("@/views/visual/visual-index.vue");
export default {
  path: "planet",
  name: "menu.planet_manage.index",
  meta: {
    login: true,
    Authentication: true
  },
  component: MainChildren,
  children: [
    {
      path: "introduce",
      name: "menu.planet_manage.introduce",
      meta: {
        login: true,
        Authentication: false
      },
      component: introduce
    },
    {
      path: "brand-setting",
      name: "menu.planet_manage.brand-setting",
      meta: {
        login: true,
        Authentication: false
      },
      component: brandSetting
    },
    {
      path: "task",
      name: "menu.planet_manage.task",
      meta: {
        login: true,
        Authentication: true
      },
      component: growUpAll
    },
    {
      path: "old-task",
      name: "星球旧任务",
      meta: {
        login: true,
        Authentication: true
      },
      component: growUpAllOld
    },
    {
      path: "application",
      name: "menu.planet_manage.application",
      meta: {
        login: true,
        Authentication: true
      },
      component: plugsManage
    },
    {
      path: "invite-poster",
      name: "menu.planet_manage.invite-poster",
      meta: {
        login: true,
        Authentication: true
      },
      component: invitePoster
    },
    {
      path: "article-poster",
      name: "menu.planet_manage.article-poster",
      meta: {
        login: true,
        Authentication: true
      },
      component: articlePosterList
    },
    {
      path: "add-article-poster/:id",
      name: "新增文章海报",
      meta: {
        login: true,
        Authentication: true
      },
      component: addArticlePoster
    },
    {
      path: "activity-poster",
      name: "menu.planet_manage.activity-poster",
      meta: {
        login: true,
        Authentication: true
      },
      component: activityPosterList
    },
    {
      path: "add-activity-poster/:id",
      name: "新增活动海报",
      meta: {
        login: true,
        Authentication: true
      },
      component: addActivityPoster
    },
    {
      path: "product-poster",
      name: "menu.planet_manage.product-poster",
      meta: {
        login: true,
        Authentication: true
      },
      component: productPosterList
    },
    {
      path: "add-product-poster/:id",
      name: "配置商品海报",
      meta: {
        login: true,
        Authentication: true
      },
      component: addProductPoster
    },
    {
      path: "visual_setting",
      name: "menu.planet_manage.visual_setting",
      meta: {
        login: true,
        Authentication: true
      },
      component: visualSetting
    },
    {
      path: "background-setting",
      meta: {
        name: "自定义背景图",

        login: true,
        Authentication: true
      },
      component: () => import("@/views/visual/bacgrkound-setting/index.vue")
    }
  ]
};
