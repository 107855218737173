const MainChildren = () => import("@/components/main-children.vue");
const Guide = () => import("../views/contract/index.vue");
const Custom = () => import("../views/contract/custom.vue");
const Public = () => import("../views/contract/public.vue");
const Plan = () => import("../views/contract/plan.vue");
//按步骤创建水晶和星球
const certificateCreatByStep = (resolve) => {
  require.ensure([], () => {
    resolve(require("../views/certificate/certificate-creat-by-step"));
  });
};

export default {
  path: "/contract",
  name: "contract.name",
  component: MainChildren,
  children: [
    {
      path: "guide",
      name: "contract.guide.name",
      meta: {
        login: true,
        contract: true
      },
      component: Guide
    },
    {
      path: "custom",
      name: "contract.custom.name",
      meta: {
        login: true,
        contract: true
      },
      component: Custom
    },
    {
      path: "public",
      name: "contract.public.name",
      meta: {
        login: true,
        contract: true
      },
      component: Public
    },
    {
      path: "plan",
      name: "contract.plan.name",
      meta: {
        login: true,
        contract: true
      },
      component: Plan
    },
    {
      path: "certificate-create",
      name: "创建水晶",
      meta: {
        login: true,
        contract: true
      },
      component: certificateCreatByStep
    }
  ]
};
