import { makeAction, makeGetter } from './store-unit/store-unit';
import setModuleList from './visual/setModuleList';
import robotApi from '@/api/robot.js';
import VisualApi from '@/api/visual';

let state = {
  dialogConfigList: [],
  group: null, //群组信息
  starInfo: {}, // 星球信息
  page_cfg: {}, // 页面设置，目前有背景图片和透明度两项
  moduleList: [], // 所有组件
  moduleSelected: {}, // 当前操作的组件
  dialogObject: {
    dialogType: 'Article', // 需要弹窗显示的类型  @Article  文章
    dialogShow: false, // 是否显示弹窗
    option: {},
    dialogCallback: () => {}, // 弹窗确定后的回调函数
  },
  spmModuleErrorList: {
    /**
     * 根据index key来记录错误
     * 当页面切换，组件切换，保存的时候，触发检测方法
     * 将结果记录在此处，按照  {index:{key:"errorMessage "}}的形式记录
     */
  },
  tab_config: [], // 底部tab栏信息
  datCache: {}, // 数据缓存中心
};

const getters = makeGetter([
  'dialogObject',
  'starInfo',
  'page_cfg',
  'tab_config',
  'getGroup',
  'dialogInputUrl',
  'moduleList',
  'dialogShow',
  'dialogType',
  'dialogConfigList',
  'moduleSelected',
  'dialogObj',
  'taskGuide',
  'spmModuleErrorList',
  'spmModuleValid',
  'unitErrorList',
  'plateInvite',
  'taskList',
]);

const actions = makeAction([
  'setModuleList',
  'updateModuleList',
  'selectModule',
  'setModuleConfig',
  'pushModuleConfig',
  'removeModuleConfig',
  'hideInputDialog',
  'setDialogList',
  'setDialogType',
  'setDialogObj',
  'setTheme',
  'setDefaultThemeId',
  'showChooseDialog',
  'endDialog',
  'endInput',
  'showInputDialog',
  'setDeleteList',
  'setTaskGuide',
  'checkModulValid',
  'setUnitErrorList',
  'setTaskList',
  'hideDialog',
  'openDialog',
  'bindData',
  'deleteItem',
  'setStarInfo',
  'setGroup',
  'setBg',
  'setOpacity',
  'setTopModel',
  'setSearchInputVisible',
  'setSwitchWebVisible',
  'setStarShowLogoAndName',
  'setStarShowPeopleNumber',
  'setStarBgColor',
  'setOpenScreenDialog',
  'setStarNameColor',
  'setUserCountColor',
  'setStarShowMowaIdentifier',
]);
actions.getChatRoomList = async function ({ commit }) {
  let { code, data } = await robotApi.getChatRoomList();
  if (code === 0) {
    let group = data.filter((item) => item.default_invite_room);
    commit('setGroup', group[0]);
  }
};
actions.getStarInfo = async function ({ commit }) {
  const { code, data } = await VisualApi.getHomeConfig();
  if (code === 0) {
    commit('setStarInfo', data);
  }
};

const mutations = {
  /**
   * 设置组件列表
   * @param cfgs 可视化详情
   * @param page_cfg 页面配置
   */
  setGroup(state, data) {
    state.group = data;
  },
  setModuleList,
  updateModuleList(state, data) {
    state.moduleList = data;
  },
  selectModule(state, data) {
    // if (data.type === 'slide_show') {
    //   data.autoHeight = data.autoHeight ? 1 : undefined;
    // }
    if (state.moduleSelected !== data) {
      state.moduleSelected = data;
      return;
    }
    if (state.moduleSelected && data) {
      state.moduleSelected = data;
    }
  },
  pushModuleConfig(state, { key }) {
    state.moduleSelected[key].push({});
  },
  removeModuleConfig(state, { key, index }) {
    state.moduleSelected[key].splice(index, 1);
    this.commit('checkModulValid');

    if (state.moduleSelected.modify !== 'add') {
      state.moduleSelected.modify = 'modify';
    }
  },
  setModuleConfig(state, { key, val }) {
    const keys = key.split('.');
    const leg = keys.length;
    keys.reduce((name1, name2, index) => {
      if (index === 1) {
        return state.moduleSelected[name1][name2];
      } else {
        if (index === leg - 1) {
          if (typeof data === 'object') {
            Object.assign(name1[name2], val);
          } else {
            name1[name2] = val;
          }
        }
        return name1[name2];
      }
    });

    /*
     * 解决vuex 层级过深，导致无法实时更新的问题
     * */
    if (state.moduleSelected.modify !== 'add') {
      state.moduleSelected.modify = 'modify';
    }

    const moduleSelected = state.moduleSelected[keys[0]];
    state.moduleSelected[keys[0]] = null;
    state.moduleSelected[keys[0]] = moduleSelected;

    this.commit('checkModulValid');
    console.log('setModuleConfig');
  },

  hideInputDialog(state) {
    state.dialogInputShow = false;
  },
  setDialogList(state, data) {
    state.dialogConfigList = data || [];
  },
  setDialogType(state, data) {
    state.dialogType = data;
  },
  setDialogObj(state, data) {
    state.dialogObj = data;
  },

  showChooseDialog(state, data) {
    state.dialogShow = true;
    state.dialogCallback = data;
  },
  endDialog(state, data) {
    state.dialogShow = false;
    state.dialogCallback(data);
  },

  showInputDialog(state, data) {
    state.dialogInputShow = true;
    state.dialogInputCallBack = data.callback;
    state.dialogInputUrl = data.url;
  },
  endInput(state, data) {
    state.dialogInputCallBack(data);
  },
  setDeleteList(state, data) {
    let params = {
      id: data.id,
      type: data.type,
      modify: 'delete',
    };
    state.deleteList.push(params);
  },
  setTaskGuide(state, data) {
    state.taskGuide = data;
  },
  checkModulValid(state) {
    const list = state.moduleList;

    //不检测列表
    const unCheckList = {
      MULTIPLE: {
        title: true,
      },
      SINGLE: {
        title: true,
      },
      IMG_TEXT: {
        img: true,
      },
      TITLE: {
        img: true,
        url: true,
      },
      TASK_INVITATION: {
        type: true,
        title: true,
      },
      /* 'INTERESTS': {
				type: true,
				title: true
			} */
    };

    let spmModuleErrorList = {};
    let spmModuleValid = true;
    state.spmModuleErrorList = {}; //数据重置
    state.spmModuleValid = true; //数据重置

    list.forEach((moduleListItem, index) => {
      const unCheck = unCheckList[moduleListItem.type] || {};
      spmModuleErrorList[index] = {};
      moduleListItem.unit_list.forEach((item, i) => {
        spmModuleErrorList[index][i] = {};
        if (!unCheck.img && !item.img) {
          spmModuleErrorList[index].isErr = true;
          spmModuleErrorList[index][i].img = 'simplet.error.noImg';
          spmModuleValid = false;
        }
        if (!unCheck.type) {
          // 不是 邀请类型

          if (!item.type && item.type !== 0) {
            spmModuleErrorList[index].isErr = true;
            spmModuleErrorList[index][i].value = 'simplet.error.noType';
            spmModuleValid = false;
          } else if (item.type < 4 && !item.id) {
            spmModuleErrorList[index].isErr = true;
            spmModuleErrorList[index][i].value = 'simplet.error.noDirect';
            spmModuleValid = false;
          } else if (!unCheck.url && item.type == 4 && !item.url) {
            spmModuleErrorList[index].isErr = true;
            spmModuleErrorList[index][i].value = 'simplet.error.noUrl';
            spmModuleValid = false;
          } else if (!unCheck.title && item.type == 4 && !item.title) {
            spmModuleErrorList[index].isErr = true;
            spmModuleErrorList[index][i].value = 'simplet.error.noTitle';
            spmModuleValid = false;
          }
        }
      });
      // JSON.stringify(getters.moduleList) === JSON.stringify(state.originModuleList)?false:true,
      if (JSON.stringify(state.moduleSelected) === JSON.stringify(moduleListItem)) {
        state.unitErrorList = spmModuleErrorList[index];
      }
    });
    state.spmModuleErrorList = spmModuleErrorList; //数据重置
    state.spmModuleValid = spmModuleValid; //数据重置
  },
  setUnitErrorList(state, data) {
    state.unitErrorList = data;
  },
  setTaskList(state, data) {
    let listArr = [];
    let list = data.filter((item) => item.active);
    if (list.length >= 2) {
      listArr = list.slice(0, 2);
    } else {
      listArr = list;
    }
    state.taskList = listArr;
  },
  // 弹窗
  openDialog(state, data) {
    const { type, callBack, isRadio, option } = data;
    state.dialogObject.dialogType = type;
    state.dialogObject.dialogShow = true;
    if (option) {
      state.dialogObject.option = JSON.parse(JSON.stringify(option));
    }
    state.dialogObject.dialogCallback = callBack;
    state.dialogObject.isRaido = isRadio;
  },
  hideDialog() {
    state.dialogObject.dialogShow = false;
  },
  // 绑定数据
  bindData(state, data) {
    const { key, value } = data;
    const keys = key.split('.');
    const leg = keys.length;
    if (keys.length <= 2) {
      if (keys.length === 1) {
        state.moduleSelected[keys[0]] = value;
      } else {
        state.moduleSelected[keys[0]][keys[1]] = value;
      }
    } else {
      keys.reduce((name1, name2, index) => {
        if (index === 1) {
          return state.moduleSelected[name1][name2];
        } else {
          if (index === leg - 1) {
            if (typeof value === 'object') {
              Object.assign(name1[name2], value);
            } else {
              name1[name2] = value;
            }
          }
          return name1[name2];
        }
      });
    }
  },
  // 删除模块
  deleteItem(state, data) {
    const itemIndex = state.moduleList.findIndex((item) => item === data);
    if (itemIndex !== -1) {
      ({
        showClose: true,
        message: '改模块不合法，请刷新后再试',
        type: 'error',
      });
    }
    if (data === state.moduleSelected) {
      state.moduleSelected = null;
    }
    state.moduleList.splice(itemIndex, 1);
  },
  setStarInfo(state, data) {
    state.starInfo = data;
  },
  setBg(state, data) {
    state.page_cfg.bg = data;
    const a = state.page_cfg;
    state.page_cfg = null;
    state.page_cfg = a;
  },
  setOpacity(state, data) {
    state.page_cfg.opacity = data;
  },
  setSearchInputVisible(state, data) {
    state.page_cfg.isOpenSearchInput = data;
  },
  setSwitchWebVisible(state, data) {
    state.page_cfg.isOpenSwitchWeb = data;
  },
  /**
   * 可视化 - 设置是否显示logo和名称
   * @param {object} state store.state
   * @param {boolean} data  boolean
   */
  setStarShowLogoAndName(state, data) {
    state.page_cfg.isOpenStarLogoAndName = data;
  },

  setStarNameColor(state, data) {
    state.page_cfg.starNameColor = data;
  },

  setUserCountColor(state, data) {
    state.page_cfg.starUserCountColor = data;
  },

  /**
   * 可视化 - 设置是否显示居民数
   * @param {object} state  store.state
   * @param {boolean} data  boolean
   */
  setStarShowPeopleNumber(state, data) {
    state.page_cfg.isOpenStarPeopleNumber = data;
  },

  /**
   * 可视化 - 设置是否显示mowa标识
   * @param {object} state  store.state
   * @param {boolean} data  boolean
   */
  setStarShowMowaIdentifier(state, data) {
    state.page_cfg.isOpenMowaIdentifier = data;
  },

  /**
   *  设置星球首页背景颜色
   * @param {object} state
   * @param {boolean} data
   */
  setStarBgColor(state, data) {
    state.page_cfg.bgColor = data;
  },
  setTopModel(state, data) {
    state.page_cfg.topModel = data;
    const a = state.page_cfg;
    state.page_cfg = null;
    state.page_cfg = a;
  },

  /**
   * 开屏弹窗
   * @param state
   * @param data
   */
  setOpenScreenDialog(state, data) {
    state.page_cfg.isOpenScreenDialog = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
