import SlsWebLogger from 'js-sls-logger'
let opts =null
if (process.env.VUE_APP_EVENT == "prod") {
   opts = {
    host: 'cn-hangzhou.log.aliyuncs.com',      
    project: 'k8s-log-c229085d64edc4c6d9d1f715335f94c3a',                 
    logstore: 'fe_gass',               
    time: 10, 
    count: 10, 
  }
}else if(process.env.VUE_APP_EVENT == "test"){
   opts = {
    host: 'cn-hangzhou.log.aliyuncs.com',      
    project: 'k8s-log-c91fe8f4cfcd74b43a5de31deee1bdfb8',                 
    logstore: 'testpv',               
    time: 10, 
    count: 10, 
  }
}
  let logger
  if(opts){
     logger = new SlsWebLogger(opts)

  }
  const getUserInfo=function(){
    const userInfo = sessionStorage.getItem('userInfo')
    try{
     return  userInfo&&JSON.parse(userInfo)||{}
    }catch(e){
      return {}
    }
  }
  const send=(pramas)=>{

    try{
      logger&&logger.send(pramas)
    }catch(e){
      console.log(e)
    }
  }

  export default {
    routeGo({to,from,toName,fromName}){
      const userInfo=getUserInfo()
      send({
        event:'r',//路由
        to,from,
        user:userInfo.id||'',
        toName,fromName
      })
    
    }
  }