/*
 * @Author: sky.li
 * @LastEditors: sky.li
 * @Description: store的相关构造器
 * @可以输入预定的版权声明、个性签名、空行等
 */
import { isString } from "util";

/**
 * makeAction
 * @param {String | Function} actionArray
 * @Description:store action构造器,如果传入的为string，则赋值到Mutation，否则直接将里面的内容提出，递归赋值
 * @example
 * makeAction(
 *   [
 *    'test',
 *    {
 *        newAction({ commit }, data){
 *          commit('newAction', data);
 *      }
 *    }
 *    ]
 * )
 */
export const makeAction = actionArray => {
  const actions = {};
  actionArray.forEach(item => {
    if (isString(item)) {
      actions[item] = ({ commit }, data) => commit(item, data);
    } else {
      actions = { ...actions, ...item };
    }
  });
  return actions;
};

/**
 * makeGetter
 * @param {String | Function} getterrray
 * @Description:store getter,如果传入的为string，则赋值commit到对应的Mutation，否则直接将里面的内容提出，递归赋值
 * @example
 * makeGetter(
 *   [
 *    'test',
 *    {
 *        newTest(state, data){
 *         return state.newTest
 *      }
 *    }
 *    ]
 * )
 */
export const makeGetter = getterrray => {
  const getters = {};
  getterrray.forEach(item => {
    if (isString(item)) {
      getters[item] = state => state[item];
    } else {
      getters = { ...getters, ...item };
    }
  });
  return getters;
};
