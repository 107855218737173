/**
 * @author Tianyuan.li
 * @email Tianyuan.li@magicwindow.cn
 * @date 2018/4/12 下午12:19
 * @description
 */
import dateTime from './dateTime'
import time from './time'
import numb from './numb'
import money from './money'

export default {
  dateTime,
  time,
  numb,
  money
}
