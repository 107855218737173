/*
 * @Author: your name
 * @Date: 2020-10-14 16:56:47
 * @LastEditTime: 2020-10-29 20:55:29
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /mw-merculet-saas-web/src/router/live.js
 */
export default {
  path: "live",
  meta: {
    name: "直播管理"
  },
  component: () => import("@/components/main-children"),
  children: [
    {
      path: "list",
      meta: {
        name: "直播列表",

        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("@/views/live-management/list")
    },
    {
      path: "manage",
      meta: {
        name: "直播账户管理",

        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("@/views/live-management/manage")
    }
  ]
};
