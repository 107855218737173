/**
 * SPT水晶星球大板块 - 共识宝模块
 */

export default [
  {
    path: "addArticle",
    meta: {
      name: "新建文章",
      login: true,
      Authentication: true
    },
    component: () => import("@/views/spt/cms/edit-article.vue")
  },
  {
    path: "recomend-page-managent",
    meta: {
      name: "推荐页管理",
      login: true,
      Authentication: true
    },
    component: () =>
      import("@/views/spt/cms/recomend-page-management/index.vue")
  },
  {
    path: "article-template",
    meta: {
      name: "文章模版",
      login: true,
      Authentication: true
    },
    component: () => import("@/views/spt/cms/article-template/index.vue")
  },
  {
    path: "article-template-add",
    meta: {
      name: "新建文章模版",
      login: true,
      Authentication: true
    },
    component: () => import("@/views/spt/cms/article-template/add.vue")
  },
  {
    path: "editArticle",
    meta: {
      login: true,
      Authentication: true,
      name: "编辑文章"
    },
    component: () => import("@/views/spt/cms/edit-article.vue")
  },

  {
    path: "article",
    meta: {
      name: "文章管理",
      login: true,
      Authentication: true
    },
    component: () => import("@/components/spt/CMS/article.vue")
  },
  {
    path: "article-read-detail",
    meta: {
      name: "文章阅读详情",

      login: true,
      Authentication: true
    },
    component: () => import("@/views/spt/cms/article-read-detail.vue")
  },
  {
    path: "comment-article",
    meta: {
      name: "文章评论管理",

      login: true,
      Authentication: true
    },
    component: () => import("@/components/spt/CMS/comment-details")
  },
  {
    path: "article-statistics",
    meta: {
      name: "文章统计",
      login: true,
      Authentication: true
    },
    component: () => import("../views/spt/cms/article-statistics.vue")
  },
  {
    path: "article-class",
    name: "文章分类",
    meta: {
      login: true,
      Authentication: true
    },
    component: () => import("../views/spt/cms/article-class.vue")
  },
  {
    path: "article-banners",
    meta: {
      name: "广告位管理",

      login: true,
      Authentication: true
    },
    component: () => import("../views/spt/cms/article-banners.vue")
  },
  {
    path: "friendCircle-forward",
    meta: {
      name: "视频号管理",

      login: true,
      Authentication: true
    },
    component: () => import("../views/spt/cms/friendCircle-forward.vue")
  },
  {
    path: "friendCircle-info",
    meta: {
      name: "评论管理",

      login: true,
      Authentication: true
    },
    component: () => import("../views/spt/cms/friendCircle-info.vue")
  },

  {
    path: "koc-details",
    meta: {
      name: "视频号详情",

      login: true,
      Authentication: true
    },
    component: () => import("../views/spt/cms/koc-details.vue")
  },

  {
    path: "topic-tags-details",
    meta: {
      name: "话题详情",
      login: true,
      Authentication: true
    },
    component: () => import("../views/spt/cms/topic-tags-details.vue")
  },
  {
    path: "article-pay",
    meta: {
      name: "内容订单管理",

      login: true,
      Authentication: true
    },
    component: () => import("../views/spt/cms/article-pay.vue")
  }
];
