/**
 * @author Tianyuan.li
 * @email Tianyuan.li@magicwindow.cn
 * @date 1/11/19 11:45 AM
 * @description
 */
const MainChildren = () => import("@/components/main-children.vue");

//群商城商品列表
const mall = () => import("../views/group/mall/mall.vue");

//群商城商品列表
const goodsList = () => import("../views/group/mall/goods-list.vue");

//群商城订单列表
const orderList = () => import("../views/group/mall/order-list.vue");

//群商城资产
const property = () => import("../views/group/mall/property.vue");

// 各种记录
const record = () => import("../views/group/record");

// 结算记录
const settlementList = () => import("../views/group/mall/settlement-list");

// 提现记录
const withdrawList = () => import("../views/group/mall/withdraw-list");

// 提现记录
const customService = () => import("../views/group/mall/custom-service");

// 运费模板
const freightTemplate = () =>
  import("../views/group/mall/freight-template-list.vue");

// 支付方式设置
const settingPay = () => import("../views/group/setting-pay.vue");

// 交易对账单
const transationStatement = () =>
  import("../views/group/transation-statement.vue");

//购物奖励
const awardsList = () => import("../views/group/mall/buy-awards.vue");

// 分销home
const distributionSale = () => import("../views/group/distribution-sale/home");

// 分销等级佣金
const distributionSaleLevel = () =>
  import("../views/group/distribution-sale/level");

// 分销订单
const distributionSaleOrder = () =>
  import("../views/group/distribution-sale/order");

// 分销业绩
const distributionSaleIncome = () =>
  import("../views/group/distribution-sale/income");

// 分销设置
const distributionSaleSettings = () =>
  import("../views/group/distribution-sale/settings");

// 提现申请
const distributionSaleApplyWithdraw = () =>
  import("../views/group/distribution-sale/apply-withdraw");

//发货地址
const fromAddressConfig = () =>
  import("../views/group/mall/from-address-config");

// 商户充值记录
const chargeRecord = () => import("../views/group/mall/account-charge-record");

// 商户充值记录
const mediaChargeRecord = () =>
  import("../views/group/mall/media-charge-record");

const group = {
  path: "group",
  name: "group.index",
  meta: {
    login: true,
    Authentication: true,
    noVerifyToken: false
  },
  component: MainChildren,
  children: [
    {
      path: "mall",
      name: "group.mall.name",
      component: mall,
      children: [
        {
          path: "goods",
          name: "group.goods.name",
          meta: {
            login: true,
            Authentication: true,
            noVerifyToken: false
          },
          component: goodsList
        },
        {
          path: "order",
          name: "group.order.name",
          meta: {
            login: true,
            Authentication: true,
            noVerifyToken: false
          },
          component: orderList
        },
        {
          path: "awards",
          name: "购物奖励",
          meta: {
            login: true,
            Authentication: true,
            noVerifyToken: false
          },
          component: awardsList
        },
        {
          path: "property",
          name: "group.property.name",
          meta: {
            login: true,
            Authentication: true,
            noVerifyToken: false
          },
          component: property
        },
        {
          path: "freight-template",
          name: "group.freightTemplate.name",
          meta: {
            login: true,
            Authentication: true,
            noVerifyToken: false
          },
          component: freightTemplate
        },
        {
          path: "custom-service",
          name: "group.custom.service",
          meta: {
            login: true,
            Authentication: true,
            noVerifyToken: false
          },
          component: customService
        },
        {
          path: "from-address-config",
          name: "group.fromAddressConfig.name",
          meta: {
            login: true,
            Authentication: true,
            noVerifyToken: false
          },
          component: fromAddressConfig
        }
      ]
    },
    {
      path: "distribution-sale",
      name: "group.distribution-sale.name",
      component: distributionSale,
      children: [
        {
          path: "settings",
          name: "group.ds.settings.name",
          meta: {
            login: true,
            Authentication: true,
            noVerifyToken: false
          },
          component: distributionSaleSettings
        },
        {
          path: "order",
          name: "group.ds.order.name",
          meta: {
            login: true,
            Authentication: true,
            noVerifyToken: false
          },
          component: distributionSaleOrder
        },
        {
          path: "income",
          name: "group.ds.income.name",
          meta: {
            login: true,
            Authentication: true,
            noVerifyToken: false
          },
          component: distributionSaleIncome
        },
        {
          path: "apply-withdraw",
          name: "group.ds.withdraw.name",
          meta: {
            login: true,
            Authentication: true,
            noVerifyToken: false
          },
          component: distributionSaleApplyWithdraw
        }
      ]
    },
    {
      path: "settlement",
      name: "group.settlement.name",
      component: settlementList
    },
    {
      path: "withdrawList",
      name: "group.withdrawList.name",
      component: withdrawList
    },
    {
      path: "record/:type",
      name: "group.record.name",
      component: record
    },
    {
      path: "setting-pay",
      name: "group.setting-pay.name",
      component: settingPay
    },
    {
      path: "transation-statement",
      component: transationStatement,
      meta: {
        name: "group.transation-statement.name"
      }
    },
    {
      path: "charge-record",
      component: chargeRecord,
      meta: {
        name: "商户充值记录"
      }
    },
    {
      path: "media-charge-record",
      component: mediaChargeRecord,
      meta: {
        name: "视频流量充值记录"
      }
    }
  ]
};

export default group;
