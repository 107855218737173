import Status from "@/serving/status";
import api from "@/api/login";
import VueCookies from "vue-cookies";
import storage from "@/serving/storage.serving";
import checkType from "@/serving/checkType";

function checkPhone() {
  var userAgentInfo = navigator.userAgent;
  var Agents = new Array("Android", "iPhone", "SymbianOS", "Windows Phone", "iPod");
  var flag = false;
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = true;
      break;
    }
  }
  return flag;
}

const getUserInfo = async (to, form, next) => {
  if (to.path.startsWith("/plugins/mobile")) {
    next();
    return
  }
  let { code, data } = await api.getUserInfo();
  if (code === 0) {
    if (!checkType(data.sys_version, to)) return;

    const emailStatus = Status.getUserStatus(data.status); // 邮箱状态：-3邮箱验证中，-2邮箱已验证未填充信息 -1审核失败 1审核通过
    storage.set(emailStatus, "emailStatus");
    storage.set(data, "userInfo");
    if (to.meta.login && to.path !== "/regist/0" && to.path !== "/regist/3") {
      // 必须要登录的页面进行邮箱验证
      emailStatusValidation(emailStatus, data, to, form, next);
    } else {
      next();
    }
  } else {
    next();
  }
};

const emailStatusValidation = (emailStatus, data, to, form, next) => {
  if (emailStatus === "emailValidation" || emailStatus === "failed") {
    // 邮箱验证中 或 审核失败
    if (to.path !== "/regist/1") {
      next("/regist/1");
    } else {
      next();
    }
  } else {
    // 邮箱已验证未填充信息 或 审核通过 目前没有了邮箱已验证未填充信息的状态
    if (to.path === "/regist/1") {
      next("/regist/3");
    } else {
      // 判断电子合同 三个步骤 状态
      contractValidation(data, to, form, next);
    }
  }
};

const contractValidation = (data, to, form, next) => {
  // !data.agree_protocol ||
  //       data.agree_protocol != true
  if (data.account) {
    if (
      (data.account.status !== 1 || data.account.contract_status === 0 || data.account.payment_status !== 2 || !data.account.agree_protocol || data.account.agree_protocol != true) &&
      !to.meta.contract
    ) {
      // 三步有任何一步是未完成/审核中 并且将要跳转的不是电子合同页面  跳转三步引导页
      next("/contract/guide");
    } else if (!data.app_uat && !to.meta.contract) {
      next("/contract/certificate-create");
    } else {
      next();
    }
  } else {
    next("/");
  }
};

export default (router) => {
  router.beforeEach((to, from, next) => {
    // if (checkPhone() && to.path !== "/mobile") {
    //   next("/mobile");
    // } else {
    const token = VueCookies.get("token");
    if (token) {
      if (to.path !== "/login") {
        getUserInfo(to, from, next);
      } else {
        next();
      }
      /* } */
    } else {
      if (to.meta.login && to.path !== "/regist/0" && to.path !== "/regist/3") {
        next("/login");
      } else {
        next();
      }
    }
    // }
  });
};
