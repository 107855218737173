/**
 * @author Tianyuan.li
 * @email Tianyuan.li@magicwindow.cn
 * @date 2018/4/10 下午9:13
 * @description
 */

import { base } from "@/config/index";
import http from "@/serving/http.serving";

export default {
  login: {
    login: (params) => http.httpPost(`${base}/login`, params),
  },
  singleLogin: (params) => http.httpGet(`${base}/singleLogin`, params),
  logout: () => http.httpPost(`${base}/logout`),
  // GET /v1/merculetManagement/register/verifyCode

  verifyCode: () => http.httpPost(`${base}/register/verifyCode`),

  register: (params) => http.httpPost(`${base}/register`, params),

  getUserInfo: (params) => http.httpGet(`${base}/sysUser/getUserInfo`, params),

  userSupplement: (params) => http.httpPost(`${base}/userInfo/supplement`, params),

  updatepwd: (params) => http.httpPost(`${base}/sysUser/updatePassword`, params),

  resetSendEmail: (params) => http.httpPost(`${base}/forgetPassword/sendEmail`, params),

  resetUpdate: (params) => http.httpPost(`${base}/forgetPassword/update`, params),

  resendEmail: () => http.httpPost(`${base}/resendRegisterEmail`),

  companyAuthen: (params) => http.httpPost(`${base}/company/authen`, params),

  sendSms: (params) => http.httpPost(`${base}/userInfo/sendSms`, params),

  countryList: (params) => http.httpGet(`${base}/sysUser/countryList`, params),

  sysUserUpdate: (params) => http.httpGet(`${base}/sysUser/update`, params),
  // 获取权限
  getPermissionConfig: (params) => http.httpGet(`${base}/sysUser/getPermissionConfig`, params),

  // 阅读星球主协议
  agreeProtocol: (params) => http.httpGet(`${base}/sysUser/agreeProtocol`, params),

  // 合同签署
  updateContractStatus: (params) => http.httpGet(`${base}/sysUser/updateContractStatus`, params),

  // 完成付费
  updatePaymentStatus: (params) => http.httpGet(`${base}/sysUser/updatePaymentStatus`, params),
  // 激活激活码
  activeStarCode: (params) => http.httpGet(`${base}/sysUser/activeStarCode`, params),
  // 获取已经激活的激活码
  getActiveStarCode: (params) => http.httpGet(`${base}/sysUser/getActiveStarCode`, params)
};
