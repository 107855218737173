/*
 * @Author: sky.li
 * @LastEditors: Zhaoheng && hellolad@126.com
 * @Description:
 * @可以输入预定的版权声明、个性签名、空行等
 */
/**
 * 整理可视化返回数据的处理
 *
 */
import tabbarBase from '@/store/visual/tabbarBase.js';

function getUiConfig(item) {
  let res = {};
  try {
    res = JSON.parse(item.ui_cfg);
    delete res.ui_cfg; // 历史版本中，有ui_cfg重复赋值的状况，此处消除该问题
  } catch (e) {
    return {};
  }
  const type = item.c_key; // 组件的类型，在接口中用c_key表示
  res.type = type; // 前端使用type做表示，此处做映射

  const biz_data = JSON.parse(item.biz_data || '[]'); // 组件所需的数据返回值
  /**
   * 一般在config中只存储配置相关信息，由配置而获取的数据信息由后端提供
   * 所以在可视化编辑的时候，有部分组件，需要将后端的数据信息填充到config属性中
   */
  if (type === 'topic') {
    if (!res.config) {
      res.config = biz_data[0].items[0] || res.config;
    }
  }
  if (type === 'vod') {
    try {
      if (biz_data[0].items[0]) {
        res.config.config = biz_data[0].items[0];
      } else {
        res.config = res.config;
      }
      // res.config = biz_data[0].items[0] || res.config;
    } catch (e) {
      console.log(e);
    }
  }
  if (type === 'moments') {
    try {
      res.config = biz_data[0].items.map((subItem) => {
        subItem.id = subItem.moment_id;
        subItem.type = 'moments';
        return subItem;
      });
    } catch (e) {
      // res.config = [];
    }
  }
  if (type === 'my_crystal') {
    res.config = res.config || {};
  }
  if (type === 'coupon') {
    try {
      res.config = biz_data[0].items.map((subItem) => {
        subItem.id = subItem.coupon_id;
        subItem.type = type;
        return subItem;
      });
    } catch (e) {
      // res.config = [];
    }
  }
  if (type === 'goods' || type === 'score_goods' || type === 'pin_goods' || type === 'friend_assist') {
    if (biz_data && biz_data[0] && biz_data[0].items) {
      res.tempGoods = biz_data[0].items.map((item) => {
        if (type === 'friend_assist') {
          let activity_info = {
            people_number: 2,
            price: 0,
          };
          try {
            const config_data = JSON.parse(item.activity_info.config_data);
            const rules = config_data.rules[0];
            activity_info.people_number = rules.people_number;
            activity_info.price = rules.discount * config_data.original_price;
          } catch (e) {
            console.log(e);
          }
          item.activity_info = activity_info;
        } else {
          try {
            if (item && item.activity_info && item.activity_info.config_data) {
              item.activity_info.config_data = JSON.parse(item.activity_info.config_data);
            }
          } catch (e) {
            console.log(e);
          }
        }

        item.id = item.goods_id;
        item.name = item.goods_name;
        item.type = type;
        item.cover_img_url = item.goods_image_url;
        item.sale_price = item.price;

        return item;
      });
      if (!/category/.test(biz_data[0].type)) {
        res.config = JSON.parse(JSON.stringify(res.tempGoods));
      }
      if (biz_data[0].type === 'goods_category' || biz_data[0].type === 'score_goods_category') {
        res.baseTempGoods = JSON.parse(JSON.stringify(res.tempGoods));
      }
    } else {
      res.tempGoods = [];
      res.baseTempGoods = [];
    }
  }

  if (type === 'store') {
    try {
      res.tempStores = biz_data[0].items;
    } catch (e) {
      res.tempStores = [];
    }
  }
  if (type === 'join_group') {
    try {
      res.group = biz_data[0].items[0];
    } catch (e) {
      console.log(e);
    }
  }
  if (type === 'title_component') {
    try {
      res.config = [res.config];
    } catch (e) {
      console.log(e);
    }
  }
  return res;
}
export default (state, { cfgs = [], page_cfg = '{}', tab_config = '{}' }) => {
  const ModuleList = cfgs.map(getUiConfig);
  console.log('ModuleList', cfgs);
  let bg = 'https://img.merculet.cn/home/page/visual-top-bg.png';
  let opacity = 100;
  let topModel = 'default';
  let isOpenSearchInput = true;
  let isOpenSwitchWeb = true;
  let isOpenStarLogoAndName = true;
  let starNameColor = '#F7F7F7';
  let starUserColorColor = '#F7F7F7';
  let isOpenStarPeopleNumber = false;
  let isOpenMowaIdentifier = false;
  let isOpenScreenDialog = false;
  let bgColor = '#f7f7f7';

  try {
    page_cfg = JSON.parse(page_cfg);
    bg = page_cfg.bg || bg;
    topModel = page_cfg.topModel || topModel;
    opacity = page_cfg.opacity || opacity;
    bgColor = page_cfg.bgColor || bgColor;
    if (page_cfg.isOpenSearchInput != null) {
      isOpenSearchInput = page_cfg.isOpenSearchInput;
    }
    if (page_cfg.isOpenSwitchWeb != null) {
      isOpenSwitchWeb = page_cfg.isOpenSwitchWeb;
    }
    if (page_cfg.isOpenStarLogoAndName != null) {
      isOpenStarLogoAndName = page_cfg.isOpenStarLogoAndName;
      starNameColor = page_cfg.starNameColor || '#f7f7f7';
    }
    if (page_cfg.isOpenStarPeopleNumber != null) {
      isOpenStarPeopleNumber = page_cfg.isOpenStarPeopleNumber;
      starUserColorColor = page_cfg.starNameColor || '#f7f7f7';
    }
    if (page_cfg.isOpenMowaIdentifier != null) {
      isOpenMowaIdentifier = page_cfg.isOpenMowaIdentifier;
    }
    if (page_cfg.isOpenScreenDialog != null) {
      isOpenScreenDialog = page_cfg.isOpenScreenDialog;
    }
  } catch (e) {
    console.log(e);
  }
  state.moduleList = ModuleList;
  try {
    tab_config = JSON.parse(tab_config);
  } catch (e) {
    console.log(e);
  }
  if (!Array.isArray(tab_config)) {
    tab_config = tabbarBase();
  }
  state.tab_config = {
    config: tab_config,
    type: 'tabbar',
  };
  state.page_cfg = { bg, opacity, topModel, isOpenSearchInput, isOpenSwitchWeb, isOpenStarLogoAndName, starNameColor, isOpenStarPeopleNumber, starUserColorColor, bgColor, isOpenScreenDialog, isOpenMowaIdentifier };
};
