/*
 * @Author: sky.li
 * @LastEditors  : sky.li
 * @Description:
 * @可以输入预定的版权声明、个性签名、空行等
 */
import { base } from "@/config/index";

import http from "@/serving/http.serving";
import storage from "@/serving/storage.serving";

const getAppId = () => {
  const leftMenu = storage.get("leftMenu");
  return {
    app_id: leftMenu.appId
  };
};

export default {
  leftList: (params) =>
    http.httpPost(`${base}/permission/menu/company/list`, params),
  loadMenu: (params) => http.httpGet(`${base}/sysUser/loadMenu`, params),

  // appList: params => http.httpGet(`https://www.easy-mock.com/mock/5ac874fa21ab0d533cccac0f/appList`, params),

  // 上传获取token和domain
  preupload: () => http.httpGet(`${base}/resource/preupload`),
  // 视频类上传token
  getMediaUploadToken: () =>
    http.httpGet(`${base}/resource/getMediaUploadToken`),

  // 查询应用时区和名称
  findTimeZoneAndUavName: () =>
    http.httpGet(`${base}/config/app/findTimeZoneAndUavName`, {
      appId: getAppId().app_id
    }),

  // 修改时区和名称
  updateTimeZoneAndUavName: (params) =>
    http.httpGet(`${base}/config/app/updateTimeZoneAndUavName`, params),
  // 修改时区和名称
  createVideoUploadUsingPOST: (params) =>
    http.httpPost(`${base}/vod/createVideoUpload`, params),

  // 查询所有分组
  getGroupList: (params) =>
    http.httpGet(`${base}/group/list`, params),

  // 从分组中查询图片
  findImagesFromGroup: (params) =>
    http.httpGet(`${base}/groupImage/findImagesFromGroup`, params),
  // 移动图片到分组中
  moveImagesToGroup: (params) =>
    http.httpPost(`${base}/groupImage/moveImagesToGroup`, params),
  // 从分组中移除图片
  removeImagesFromGroup: (params) =>
    http.httpPost(`${base}/groupImage/removeImagesFromGroup`, params),
  // 上传图片到分组
  uploadImagesToGroup: (params) => 
    http.httpPost(`${base}/groupImage/uploadImagesToGroup`, params),
  // 新建分组
  createGroup: (params) =>
    http.httpPost(`${base}/group/create`, params),
  // 编辑分组
  modifyGroup: (params) =>
    http.httpPost(`${base}/group/modifyGroup`, params),
  // 删除分组且数据移至未分组
  removeGroup: (params) =>
    http.httpPost(`${base}/group/removeGroup?id=${params.id}`),
  // 删除分组以及数据
  removeGroupAll: (params) =>
    http.httpPost(`${base}/group/removeGroupAll?id=${params.id}`),
  // 从分组中查询视频
  findVideosFromGroup: (params) =>
    http.httpGet(`${base}/groupVideo/findVideosFromGroup`, params),
  // 移动视频至分组
  moveVideosToGroup: (params) =>
    http.httpPost(`${base}/groupVideo/moveVideosToGroup`, params),
  // 从分组中移动视频
  removeVideosFromGroup: (params) =>
    http.httpPost(`${base}/groupVideo/removeVideosFromGroup`, params),
  // 上传视频
  uploadVideoToGroup: (params) =>
    http.httpPost(`${base}/groupVideo/uploadVideoToGroup`, params),
  // 重命名
  updateImagesName: (params) =>
    http.httpPost(`${base}/groupImage/updateImages`, params),
  // 修改视频信息 uploadVideoToGroup
  modifyVideoInfo: (params) =>
    http.httpPost(`${base}/groupVideo/modifyVideoInfo`, params),
  // 根据video_id查询视频详细信息 （不要用）
  findVodVideoByVideoId: (params) =>
    http.httpGet(`${base}/groupVideo/findVodVideoByVideoId?video_id=${params.video_id}`),
  // 获取短连接
  shortUrl: (params) =>
    http.httpGet(`${base}/surl/create`, params)
};
