/*
 * @Author: sky.li
 * @LastEditors: sky.li
 * @Description:
 * @可以输入预定的版权声明、个性签名、空行等
 */
import goodsCategory from "./goodsCategory";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default {
  mallGoodsCategory: goodsCategory,
};
