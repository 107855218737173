import http from '@/serving/http.serving';

export default {
  // 商品 列表
  originalProductList: (params) => http.get(`/v1/merculetManagement/ecshop/originalProducts/page`, params),
  originalProductMediaList: (params) => http.get(`/v1/merculetManagement/ecshop/originalProducts/media/page`, params),
  // 新增 商品
  setSortNo: (params) => http.get(`/v1/merculetManagement/ecshop/products2/setSortNo`, params),
  add: (params) => http.post(`/v1/merculetManagement/ecshop/products`, params),
  // 新增 商品
  saveOriginalProduct: (params) => http.post(`/v1/merculetManagement/ecshop/originalProducts/save`, params),
  // 根据商品ID获取商品信息
  getOriginalProductInfo: (params) => http.get(`/v1/merculetManagement/ecshop/originalProducts/get`, params),
  // 停售并且删除某商品
  deleteOriginalProduct: (params) => http.post(`/v1/merculetManagement/ecshop/originalProducts/delete?id=${params.id}`),

  // 新增 课程商品
  saveOriginalProductMedia: (params) => http.post(`/v1/merculetManagement/ecshop/originalProducts/media/save`, params),
  // 根据课程商品ID获取商品信息
  getOriginalProductMediaInfo: (params) => http.get(`/v1/merculetManagement/ecshop/originalProducts/media/get`, params),
  // 停售并且删除某课程商品
  deleteOriginalProductMedia: (params) => http.post(`/v1/merculetManagement/ecshop/originalProducts/media/delete?id=${params.id}`),
  // 根据商品ID或者批次ID获取商品批次信息
  getOriginalBatchInfo: (params) => http.get(`/v1/merculetManagement/ecshop/originalBatchProducts/get`, params),
  // 新增 批次
  saveOriginalBatch: (params) => http.post(`/v1/merculetManagement/ecshop/originalBatchProducts/save`, params),
  // 某批次新增库存
  addInventoryForBatch: (params) =>
    http.post(
      `/v1/merculetManagement/ecshop/originalBatchProducts/addInventory?original_batch_product_id=${params.original_batch_product_id}&original_batch_product_sku_id=${params.original_batch_product_sku_id}&store_num=${params.store_num}`,
    ),
  // 课程商品某批次新增库存
  addInventoriesMediaForBatch: (params) => http.post(`/v1/merculetManagement/ecshop/originalBatchProducts/media/addInventories`, params),
  // 卡券类商品某批次新增库存
  addInventoriesForBatch: (params) => http.post(`/v1/merculetManagement/ecshop/originalBatchProducts/addInventories`, params),
  // 某批次立即停售
  removeFromStock: (params) => http.post(`/v1/merculetManagement/ecshop/originalBatchProducts/removeFromStock?original_batch_product_id=${params.original_batch_product_id}`),
  // 某批次恢复供应
  addToStock: (params) => http.post(`/v1/merculetManagement/ecshop/originalBatchProducts/addToStock?original_batch_product_id=${params.original_batch_product_id}`),
  // 删除某批次
  deleteOriginalBatch: (params) => http.post(`/v1/merculetManagement/ecshop/originalBatchProducts/delete?id=${params.original_batch_product_id}`),
  // 获取批次预设购买留言字段
  getOriginalBatchFields: (params) => http.get(`/v1/merculetManagement/ecshop/originalBatchProducts/getAllPresetFields`, params),
  // 商品上架选择列表
  pageForOnShelf: (params) => http.get(`/v1/merculetManagement/ecshop/originalProducts/pageForOnShelf`, params),
  // 新增上架商品
  saveMarketProduct: (params) => http.post(`/v1/merculetManagement/ecshop/products2/onself`, params),
  // 新增上架商品
  saveMarketProductMedia: (params) => http.post(`/v1/merculetManagement/ecshop/products2/media/onself`, params),
  // 上架商品管理列表
  marketProductList: (params) => {
    if (!params.exclude_types) {
      params.excludeTypes = ['GIFT_CARD', 'TICKET'].toString();
    }
    return http.get(`/v1/merculetManagement/ecshop/products2/page`, params);
  },
  // 上架课程商品管理列表
  marketMediaProductList: (params) => http.get(`/v1/merculetManagement/ecshop/products2/media/page`, params),
  // 上架商品 详情
  marketDetails: (params) => http.get(`/v1/merculetManagement/ecshop/products2/get?id=${params.id}`),
  // 上架课程商品 详情
  marketMediaDetails: (params) => http.get(`/v1/merculetManagement/ecshop/products2/media/get?id=${params.id}`),
  // 上架商品新增库存
  addInventoryForMarket: (params) => http.post(`/v1/merculetManagement/ecshop/products2/addInventory?product_id=${params.product_id}&product_sku_id=${params.product_sku_id}&store_num=${params.store_num}`),
  // 上架商品状态修改
  marketOperate: (params) => http.put(`/v1/merculetManagement/ecshop/products2/operation/${params.operateType}`, params),

  // 老版本的商品列表
  list: (params) => http.get(`/v1/merculetManagement/ecshop/products`, params),
  // 编辑 商品
  edit: (params) => http.put(`/v1/merculetManagement/ecshop/products/${params.id}`, params),
  // 删除 商品
  delete: (params) => http.delete(`/v1/merculetManagement/ecshop/products/${params.id}`, params),
  // 商品 详情
  details: (params) => http.get(`/v1/merculetManagement/ecshop/products/${params.id}`),
  // 商品状态修改
  operate: (params) => http.put(`/v1/merculetManagement/ecshop/products/operation/${params.operateType}`, params),

  /**
   *
   * @param 课程商品相关接口
   */
  // 新增 商品
  mediaSetSortNo: (params) => http.get(`/v1/merculetManagement/ecshop/products2/media/setSortNo`, params),
  // 课程商品列表
  mediaList: (params) => http.get(`/v1/merculetManagement/ecshop/products`, params),
  // 课程商品状态修改
  mediaOperate: (params) => http.put(`/v1/merculetManagement/ecshop/products/media/operation/${params.operateType}`, params),
  // 编辑 课程商品
  mediaEdit: (params) => http.put(`/v1/merculetManagement/ecshop/products/media/${params.id}`, params),
  // 课程商品 详情
  mediaDetails: (params) => http.get(`/v1/merculetManagement/ecshop/products/media/${params.id}`),
  // 新增课程商品
  mediaAdd: (params) => http.post(`/v1/merculetManagement/ecshop/products/media`, params),

  // 商品奖励配置列表(支持搜索)
  rewardList: (params) => http.post(`/v1/merculetManagement/ecshop/reward/config/page`, params),
  // 商品列表接口支持商品名称搜索
  productList: (params) => http.post(`/v1/merculetManagement/ecshop/products/reward/list`, params),
  // 保存和编辑商品奖励配置接口
  rewardConfigSave: (params) => http.post(`/v1/merculetManagement/ecshop/reward/config/create`, params),
  // 保存和编辑商品奖励配置接口
  rewardConfigUpdate: (params) => http.post(`/v1/merculetManagement/ecshop/reward/config/update`, params),
  // 根据单个商品奖励配置查询接口
  rewardConfigSearch: (params) => http.get(`/v1/merculetManagement/ecshop/reward/config/find?id=${params.id}`),
  // 失效接口
  rewardInvalid: (params) => http.post(`/v1/merculetManagement/ecshop/reward/config/cancel`, params),
  // 删除接口
  rewardDelete: (params) => http.post(`/v1/merculetManagement/ecshop/reward/config/del`, params),
  // 商品规格
  getProductAttributes: (params) => http.get(`/v1/merculetManagement/ecshop/productAttributes`, params),
  // 添加规格
  postProductAttributes: (params) => http.post(`/v1/merculetManagement/ecshop/productAttributes`, params),
  // 删除规格
  deleteProductAttributes: (params) => http.delete(`/v1/merculetManagement/ecshop/productAttributes${params.id}`, params),
  // 获取单个规格
  getProductAttributesInfo: (params) => http.get(`/v1/merculetManagement/ecshop/productAttributes${params.id}`, params),
  // 根据规格id获取规格值
  getPoductAttributeOptions: (params) => http.get(`/v1/merculetManagement/ecshop/productAttributes/${params.attribute_id}/productAttributeOptions`, params),
  // 添加规格值
  postPoductAttributeOptions: (params) => http.post(`/v1/merculetManagement/ecshop/productAttributes/${params.attribute_id}/productAttributeOptions`, params),
  // 根据exchange flag查询 product roles
  getProductRoles: (params) => http.get(`/v1/merculetManagement/ecshop/products/getProductRoles`, params),

  // 新增分类
  saveCategory: (params) => http.post(`/v1/merculetManagement/ecshop/product/category/save`, params),
  // 获取分类列表
  getCategoryList: (params) => http.get(`/v1/merculetManagement/ecshop/product/category/list`, params),
  // 不区分类型获取分类列表
  getCategoryListAll: (params) => http.get(`/v1/merculetManagement/ecshop/product/category/list/all`, params),
  // 删除分类
  deleteCategory: (params) => http.get(`/v1/merculetManagement/ecshop/product/category/delete/${params}`),
  // 批量设置商品分类
  updateGoodsCategory: (params) => http.post(`/v1/merculetManagement/ecshop/products2/updateCategories`, params),

  // 新增优惠券
  addCoupon: (params) => http.post(`/v1/merculetManagement/ecshop/coupon/save`, params),
  // 编辑优惠券
  editCoupon: (params) => http.post(`/v1/merculetManagement/ecshop/coupon/update`, params),
  // 获取优惠券列表
  getCouponList: (params) => http.get(`/v1/merculetManagement/ecshop/coupon/list/page`, params),
  // 获取优惠券详情
  couponDetail: (params) => http.get(`/v1/merculetManagement/ecshop/coupon/${params}/summary`),
  // 更新优惠券的状态
  updateCouponStatus: (params) => http.get(`/v1/merculetManagement/ecshop/coupon/update/status`, params),
  // 更新优惠券的状态
  addCouponQuantity: (params) => http.get(`/v1/merculetManagement/ecshop/coupon/apply/quantity`, params),
  // 获取优惠券可用数量
  balanceQuantity: (params) => http.get(`/v1/merculetManagement/ecshop/coupon/quantity`, params),
  // 获取兑换码
  createExchange: (params) => http.post(`/v1/merculetManagement/ecshop/coupon/exchange`, params),
  // 获取兑换码列表
  exchangeList: (params) => http.get(`/v1/merculetManagement/ecshop/coupon/exchange/list`, params),
  // 兑换码详情列表
  exchangeDetailList: (params) => http.get(`/v1/merculetManagement/ecshop/coupon/batch/list`, params),
  // 导出某一批次的兑换码详情列表
  exportExchangeList: (params) => http.exportGet(`/v1/merculetManagement/ecshop/coupon/batch/export`, params),

  // 商品关联优惠券
  relationCoupon: (params) => http.post(`/v1/merculetManagement/ecshop/product/coupon/relation`, params),
  // 查看某个商品的兑换码详情
  goodsCouponSummary: (params) => http.get(`/v1/merculetManagement/ecshop/product/coupon/summary`, params),
  // 查看某个商品的兑换码详情列表
  goodsCouponDetailList: (params) => http.get(`/v1/merculetManagement/ecshop/product/coupon/code/list`, params),
  // 提交上传的兑换码
  uploadCoupon: (params) => http.get(`/v1/merculetManagement/ecshop/product/coupon/submit`, params),

  // 新增购物返红包
  saveRebatePurchase: (params) => http.post(`/v1/merculetManagement/ecshop/redpackage/save/purchase`, params),
  // 获取购物返红包列表
  getRebateListPurchase: (params) => http.get(`/v1/merculetManagement/ecshop/redpackage/list/purchase`, params),
  // 获取购物返红包详情
  rebateDetailPurchase: (params) => http.get(`/v1/merculetManagement/ecshop/redpackage/${params}/purchase/summary`),

  saveRebateDirect: (params) => http.post(`/v1/merculetManagement/ecshop/redpackage/save/direct`, params),
  // 获取购物返红包列表
  getRebateListDirect: (params) => http.get(`/v1/merculetManagement/ecshop/redpackage/list/direct`, params),
  // 获取购物返红包详情
  rebateDetailDirect: (params) => http.get(`/v1/merculetManagement/ecshop/redpackage/${params}/direct/summary`),

  redpackInfo: (key) => http.get(`/v1/merculetManagement/ecshop/redpackage/query/${key}`),
  // 查看购物返红包的可用或者不可用商品
  rebateProductsDetail: (params) => http.get(`/v1/merculetManagement/ecshop/redpackage/${params}/products`),
  // 查看购物返红包的优惠券列表
  rebateCouponDetail: (params) => http.get(`/v1/merculetManagement/ecshop/redpackage/${params}/coupons`),
  // 停用购物返红包
  disableRebateCoupon: (params) => http.get(`/v1/merculetManagement/ecshop/redpackage/${params.id}/disable`, params),

  // 新增新人优惠券礼包
  saveUserGift: (params) => http.post(`/v1/merculetManagement/ecshop/coupon/user/packet/save`, params),
  // 获取新人优惠券礼包详情
  userGiftDetail: () => http.get(`/v1/merculetManagement/ecshop/coupon/user/packet/summary`),
  // 根据优惠券id获取商品列表
  getProductsListByCouponID: (params) => http.get(`/v1/merculetManagement/ecshop/coupon/user/packet/products`, params),
  // 启用或者停用新人优惠券礼包
  updateUserGiftStatus: (params) => http.get(`/v1/merculetManagement/ecshop/coupon/user/packet/updateStatus`, params),
  /**拼团相关接口 */
  // 新增拼团
  saveActivityBulk: (params) => http.post(`/v1/merculetManagement/ecshop/activitys/bulk/save`, params),
  // 新增拼团
  updateActivityBulk: (params) => http.post(`/v1/merculetManagement/ecshop/activitys/bulk/update/${params.id}`, params),
  // 获取拼团列表
  getActivityBulkList: (params) => http.get(`/v1/merculetManagement/ecshop/activitys/bulk/page`, params),
  // 获取拼团列表 供可视化用
  getPinListVisual: (params) => http.get(`/v1/merculetManagement/ecshop/activitys/bulk/listForHomePageConfig`, params),
  // 根据拼团id获取详情
  getActivityBulkDetailByID: (params) => http.get(`/v1/merculetManagement/ecshop/activitys/bulk/get/${params}`),
  // 使拼团失效
  closeActivityBulkByID: (params) => http.post(`/v1/merculetManagement/ecshop/activitys/bulk/close/${params.id}`, params),
  // 根据拼团id获取成团订单信息
  getActivityBulkOrderList: (params) => http.get(`/v1/merculetManagement/ecshop/activitys/bulk/record/list`, params),

  /** 助力相关接口 */
  // 新增助力
  saveActivityFriendAssist: (params) => http.post(`/v1/merculetManagement/ecshop/activitys/friendAssist/save`, params),
  // 更新助力
  updateActivityFriendAssis: (params) => http.post(`/v1/merculetManagement/ecshop/activitys/friendAssist/update/${params.id}`, params),
  // 获取助力列表
  getActivityFriendAssistList: (params) => http.get(`/v1/merculetManagement/ecshop/activitys/friendAssist/page`, params),
  // 根据助力id获取详情
  getActivityFriendAssistDetailByID: (params) => http.get(`/v1/merculetManagement/ecshop/activitys/friendAssist/get/${params}`),
  // 使助力失效
  closeActivityFriendAssistByID: (params) => http.post(`/v1/merculetManagement/ecshop/activitys/friendAssist/close/${params.id}`, params),
  // 根据助力id获取成团订单信息
  getActivityFriendAssistOrderList: (params) => http.get(`/v1/merculetManagement/ecshop/activitys/friendAssist/record/list`, params),
  // 根据助力id获取活动统计信息
  getActivityFriendAssisStats: (params) => http.get(`/v1/merculetManagement/ecshop/activitys/friendAssist/stats`, params),

  // 获取视频音频播放地址
  getPlayUrl: (params) => http.get(`/v1/merculetManagement/ecshop/products/${params}/playUrl`),
  // 获取视频音频播放地址
  PlayUrl: (params) => http.get(`/v1/merculetManagement/ecshop/originalProducts/${params}/playUrl`),
  // 视频审核失败原因
  classGoodsReviewFailedList: (params) => http.get('/v1/merculetManagement/vod/getVideoAuditResult', params),
  // 视频审核失败原因
  redpackageListForArticle: (params) => http.post('/v1/merculetManagement/ecshop/redpackage/listForArticle', params),
  // 获取商品赋能开放商品列表
  originalProducts: (params) => http.get('/v1/merculetManagement/ecshop/originalProducts/pageForUnite', params),
  // 获取商品被赋能列表
  uniteBatchProductsPageForUnited: (params) => http.get('/v1/merculetManagement/ecshop/uniteBatchProducts/pageForUnited', params),

  // 获取礼品卡商品被赋能列表
  uniteBatchGiftCardPageForUnited: (params) => http.get('/v1/merculetManagement/ecshop/uniteBatchGiftCards/pageForUnited', params),
  // 保存一个商品赋能
  uniteBatchParoductsSave: (params, body) =>
    http.post(
      `/v1/merculetManagement/ecshop/uniteBatchProducts/save?target_account_id=${params.target_account_id}&original_product_id=${params.original_product_id}&original_batch_product_id=${params.original_batch_product_id}`,
      body,
    ),
  // 保存一个礼品卡商品赋能
  uniteBatchGiftCardSave: (params, body) =>
    http.post(
      `/v1/merculetManagement/ecshop/uniteBatchGiftCards/save?target_account_id=${params.target_account_id}&original_product_id=${params.original_product_id}&original_batch_product_id=${params.original_batch_product_id}`,
      body,
    ),
  // 星球商品赋能列表 /v1/merculetManagement/ecshop/uniteBatchProducts/page
  uniteBatchProducts: (params) => http.get('/v1/merculetManagement/ecshop/uniteBatchProducts/page', params),
  // 礼品卡商品赋能列表
  uniteBatchGiftCards: (params) => http.get('/v1/merculetManagement/ecshop/uniteBatchGiftCards/page', params),
  // 在赋能商品列表里移除一个批次 /v1/merculetManagement/ecshop/uniteBatchProducts/removeBatch
  removeBatch: (params) => http.post(`/v1/merculetManagement/ecshop/uniteBatchProducts/removeBatch?target_account_id=${params.target_account_id}&original_batch_product_id=${params.original_batch_product_id}`),
  // 在赋能商品列表里删除一个商品 /v1/merculetManagement/ecshop/uniteBatchProducts/removeProduct
  removeProduct: (params) => http.post(`/v1/merculetManagement/ecshop/uniteBatchProducts/removeProduct?original_product_id=${params.original_product_id}&target_account_id=${params.target_account_id}`),
  // 获取该赋能商品下的批次列表 /v1/merculetManagement/ecshop/originalProducts/get
  originalProductBatchList: (params) => http.get(`/v1/merculetManagement/ecshop/originalProducts/get?id=${params.id}&is_unite=${params.is_unite}`),

  originalGiftCardBatchList: (params) => http.get(`/v1/merculetManagement/ecshop/giftCard/get?id=${params.id}&is_unite=${params.is_unite}`),

  // 保存设置兑换码引导
  saveUseGuide: (params) => http.post(`/v1/merculetManagement/ecshop/product/coupon/saveUseGuide`, params),
  // 获取保存的设置兑换码引导
  getUseGuide: (params) => http.get(`/v1/merculetManagement/ecshop/product/coupon/getUseGuide`, params),

  // --------小程序商品审核相关接口------
  // 审核商品列表
  productsListForAudits: (params) => http.get(`/v1/merculetManagement/ecshop/productForMiniAppAudits`, params),
  // 添加审核商品
  addProductsForAudits: (params) => http.post(`/v1/merculetManagement/ecshop/productForMiniAppAudits/add`, params),

  // 删除审核商品
  deleteProductsForAudits: (params) => http.post(`/v1/merculetManagement/ecshop/productForMiniAppAudits/delete`, params),
  // --------------------------------

  requestReceivedCounponList: (params) => http.post(`/v1/merculetManagement/ecshop/coupon/received/page`, params),

  // 删除礼品卡商品
  removeGiftCardProduct: (params) => http.post(`/v1/merculetManagement/ecshop/uniteBatchGiftCards/removeProduct?original_product_id=${params.original_product_id}&target_account_id=${params.target_account_id}`),

  // 删除礼品卡商品的批次
  removeGiftCardBatch: (params) => http.post(`/v1/merculetManagement/ecshop/uniteBatchGiftCards/removeBatch?target_account_id=${params.target_account_id}&original_batch_product_id=${params.original_batch_product_id}`),

  //为了角色权限管理新增的接口
  //自营上架中商品列表
  selfOnshelf: (params) => http.get(`/v1/merculetManagement/ecshop/products2/page/self/onshelf`, params),

  //自营已售完商品列表
  selfSoldout: (params) => http.get(`/v1/merculetManagement/ecshop/products2/page/self/soldout`, params),

  //自营已下架
  selfOffshelf: (params) => http.get(`/v1/merculetManagement/ecshop/products2/page/self/offshelf`, params),

  //被赋能商品上架中商品列表
  uniteOnshelf: (params) => http.get(`/v1/merculetManagement/ecshop/products2/page/unite/onshelf`, params),

  //被赋能商品已售完商品列表
  uniteSoldout: (params) => http.get(`/v1/merculetManagement/ecshop/products2/page/unite/soldout`, params),

  //被赋能商品已下架
  uniteOffshelf: (params) => http.get(`/v1/merculetManagement/ecshop/products2/page/unite/offshelf`, params),

  //为了角色权限管理新增的接口
  //自营上架中商品列表
  selfOnshelfMedia: (params) => http.get(`/v1/merculetManagement/ecshop/products2/media/page/self/onshelf`, params),

  //自营已售完商品列表
  selfSoldoutMedia: (params) => http.get(`/v1/merculetManagement/ecshop/products2/media/page/self/soldout`, params),

  //自营已下架
  selfOffshelfMedia: (params) => http.get(`/v1/merculetManagement/ecshop/products2/media/page/self/offshelf`, params),

  //被赋能商品上架中商品列表
  uniteOnshelfMedia: (params) => http.get(`/v1/merculetManagement/ecshop/products2/media/page/unite/onshelf`, params),

  //被赋能商品已售完商品列表
  uniteSoldoutMedia: (params) => http.get(`/v1/merculetManagement/ecshop/products2/media/page/unite/soldout`, params),

  //被赋能商品已下架
  uniteOffshelfMedia: (params) => http.get(`/v1/merculetManagement/ecshop/products2/media/page/unite/offshelf`, params),

  //同步赋能进行时
  isUniteMediaLocked: (id) => http.get(`/v1/merculetManagement/ecshop/originalProducts/${id}/isUniteMediaLocked`),
  // 赠予课程
  createDonateOrder: (params) => http.post(`/v1/merculetManagement/ecshop/orders/createDonateOrder`, params),
  // 创建课程的目录
  saveDirectory: (params) => http.get(`/v1/merculetManagement/ecshop/originalProducts/saveDirectory`, params),
  // 导出优惠券 /v1/merculetManagement/ecshop/coupon/received/export
  exportCoupon: (params) => http.exportGet(`/v1/merculetManagement/ecshop/coupon/received/export`, params),

};
