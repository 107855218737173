export default {
  path: 'vip',
  name: '会员管理',
  component: () => import('@/components/main-children'),
  children: [
    {
      path: 'orders',
      name: '会员订单',
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import('../views/vip/orders'),
    },
    {
      path: 'records',
      name: '会员消费记录',
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import('../views/vip/records'),
    },
    {
      path: 'vip-list',
      name: '会员列表',
      component: () => import('../views/vip/vip-list'),
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
    },
    {
      path: 'setting',
      name: '会员卡设置',
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import('../views/vip/setting'),
    },
    {
      path: "vip-card-config",
      name: "会员卡配置",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import('../views/vip/vip-card-config')
    }
  ]
}