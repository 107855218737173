export default {
  path: "friendAssistMall",
  name: "助力",
  component: () => import("@/components/main-children"),
  children: [
    {
      path: "list",
      meta: {
        name: "好友助力",
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("@/views/activityCenter/friendAssistMall/index")
    },

    {
      path: "detail",
      meta: {
        name: "助力详情",
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("@/views/activityCenter/friendAssistMall/detail")
    }
  ]
};
