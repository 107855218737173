const MainChildren = () => import("@/components/main-children.vue");
//购物返红包列表
const rebateList = () => import("../views/activityCenter/rebateCoupon/index");

//购物返红包详情
const rebateDetail = () =>
  import("../views/activityCenter/rebateCoupon/rebate-detail");

export default {
  path: "rebateCoupon",
  name: "购物返红包",
  component: MainChildren,
  children: [
    {
      path: "list",
      meta: {
        name: "购物红包",
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: rebateList
    },

    {
      path: "detail",
      meta: {
        name: "购物返红包详情",
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: rebateDetail
    }
  ]
};
