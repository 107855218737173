/*
 * @Author: sky.li
 * @LastEditors: Please set LastEditors
 * @Description:
 * @可以输入预定的版权声明、个性签名、空行等
 */
const MainChildren = (resolve) => {
  require.ensure([], () => {
    resolve(require("@/components/main-children.vue"));
  });
};

//子账户列表
const accountList = (resolve) => {
  require.ensure([], () => {
    resolve(require("../views/systemSetup/accountManagement/index"));
  });
};

//角色权限管理
const rolePermissionsManagement = (resolve) => {
  require.ensure([], () => {
    resolve(require("../views/systemSetup/rolePermissionsManagement/index"));
  });
};

//新增角色权限
const addRolePermissionsManagement = (resolve) => {
  require.ensure([], () => {
    resolve(require("../views/systemSetup/rolePermissionsManagement/add"));
  });
};

//编辑角色权限
// const editRolePermissionsManagement = (resolve) => {
//   require.ensure([], () => {
//     resolve(require("../views/systemSetup/rolePermissionsManagement/edit"));
//   });
// };

//子账户详情
const accountDetail = (resolve) => {
  require.ensure([], () => {
    resolve(require("../views/systemSetup/accountManagement/detail"));
  });
};

//新建子账户
const accountAdd = (resolve) => {
  require.ensure([], () => {
    resolve(require("../views/systemSetup/accountManagement/add"));
  });
};

export default {
  path: "system-setup",
  name: "systemSetup.name",
  component: MainChildren,
  children: [
    {
      path: "account-management",
      name: "systemSetup.accountManagement.name",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: accountList,
    },
    {
      path: "role-permissions-management",
      name: "角色权限管理",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: rolePermissionsManagement,
    },
    {
      path: "role-permissions-management/add",
      name: "新增角色权限",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: addRolePermissionsManagement,
    },
    {
      path: "role-permissions-management/edit",
      name: "编辑角色权限",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: addRolePermissionsManagement,
    },
    {
      path: "supply",
      name: "供应商设置",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/supply/list.vue"),
    },
    {
      path: "live-management",
      name: "直播管理",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/live-management/index.vue"),
    },
    {
      path: "live-management/list",
      name: "直播列表",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/live-management/list.vue"),
    },
    {
      path: "live-management/live-detail",
      name: "直播详情",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/live-management/live-detail.vue"),
    },
    {
      path: "account-management-add",
      name: "systemSetup.accountManagement.add",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: accountAdd,
    },
    {
      path: "account-management-detail",
      name: "systemSetup.accountManagement.detail",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: accountDetail,
    },
    {
      path: "setting",
      name: "设置",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("../views/systemSetup/setting"),
    },
    {
      path: "qa-manage",
      name: "Q&A管理",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("../views/systemSetup/qa-manage/index"),
    },
  ],
};
