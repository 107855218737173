// 商城管理

const MainChildren = () => import("@/components/main-children.vue");

//群商城商品列表
const goodsList = () => import("../views/group/mall/goods-list.vue");

//购物奖励列表
const awardsList = () => import("../views/group/mall/buy-awards.vue");

//群商城订单列表
const orderList = () => import("../views/group/mall/order-list.vue");

// 运费模板
const freightTemplate = () =>
  import("../views/group/mall/freight-template-list.vue");

// 客服设置
const customService = () => import("../views/group/mall/custom-service");

//发货地址
const fromAddressConfig = () =>
  import("../views/group/mall/from-address-config");

const storesManage = () => import("../views/group/mall/stores-manage");

export default {
  path: "mall",

  meta: {
    login: true,
    Authentication: true,
    name: "商城管理"
  },
  component: MainChildren,
  children: [
    {
      path: "goods",
      name: "menu.mall_manage.goods_manage",
      meta: {
        login: true,
        Authentication: true
      },
      component: goodsList
    },
    {
      path: "goods-market",
      name: "上下架管理",
      meta: {
        login: true,
        Authentication: true
      },
      component: () => import("../views/group/mall/goodsView.vue")
    },
    {
      path: "goods-audits",
      name: "小程序商品审核",
      meta: {
        login: true,
        Authentication: true
      },
      component: () => import("../views/group/mall/goods-audits.vue")
    },
    {
      path: "class-administration",
      name: "课程管理",
      meta: {
        login: true,
        Authentication: true
      },
      component: () => import("@/views/group/mall/class-administration")
    },
    {
      path: "class-goods",
      name: "课程上架",
      meta: {
        login: true,
        Authentication: true
      },
      component: () => import("@/views/group/mall/class-goods")
    },

    {
      path: "category",
      name: "menu.mall_manage.category_manage",
      meta: {
        login: true,
        Authentication: true
      },
      component: () => import("@/views/group/mall/goods-category/index")
    },

    {
      path: "awards",
      name: "menu.mall_manage.awards_manage",
      meta: {
        login: true,
        Authentication: true
      },
      component: awardsList
    },
    {
      path: "order",
      name: "menu.mall_manage.order_manage",
      meta: {
        login: true,
        Authentication: true
      },
      component: orderList
    },
    {
      path: "refund",
      name: "售后订单",
      meta: {
        login: true,
        Authentication: true
      },
      component: () => import("@/views/group/mall/refund/refund-list.vue")
    },
    {
      path: "class-order",
      name: "课程订单列表",
      meta: {
        login: true,
        Authentication: true
      },
      component: () => import("@/views/group/mall/class-order-list.vue")
    },
    {
      path: "scenic",
      name: "景区列表",
      meta: {
        login: true,
        Authentication: true
      },
      component: () => import("@/views/scenic/scenicList.vue")
    },
    {
      path: "scenic/ticket-list",
      name: "门票订单",
      meta: {
        login: true,
        Authentication: true
      },
      component: () => import("@/views/scenic/ticket-list.vue")
    },
    {
      path: "scenic/ticket-detail",
      name: "订单详情",
      meta: {
        login: true,
        Authentication: true
      },
      component: () => import("@/views/scenic/order-info.vue")
    },

    {
      path: "freight-template",
      name: "menu.mall_manage.freight_template",
      meta: {
        login: true,
        Authentication: true
      },
      component: freightTemplate
    },
    {
      path: "customer_service",
      name: "menu.mall_manage.customer_service_setting",
      meta: {
        login: true,
        Authentication: true
      },
      component: customService
    },
    {
      path: "shipping_setting",
      name: "menu.mall_manage.shipping_setting",
      meta: {
        login: true,
        Authentication: true
      },
      component: fromAddressConfig
    },
    {
      path: "stores-manage",
      meta: {
        name: "门店管理",
        login: true,
        Authentication: true
      },
      component: storesManage
    },
    {
      path: "hot-word",
      meta: {
        name: "搜索热词管理",
        login: true,
        Authentication: true
      },
      component: () => import("@/views/group/mall/hotWord/index.vue")
    },
    {
      path: "writeoff-record",
      meta: {
        name: "商品核销记录",
        login: true,
        Authentication: true
      },
      component: () => import("@/views/group/mall/writeoff-record/index.vue")
    },
    {
      path: "writeoff-record-new",
      meta: {
        name: "商品核销记录-app",
        login: true,
        Authentication: true
      },
      component: () => import("@/views/group/mall/writeoff-record-new/index.vue")
    },
    {
      path: "order-detail",
      meta: {
        name: "订单详情",
        login: true,
        Authentication: true
      },
      component: () => import("@/views/group/mall/writeoff-record/order-detail.vue")
    }
  ]
};
