export default function (input) {

  input = input + ''
  var ex = /^(-?\d+)(\.\d+)?$/
  if (!ex.test(input)) {
    return input
  }
  let ar, l, r, returnVal = ''

  function toN(num) {
    let n = Number(num).toFixed(4).toString();
    let size = n.length - 2;
    return n.substring(0, size);

  }

  if (Number(input) > 1000000 && Number(input) < 100000000) {
    // return (Number(input)/1000000).toFixed(2) + 'M';
    return toN(input / 1000000) + 'M';

  } else if (Number(input) >= 100000000) {
    // return (Number(input)/100000000).toFixed(2) + 'B'
    return toN(input / 100000000) + 'B'
  } else {
    if (input.indexOf('.') < 0) {
      l = input.split('')
      l.reverse()
      l.map(function (v, i) {
        var ind = l.length - (i + 1)
        returnVal += ind % 3 ? l[ind] : (i + 1) >= l.length ? l[ind] : l[ind] + ','
      })
    } else {
      ar = Number(input).toFixed(2).split('.')
      l = ar[0].split('')
      l.reverse()
      r = ar[1].split('')
      l.map(function (v, i) {
        var ind = l.length - (i + 1)
        returnVal += ind % 3 ? l[ind] : (i + 1) >= l.length ? l[ind] : l[ind] + ','
      })
      returnVal += '.'
      r.map(function (v, i) {
        returnVal += (i + 1) % 3 ? v : (i + 1) >= r.length ? v : v + ','
      })
    }
    return returnVal
  }
}


