// export default {
//   path: 'activity_sign',
//   name: 'activity_sign.name',
//   component: () => import('../views/activity-sign'),
// }

const MainChildren = (resolve) => {
  require.ensure([], () => {
    resolve(require("@/components/main-children.vue"));
  });
};

export default {
  path: "activity_sign",
  name: "activity_sign.name",
  component: MainChildren,
  children: [
    {
      path: "add",
      meta: {
        name: "新建报名",

        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("../views/activity-sign")
    },
    {
      path: "list",
      meta: {
        name: "活动报名",

        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("../views/activity-sign/activity-list")
    },
    {
      path: "detail",
      meta: {
        name: "活动详情",

        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("../views/activity-sign/activity-detail")
    },
    {
      path: "edit",
      meta: {
        name: "活动编辑",

        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("../views/activity-sign/edit")
    },
    {
      path: "user_sign_list",
      meta: {
        name: "用户报名情况",

        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("../views/activity-sign/user-sign-list")
    },
    {
      path: "distribution_list",
      name: "活动分销列表",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () =>
        import("../views/activityCenter/activityDistribution/index")
    },
    {
      path: "distribution_add",
      name: "活动分销设置",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () =>
        import("../views/activityCenter/activityDistribution/add")
    },
    {
      path: "distribution_detail",
      meta: {
        name: "活动分销详情",

        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () =>
        import("../views/activityCenter/activityDistribution/detail")
    },
    {
      path: "order",
      meta: {
        name: "报名活动订单",

        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("../views/activityCenter/activityOrder/index")
    }
  ]
};
