/**
 * @author tianyuan
 * @email
 * @date
 * @description 可视化相关接口
 */

import { base } from "@/config/index";
import http from "@/serving/http.serving";

export default {
  // 获取首页配置规则
  getHomePageConfig: () => http.httpGet(`${base}/home/page/config/get`),
  // 获取首页可用组件列表
  getHomePageConfigComponents: () =>
    http.httpGet(`${base}/home/page/config/components`),
  // 发布
  publishHomePageConfigComponents: () =>
    http.httpGet(`${base}/home/page/config/publish`),
  // 保存
  postHomePageConfig: (params) =>
    http.httpPost(`${base}/home/page/config/save`, params),
  // 获取星球信息
  getHomeConfig: (params) =>
    http.httpGet(`${base}/sys/accountHomeConfig/find`, params),
  // 设置水晶礼包封面

  setGiftConfig: (params) =>
    http.httpPost(`${base}/config/pocket/create`, params),
  // 获取
  getGiftConfig: (params) => http.httpGet(`${base}/config/pocket/get`, params),
  // 获取签到页配置
  getSigninPageConfig: (params) =>
    http.httpGet(`${base}/page/config/query`, params),
  // 保存签到页配置
  saveSigninPageConfig: (params) =>
    http.httpPost(`${base}/page/config/save`, params),
};
