/*
 * @Author: 覃宇辉 
 * @Date: 2018-06-01 10:54:28 
 * @Last Modified by: 覃宇辉
 * @Last Modified time: 2018-06-01 10:55:54
 */



export default function (input) {

  var d;
  try {
    d = new Date(input)
  } catch (err) {
    return ''
  }
  var year = d.getFullYear();
  var month = d.getMonth() + 1;
  if (month < 10) {
    month = '0' + month
  }
  var day = d.getDate() < 10 ? '0' + d.getDate() : '' + d.getDate();

  return year + '-' + month + '-' + day
}
