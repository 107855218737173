/* * @Author: 覃宇辉 */

<template>
  <div class="table-empty">
    <img src="../assets/image/empty.png" alt />
    <span>{{ $t("common.noData") }}</span>
  </div>
</template>

<script>
export default {
  name: "table-data-empty",
  data() {
    return {};
  },
};
</script>

<style scoped>
.table-empty {
  line-height: 60px;
  color: #999;
  text-align: center;
  margin: 0 auto;
  padding: 20px 0;
}
span {
  display: block;
  font-size: 12px;
}
.table-empty img {
  width: 140px;
  height: 88px;
  /* display: block; */
}
</style>
