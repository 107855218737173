let state = {
  config: {
    team: {},
    article: {},
    vip: {},
    bonus_pool: {},
    bulk_group: {},
    friend_assist: {},
  },
};

const getters = {
  roleConfig: (state, getters) => state.config,
  accountEmail: (state) => () => {
    let userInfo = sessionStorage.getItem("userInfo");
    if (userInfo) {
      userInfo = JSON.parse(userInfo);
      return userInfo.username
    }
    return ""
  },
  isMainAccount: (state) => () => {
    let userInfo = sessionStorage.getItem("userInfo");
    if (userInfo) {
      userInfo = JSON.parse(userInfo);
      console.log("userInfo =====", userInfo.type == 1)
      return userInfo.type == 1; // type == 1 主账号 其他 子账号
    }
    return false;
  },
  isMainAccountAndMoWa: (state) => () => {
    let userInfo = sessionStorage.getItem("userInfo");
    if (userInfo) {
      userInfo = JSON.parse(userInfo);
      return userInfo.type == 1 && userInfo.sys_version == "MOWA_STANDARD"; // type == 1 主账号 其他 子账号
    }
    return false;
  },
  isMoWaStandard: (state) => {
    let userInfo = sessionStorage.getItem("userInfo");
    if (userInfo) {
      userInfo = JSON.parse(userInfo);
      return userInfo.sys_version == "MOWA_STANDARD"; // type == 1 主账号 其他 子账号
    }
    return false;
  }
};

const actions = {
  setRoleConfig({commit}, data) {
    commit("setRoleConfig", data);
  },
};
const mutations = {
  setRoleConfig(state, data) {
    let config;
    try {
      config = JSON.parse(data);
      state.config = config;
    } catch (e) {
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
