/*
 * @Author: sky.li
 * @LastEditors: sky.li
 * @Description:商品分类
 * @可以输入预定的版权声明、个性签名、空行等
 */
import goodsCategoryApi from "@/api/goodsCategory";

/**
 * 将多纬数据，改为一纬数据的map
 * 同时删除sub_product_categories属性
 */
function getCategoryMap(arr = [], categoryMap = {}) {
  const arrCopy = JSON.parse(JSON.stringify(arr));
  arrCopy.forEach((item) => {
    if (item.sub_product_categories) {
      getCategoryMap(item.sub_product_categories, categoryMap);
    }
    delete item.sub_product_categories;
    categoryMap[item.id] = item;
  });
  return categoryMap;
}

let state = {
  list: {},
  obj: {},
  style: null, // * SINGLE: 一级分类 * MULTILEVEL: 二级分类
  loaded: false,
  loading: false,
};
export default {
  namespaced: true,

  state,

  mutations: {
    setGoodsCategory(state, { obj, list }) {
      state.list = list;
      state.obj = obj;
      state.loaded = true;
      state.loading = false;
    },
    setGoodsCategoryLoading(state) {
      state.loading = true;
    },
    resetGoodsCategory(state) {
      state.list = [];
      state.obj = {};
      state.loaded = false;
      state.loading = false;
    },
    setStyle(state, style) {
      state.style = style;
    },
  },
  actions: {
    /**
     * getGoodsCategory
     * @param {*} param stroe params
     * @param {Boolean} reLoad 是否重置缓存
     */
    async getGoodsCategory({ state, commit }, reLoad) {
      if (reLoad === true) {
        commit("resetGoodsCategory", reLoad);
      }

      const { loaded, loading } = state;

      if (!loaded && !loading) {
        commit("setGoodsCategoryLoading");
        const { data } = await goodsCategoryApi.getCategoryListAll();
        data.score.unshift({ id: 0, category_name: "全部星福利" });
        data.shop.unshift({ id: 0, category_name: "全部星球店" });
        commit("setGoodsCategory", {
          list: data,
          obj: getCategoryMap([...data.score, ...data.shop]),
        });
      } else {
        commit("setGoodsCategoryLoading");
      }
    },

    async getStyle({ commit, state }, reLoad) {
      if (!state.style || reLoad) {
        const { data } = await goodsCategoryApi.queryStyle();
        commit("setStyle", data);
      }
    },
  },
};
