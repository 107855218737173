/*
 * @Author: sky.li
 * @LastEditors: sky.li
 * @Description:
 * @可以输入预定的版权声明、个性签名、空行等
 */
/**
 * @author Tianyuan.li
 * @email Tianyuan.li@magicwindow.cn
 * @date 3/29/19 12:39 AM
 * @description
 */
const index = () => import("../views/tpl-preview/index");

const goods = () => import("../views/tpl-preview/goods");
const voice = () => import("../views/tpl-preview/voice");
const media = () => import("../views/tpl-preview/video");
const article = () => import("../views/tpl-preview/article");

export default {
  path: "/tpl-preview",
  component: index,
  children: [
    {
      path: "goods",
      name: "商品",
      component: goods
    },
    {
      path: "video",
      name: "视频",
      component: media
    },
    {
      path: "voice",
      name: "音频",
      component: voice
    },
    {
      path: "article",
      name: "文章",
      component: article
    },
    {
      path: "template",
      name: "模版",
      component: () => import("@/views/tpl-preview/template")
    },
    {
      path: "red-pack",
      name: "红包",
      component: () => import("@/views/tpl-preview/red-pack")
    },
    {
      path: "topic",
      name: "话题",
      component: () => import("@/views/tpl-preview/topic")
    },
    {
      path: "koc",
      name: "视频号",
      component: () => import("@/views/tpl-preview/koc")
    },
    {
      path: "vod",
      name: "视频",
      component: () => import("@/views/tpl-preview/vod")
    },
    {
      path: "recommend",
      name: "视频",
      component: () => import("@/views/tpl-preview/recommend")
    },
  ]
};
