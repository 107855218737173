import { base } from "@/config/index";
import http from "@/serving/http.serving";

// const getAppId = () => {
//   const leftMenu = storage.get("leftMenu");

//   return {
//     app_id: leftMenu.appId,
//   };
// };

export default {
  // 获取机器人配置信息
  getRobotConfig: (params) =>
    http.httpGet(`${base}/robot/getRobotConfig`, params),

  // 保存机器人配置信息
  updateRobotConfig: (params) =>
    http.httpPost(`${base}/robot/updateRobotConfig`, params),

  // 获取机器人配置信息
  bindRoomWithApp: (params) =>
    http.httpGet(`${base}/robot/bindRoomWithApp`, params),

  // 获取机器人配置信息
  unBindRoomWithApp: (params) =>
    http.httpGet(`${base}/robot/unBindRoomWithApp`, params),

  // 登录微信机器人
  wxRobotLogin: (params) => http.httpGet(`${base}/robot/addRobot`, params),

  // 获取最后登录的二维码
  getLastQrCodeUrl: (params) =>
    http.httpGet(`${base}/robot/getLastQrCode`, params),

  // 设为默认邀请群
  updateDefaultInviteRoom: (params) =>
    http.httpGet(`${base}/robot/updateDefaultInviteRoom`, params),

  // 获取机器人列表
  listRobots: () => http.httpGet(`${base}/robot/listRobots`),

  // 关闭微信机器人
  closeRobot: (params) => http.httpGet(`${base}/robot/closeRobot`, params),

  // 获取机器人token列表
  listRobotTokens: () => http.httpGet(`${base}/robot/listRobotTokens`),

  // 获取机器人token列表
  rechargeRobotToken: (params) =>
    http.httpGet(`${base}/robot/rechargeRobotToken`, params),

  // 新增机器人token
  addNewRobotToken: (params) =>
    http.httpGet(`${base}/robot/addRobotToken`, params),

  // 获取机器人会员通知配置
  getRobotVipMsgConfig: (params) =>
    http.httpGet(`${base}/robot/getRobotVipMsgConfig`, params),

  // 更新微信群会员通知配置状态
  updateRobotChatRoomVipMsg: (params) =>
    http.httpGet(`${base}/robot/updateRobotChatRoomVipMsg`, params),

  // 更新机器人会员通知配置
  updateRobotVipMsgConfig: (params) =>
    http.httpPost(`${base}/robot/updateRobotVipMsgConfig`, params),
  //
  // //获取机器人会员通知配置
  // getRobotVipMsgConfig: params => http.httpGet(`${base}/robot/getRobotVipMsgConfig`, params),

  // 微信群指定欢迎语
  updateChatRoomContent: (params) =>
    http.httpPost(`${base}/robot/updateChatRoomContent`, params),

  // 指定群介绍
  updateChatRoomDescription: (params) =>
    http.httpPost(`${base}/robot/updateChatRoomDescription`, params),

  // 用户提现列表
  listWithDraws: (params) =>
    http.httpPost(`${base}/withdraw/listWithDraws`, params),
  // 用户提现数量
  withDrawCount: (params) =>
    http.httpPost(`${base}/withdraw/getWithDrawCount`, params),
  // 用户提现申请通过
  approveWithdraw: (params) => http.httpGet(`${base}/withdraw/approve`, params),
  // 用户提现申请拒绝
  rejectWithdraw: (params) => http.httpGet(`${base}/withdraw/reject`, params),
  // 获取星球列表
  listStars: (params) => http.httpGet(`${base}/withdraw/listStars`, params),

  // 获取微信群列表
  getChatRoomList: () => http.httpGet(`${base}/activity/listChatRooms`),
  // 新增微信入群设置
  createChatRoom: (params) =>
    http.httpPost(`${base}/activity/createChatRoom`, params),
  // 设置为默认群
  setDefaultChatRoom: (params) =>
    http.httpGet(`${base}/activity/enableChatRoom`, params),
  // 删除微信群
  deleteChatRoom: (params) =>
    http.httpGet(`${base}/activity/deleteChatRoom`, params),

  // 获取公众号授权列表
  getWxMpSyncList: () => http.httpGet(`${base}/article/listSyncConfigs`),
  // 新增公众号授权
  createWxMpSync: (params) =>
    http.httpPost(`${base}/article/createSyncConfig`, params),
  // 设置公众号授权上线
  enableWxMpSync: (params) =>
    http.httpGet(`${base}/article/enableSyncConfig`, params),
  // 设置公众号授权上线
  disableWxMpSync: (params) =>
    http.httpGet(`${base}/article/disableSyncConfig`, params),
  // 获取同步历史文章列表
  getWxMpHistoryList: () => http.httpGet(`${base}/article/listTasks`),
  // 新增同步历史文章信息
  createWxMpHistory: (params) =>
    http.httpPost(`${base}/article/createTask`, params),

  // 获取角色管理列表
  roleManageList: () => http.httpGet(`${base}/role/list`),

  roleManageDetail: (params) =>
    http.httpGet(`${base}/uiPath/listByRole`, params),
  saveManage: (roleId, ids) =>
    http.httpPost(`${base}/uiPath/saveByRole?role_id=${roleId}`, ids),
};
