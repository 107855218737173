/*
 * @Author: 覃宇辉
 * @Date: 2018-05-16 15:33:44
 * @Last Modified by: 覃宇辉

 <money :hasSpan="true" :className="'aaa'" :num="3333333"></money>
 <money :num="33"></money>
 */


<template>

    <div v-if="hasSpan" class="money">{{dataObj.num}}
        <span class="unit">{{dataObj.text}}</span>
    </div>
    <span v-else  :class="className">{{dataObj.num}}{{dataObj.text}}</span>

</template>

<script>
export default {
    name: "money",
    props: {
        num:{
            // type:Number,
            default:0
        },
        className:{
            type:String,
            default:''
        },
        hasSpan: {
            type: Boolean,
            default:false
        },
    },
    data() {
        return{}
    },
    computed: {
        dataObj: function() {
            let data=this.num;
            let text="";
            if(this.isValueNumber(data)){
                if(data<10000){
                    text='';
                    // data=Math.floor(data*100)/100;
                    data=this.formatDecimal(data,2);
                }else if(data>=10000 && data<100000000){
                    text=this.$t('common.thousand');
                    // data=Math.floor((data/10000)*100)/100;
                    data=this.formatDecimal(data/10000,2);
                }else{
                    text=this.$t('common.millions');
                    // data=Math.floor((data/100000000)*100)/100;
                    data=this.formatDecimal(data/100000000,2);
                }
            }
            return {
                num:data,
                text:text
            }
        }
    },

    methods: {
        isValueNumber(value){
            if(value === null){
                console.warn('不可为null');
                return false
            }else if(value === ''){
                console.warn('不可为空');
                return false
            }else{
                let num = Number(value);
                if(isNaN(num)){
                    console.warn('类型不对');
                    return false
                }else{
                    return true;
                }
            }
        },
        formatDecimal(num, decimal) {
          num = num.toString()
          let index = num.indexOf('.')
          if (index !== -1) {
            num = num.substring(0, decimal + index + 1)
          } else {
            num = num.substring(0)
          }

          var str = parseFloat(num).toFixed(decimal).toString();
          var intSum = str.substring(0,str.indexOf(".")).replace( /\B(?=(?:\d{3})+$)/g, ',' );//取到整数部分
          var dot = str.substring(str.length,str.indexOf("."))//取到小数部分搜索
          return intSum + dot;
        }
    }
};
</script>

<style scoped>

</style>
