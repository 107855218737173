export default {
  namespaced: true,

  state: {
    config:  null,
    mode: "ONLINE"
  },

  mutations: {
    setConfig(state, payload) {
      state.config = payload.config
    },

    removeConfig(state) {
      state.config = null
    },

    setChargeMode(state, mode) {
      state.mode = mode
    }
  }
}