/*
    市场
 * @Author: 覃宇辉
 * @Date: 2018-09-21 15:00:53
 * @Last Modified by:   覃宇辉
 */

const marketManage = () => import("../views/spt/market/market-manage.vue");

const myMarket = () => import("../views/spt/market/my-market.vue");

const allMarket = () => import("../views/spt/market/all-market.vue");

const Games = () => import("../views/spt/market/games.vue");

const Goods = () => import("../views/spt/market/goods.vue");

const Member = () => import("../views/spt/market/member.vue");

const Assembly = () => import("../views/spt/market/assembly.vue");

const Plugs = () => import("../views/spt/market/plugs.vue");

const gameDetails = () => import("../views/spt/market/game-details.vue");

const goodsDetails = () => import("../views/spt/market/goods-details.vue");
const invitePoster = () => import("../views/spt/market/invite-poster.vue");
const articlePosterList = () =>
  import("../views/spt/market/article-poster-list.vue");
const addArticlePoster = () =>
  import("../views/spt/market/add-article-poster.vue");
const activityPosterList = () =>
  import("../views/spt/market/activity-poster-list.vue");
const addActivityPoster = () =>
  import("../views/spt/market/add-activity-poster.vue");
// 商品海报列表
const productPosterList = () =>
  import("../views/spt/market/product-poster-list.vue");
// 配置商品海报
const addProductPoster = () =>
  import("../views/spt/market/add-product-poster.vue");
// 自定义配置分享图标和文字
const shareCustom = () => import("@/views/spt/market/share-custom.vue");
export default [
  {
    path: "market/market-manage",
    name: "menu.spt.marketManage",
    meta: {
      login: true,
      Authentication: true,
    },
    component: marketManage,
  },
  {
    path: "market/my-market",
    name: "myMarket",
    meta: {
      login: true,
      Authentication: true,
    },
    component: myMarket,
  },
  {
    path: "market/all-market",
    name: "allMarket",
    meta: {
      login: true,
      Authentication: true,
    },
    component: allMarket,
    children: [
      {
        path: "games",
        name: "market.game.name",
        meta: {
          login: true,
          Authentication: true,
        },
        component: Games,
      },
      {
        path: "goods",
        name: "market.goods.name",
        meta: {
          login: true,
          Authentication: true,
        },
        component: Goods,
      },
      {
        path: "member",
        name: "market.member.name",
        meta: {
          login: true,
          Authentication: true,
        },
        component: Member,
      },
      {
        path: "assembly",
        name: "market.assembly.name",
        meta: {
          login: true,
          Authentication: true,
        },
        component: Assembly,
      },
      {
        path: "plugs",
        name: "market.plugs.name",
        meta: {
          login: true,
          Authentication: true,
        },
        component: Plugs,
      },
    ],
  },
  {
    path: "market/game-details",
    name: "market.gameDetails.name",
    meta: {
      login: true,
      Authentication: true,
    },
    component: gameDetails,
  },
  {
    path: "market/goods-details",
    name: "market.goodsDetails.name",
    meta: {
      login: true,
      Authentication: true,
    },
    component: goodsDetails,
  },
  {
    path: "invite-poster",
    name: "market.invitePoster.name",
    meta: {
      login: true,
      Authentication: true,
    },
    component: invitePoster,
  },
  {
    path: "article-poster",
    name: "文章海报管理",
    meta: {
      login: true,
      Authentication: true,
    },
    component: articlePosterList,
  },
  {
    path: "add-article-poster/:id",
    name: "新增文章海报",
    meta: {
      login: true,
      Authentication: true,
    },
    component: addArticlePoster,
  },
  {
    path: "activity-poster",
    meta: {
      name: "活动海报管理",

      login: true,
      Authentication: true,
    },
    component: activityPosterList,
  },
  {
    path: "add-activity-poster/:id",
    meta: {
      name: "新增活动海报",

      login: true,
      Authentication: true,
    },
    component: addActivityPoster,
  },
  {
    path: "product-poster",
    meta: {
      name: "商品海报管理",

      login: true,
      Authentication: true,
    },
    component: productPosterList,
  },
  {
    path: "add-product-poster/:id",
    meta: {
      name: "配置商品海报",

      login: true,
      Authentication: true,
    },
    component: addProductPoster,
  },
  {
    path: "/main/planet/share-custom",
    meta: {
      name: "自定义分享文案图标",
      login: true,
      Authentication: true,
    },
    component: shareCustom,
  },
  {
    path: "/main/planet/setting",
    name: "设置",
    meta: {
      login: true,
      Authentication: true,
      noVerifyToken: false,
    },
    component: () => import("../views/systemSetup/setting"),
  },
];
