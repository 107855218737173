import logger from "@/serving/logger";

export default (router) => {
  router.afterEach((to, from) => {
    logger.routeGo({
      to: to.path,
      from: from.path,
      toName: to.name,
      fromName: from.name
    });
  });
};
