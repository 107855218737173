/**
 * author 文全
 */

import Vue from "vue";
import http from "./http.serving";
import storage from "./storage.serving";
import goBack from "./goback";

// import api from '@/api/'
import status from "./status";
// import topicsapi from '@/api/topicsapi'
import chatRoBotApi from "@/api/chat-ro-bot";

let Utils = {
  http,
  storage,
  //   api,
  status,
  //   topicsapi,
  chatRoBotApi,
  goBack
};

Vue.use({
  install(Vue) {
    Object.keys(Utils).forEach(item => {
      Vue.prototype[`$${item}`] = Utils[item];
    });
  }
});
