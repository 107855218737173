export default {
  path: "operating-assistant/team-up",
  name: "operating-assistant.team-up.edit",
  component: () => import("@/components/main-children"),
  children: [
    {
      path: "edit",
      meta: {
        name: "直接组队",
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("@/views/team-up/edit")
    },
    {
      path: "details",
      meta: {
        name: "直接组队详情",
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("@/views/team-up/details")
    },
    {
      path: "browsing-history",
      meta: {
        name: "页面浏览历史",
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("@/views/team-up/browsing-history")
    }
  ]
};
