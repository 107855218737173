export default {
  namespaced: true,

  state: {
    pageHeaderSwipers: [],
    screenDialogs: []
  },

  mutations: {
    // syncPageHderSwipers
    // 当进入该页面时如果服务器有数据则同步服务器的数据
    syncPageHderSwipers(state, payload) {
      state.pageHeaderSwipers = payload;
    },

    // 设置header的swiper的item
    setPageHeaderSwiper(state, payload) {
      const { index, value } = payload;
      let data = state.pageHeaderSwipers.slice();
      if (state.pageHeaderSwipers.length) {
        data[index] = {
          ...data[index],
          ...value,
        };
        state.pageHeaderSwipers = data;
      } else {
        state.pageHeaderSwipers = [value];
      }
    },

    // 删除一个swiper的item
    removePageHeaderSwiper(state, payload) {
      const { index } = payload;
      let data = state.pageHeaderSwipers.slice();
      data.splice(index, 1);
      state.pageHeaderSwipers = data;
    },

    // 更改swper的item的icon_url
    setPageHeaderSwiperIconUrl(state, payload) {
      const { icon_url, index } = payload;
      let data = state.pageHeaderSwipers.slice();
      data[index].icon_url = icon_url;
      state.pageHeaderSwipers = data;
    },

    // 当进入该页面时如果服务器有数据则同步服务器的数据
    syncPageScreenDialog(state, payload) {
      state.screenDialogs = payload;
    },

    // 设置header的swiper的item
    setPageScreenDialog(state, payload) {
      const { index, value } = payload;
      let data = state.screenDialogs.slice();
      if (state.screenDialogs.length) {
        data[index] = {
          ...data[index],
          ...value,
        };
        state.screenDialogs = data;
      } else {
        state.screenDialogs = [value];
      }
    },

    // 删除一个swiper的item
    removePageScreenDialog(state, payload) {
      const { index } = payload;
      let data = state.screenDialogs.slice();
      data.splice(index, 1);
      state.screenDialogs = data;
    },

    // 更改swper的item的icon_url
    setPageScreenDialogIconUrl(state, payload) {
      const { icon_url, index } = payload;
      let data = state.screenDialogs.slice();
      data[index].icon_url = icon_url;
      state.screenDialogs = data;
    },
  },
};
