/**
 * @author yuhan.tang@magicwindow.cn
 */

export default {
  path: "accountCenter",
  meta: {
    login: true,
    Authentication: true,
    name: "账户中心"
  },
  component: () => import("@/views/accountCenter/index.vue")
};
