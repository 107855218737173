const MainChildren = () => import("@/components/main-children.vue");

const List = () => import("@/views/props-package/list.vue");

export default {
  path: "props-package",
  component: MainChildren,
  children: [
    {
      path: "list",
      name: "道具包激活",
      meta: {
        login: true,
        Authentication: true
      },
      component: List
    }
  ]
};
