<!--
 * @Description: 表格操作拦组件
                 编辑、预览、删除用单独图标显示，其他操作用 ... 下拉显示，如果下拉项只有一个，则显示单独的图标
 * @Author: panfei.guan
 * @Date: 2018-11-26 16:46:54
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-12-02 15:00:54
 -->

<template>
  <div class="mer-operation flex align-items-center">
    <!-- ... 列表长度大于1个 -->
    <el-dropdown
      class="m-r sync"
      v-if="extra.length > 1"
      @command="handleCommand"
    >
      <el-button
        class="tooltip-circle-btn"
        type="secondary"
        circle
        plain
        icon="iconfont icon-caozuolan-gengduo"
      ></el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-for="(item, index) in extra"
          :key="index"
          :command="item.type"
          :class="[item.class]"
          :disabled="item.disabled"
        >
          <i v-if="item.icon" :class="['iconfont', item.icon]"></i>
          {{ item.name }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <!-- ... 列表长度只有1 -->
    <el-tooltip
      v-else-if="extra.length === 1"
      effect="light"
      :content="extra[0].name"
      placement="bottom"
      :visible-arrow="false"
    >
      <el-button
        class="m-r tooltip-circle-btn"
        type="secondary"
        circle
        plain
        :icon="`iconfont ${extra[0].icon}`"
        :disabled="extra[0].disabled"
        @click="handleCommand(extra[0].type)"
      ></el-button>
    </el-tooltip>
    <!-- 编辑 -->
    <el-tooltip
      effect="light"
      :content="editText || '编辑'"
      placement="bottom"
      :visible-arrow="false"
      v-if="!editDisabled"
    >
      <el-button
        class="m-r tooltip-circle-btn"
        v-if="edit"
        type="primary"
        circle
        plain
        icon="iconfont icon-caozuolan-bianji"
        :disabled="editDisabled"
        @click.stop="handleEdit"
      ></el-button>
    </el-tooltip>
    <!-- 保存 -->
    <el-tooltip
      effect="light"
      :content="'保存'"
      placement="bottom"
      :visible-arrow="false"
    >
      <el-button
        class="m-r tooltip-circle-btn"
        v-if="save"
        type="success"
        circle
        plain
        icon="el-icon-check"
        :disabled="editDisabled"
        @click="handleSave"
      ></el-button>
    </el-tooltip>
    <!-- 预览 -->
    <el-tooltip
      effect="light"
      :content="previewText || $t('common.operation.preview')"
      placement="bottom"
      :visible-arrow="false"
    >
      <el-button
        class="m-r tooltip-circle-btn"
        v-if="preview"
        type="secondary"
        circle
        plain
        icon="iconfont icon-caozuolan-yulan"
        :disabled="previewDisabled"
        @click="handlePreview"
      ></el-button>
    </el-tooltip>
    <!-- 删除 -->
    <el-tooltip
      effect="light"
      :content="$t('common.operation.del')"
      placement="bottom"
      :visible-arrow="false"
    >
      <el-button
        class="m-r tooltip-circle-btn"
        v-if="del"
        type="danger"
        circle
        plain
        icon="iconfont icon-caozuolan-shanchu"
        :disabled="delDisabled"
        @click="handleDel"
      ></el-button>
    </el-tooltip>
    <!-- 商品推广 -->
    <el-tooltip
      effect="light"
      content="商品推广"
      placement="bottom"
      :visible-arrow="false"
    >
      <el-button
        class="m-r tooltip-circle-btn"
        style="margin-left:0;margin-right:10px"
        v-if="popularize"
        type="danger"
        circle
        icon="iconfont icon-icon-bangding"
        :disabled="delDisabled"
        @click="handlePopularize"
      ></el-button>
    </el-tooltip>

    <!-- 推广链接 -->
    <el-tooltip
      effect="light"
      content="推广链接"
      placement="bottom"
      :visible-arrow="false"
    >
      <el-button
        class="m-r tooltip-circle-btn"
        v-if="distribution"
        type="danger"
        circle
        plain
        icon="iconfont icon-icon-bangding"
        @click="handleDistribution"
      ></el-button>
    </el-tooltip>

    <!-- 评论管理 -->

    <el-tooltip
      effect="light"
      content="文章评论管理"
      placement="bottom"
      :visible-arrow="false"
    >
      <el-button
        class="m-r tooltip-circle-btn"
        v-if="comment"
        type="danger"
        circle
        plain
        @click="handleComment"
      >
      <el-icon class="el-icon-more"></el-icon>
      </el-button>
    </el-tooltip>

    <el-switch
      class="m-r"
      v-if="hasSwitch"
      v-model="status"
      active-color="#34BFA3"
      inactive-color="#D8D8D8"
      :disabled="switcDisabled"
      @change="handleSwitch"
    ></el-switch>
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    // 编辑
    edit: {
      type: Boolean,
      default: false
    },
    // 编辑按钮是否禁用
    editDisabled: {
      type: Boolean,
      default: false
    },
    // 预览
    preview: {
      type: Boolean,
      default: false
    },
    // 预览按钮是否禁用
    previewDisabled: {
      type: Boolean,
      default: false
    },
    // 删除
    del: {
      type: Boolean,
      default: false
    },
    // 保存
    save: {
      type: Boolean,
      default: false
    }, // 商品推广
    popularize: {
      type: Boolean,
      default: false
    },
    distribution: {
      type: Boolean,
      default: false
    },
    //评论
    comment: {
      type: Boolean,
      default: false
    },
    // 删除按钮是否禁用
    delDisabled: {
      type: Boolean,
      default: false
    },
    // 开关
    hasSwitch: {
      type: Boolean,
      default: false
    },
    switcStatus: {
      type: Boolean,
      default: false
    },
    switcDisabled: {
      type: Boolean,
      default: false
    },
    /*  额外数据， ...中要显示的列表 如：
      extra: [{
          type: 'technology',
          name: this.$t('eight23.technology'),
          icon: 'icon-caozuolan-jishuduijie',
          disabled: false
      }]*/
    extra: {
      type: Array,
      default: function() {
        return [];
      }
      // required: true
    },
    previewText: {
      type: String
    },
    editText: {
      type: String
    }
  },
  data() {
    return {
      status: false
    };
  },
  created() {
    this.status = this.switchStatus;
  },
  methods: {
    // 点击下拉菜单项 command: extra 中的 type 字段
    handleCommand(command) {
      this.$emit("selectExtra", command);
    },
    // 点击编辑按钮
    handleEdit() {
      this.$emit("edit");
    },
    // 点击保存按钮
    handleSave() {
      this.$emit("save");
    },
    // 点击预览按钮
    handlePreview() {
      this.$emit("preview");
    },
    // 点击删除按钮
    handleDel() {
      this.$emit("del");
    },
    // 点击开关按钮
    handleSwitch(val) {
      this.$emit("handleSwitch", val);
    },
    // 点击开关按钮
    handlePopularize(val) {
      this.$emit("handlePopularize", val);
    },
    // 点击推广链接
    handleDistribution() {
      this.$emit("handleDistribution");
    },
    // 点击评论
    // 点击推广链接
    handleComment() {
      this.$emit("handleComment");
    }
  }
};
</script>
<style lang="scss" scoped>
@import "ele/var.scss";

.mer-operation {
  .el-button.is-circle {
    padding: 6px;
  }

  .m-r {
    margin-right: 10px;
    margin-left: 0;
  }
}
</style>
