<template>
  <!-- <a target="_blank" style="color: #409EFF;font-size: 12px;margin-left: 20px" :href="syncHref">{{ name }}</a> -->
  <el-link
    type="primary"
    :href="syncHref"
    target="_blank"
    style="margin-left: 20px; font-size: 12px;"
  >{{ name }}</el-link>
</template>

<script>
import { docBase } from "@/config";
export default {
  props: {
    name: {
      type: String,
      default: ""
    }
  },

  computed: {
    syncHref() {
      return `${docBase}/doc/index?url=${this.name}`;
    }
  }
};
</script>

<style>
</style>