/*
 * @Author: sky.li
 * @LastEditors  : sky.li
 * @Description:
 * @可以输入预定的版权声明、个性签名、空行等
 */

export default {
  path: "crystal-red-pack",
  meta: {
    name: "购物返红包"
  },
  component: () => import("@/components/main-children.vue"),
  children: [
    {
      path: "list",
      meta: {
        name: "水晶礼包",
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("@/views/activityCenter/crystalRedPack/list")
    }
  ]
};
