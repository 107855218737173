import storage from "@/serving/storage.serving";

/**
 * 测试
 */

let state = storage.get("test") || {
  cart: {
    group: {
      // cart_1: { num: 1, info: {} },
      // cart_2: {}
    },
    single: {
      // cart_3: { num: 1, info: {} },
      // cart_4: {}
    },
  },
};
export default {
  state,
  mutations: {
    setTest(state, data) {
      // console.log(state.cart.group)
      state.cart.group = Object.assign(state.cart.group, data);
    },
    rmTest(state, data) {
      delete state.cart.group[data];
    },
  },
};
let a = {
  a: 1,
  b: 2,
};
