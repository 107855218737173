/*
 * @Author: sky.li
 * @LastEditors: Please set LastEditors
 * @Description:
 * @可以输入预定的版权声明、个性签名、空行等
 */
import 'babel-polyfill';
import Vue from 'vue';
import App from './App';
import router from './router';

// impor   t './serving/mock'

import './assets/css/index.scss';
import './assets/css/tailwind.css';

import ELEMENT from 'element-ui';
ELEMENT.Dialog.props.closeOnClickModal.default = false;
import '../mw-el-css-new/element-variables.scss';
import './serving';

import store from './store';

import filters from './filters';
import i18n from '@/lang/i18n';
// import EN from './lang/en';
// import JA from './lang/ja';
import pagination from './components/pagination';
import money from './components/money';
import tableDataEmpty from './components/table-data-empty';
import mwUpload from './components/mw-upload';
import mwUploadNew from './components/mw-upload-new';
import goodsCategory from '@/components/common/goodsCategory.vue';
// import LangServing from './serving/lang.serving';
import MerOperation from './components/mer-operation';
import MerDrawer from './components/mer-drawer';

Vue.config.productionTip = process.env.VUE_APP_EVENT !== 'dev' ? false : true;
Vue.config.devtools = process.env.VUE_APP_EVENT !== 'prod';
Vue.config.performance = process.env.VUE_APP_EVENT !== 'prod';

if (process.env.VUE_APP_EVENT == 'prod') {
  var _hmt = _hmt || [];
  (function () {
    var hm = document.createElement('script');
    hm.src = 'https://hm.baidu.com/hm.js?046a1e07ce4f9e8f5bac910a4d0e6bd5';
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(hm, s);
  })();
}

Vue.use(ELEMENT);

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

Vue.component('money', money);
Vue.component('pagination', pagination);
Vue.component('table-data-empty', tableDataEmpty);
Vue.component('mw-upload', mwUpload);
Vue.component('mw-upload-new', mwUploadNew);
Vue.component('mer-operation', MerOperation);
Vue.component('mer-drawer', MerDrawer);
Vue.component('mer-goods-category', goodsCategory);

/**
 *
 * 全局的弹窗方法
 */
Vue.prototype.$mwConfirm = function ({ title, content, cb, catchMessage }) {
  Vue.prototype
    .$confirm(content, title, {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    })
    .then(cb)
    .catch(() => {
      Vue.prototype.$message({
        type: 'info',
        message: catchMessage,
      });
    });
};

// 全局跳转到用户详情的方法
Vue.prototype.globalGoUserDetail = function (userId) {
  router.push(`/main/user/user-management/user-detail?user_id=${userId}`);
};

// 全局使用，判断星球是否可以进行权限管理的配置
Vue.prototype.hasPermissionManage = function () {
  const isProd = process.env.VUE_APP_EVENT === 'prod';
  let userInfo;
  try {
    userInfo = sessionStorage.getItem('userInfo');
    userInfo = JSON.parse(userInfo);
  } catch (e) {
    console.log('errr', e);
  }
  const shortName = userInfo.app_uat.short_name;
  const passShortNames = ['XIXI', 'YJBL', 'CHML', 'CYB', 'SIYUE'];
  // if(!isProd){
  //   return true
  // }else{
  //   if(passShortNames.includes(shortName)){
  //     return true
  //   }else{
  //     return false
  //   }
  // }
  if (passShortNames.includes(shortName)) {
    return true;
  } else {
    return false;
  }
};

import TargetDoc from '@/components/TargetDoc';
Vue.component('target-doc', TargetDoc);

import CommonProudctCategoryInput from '@/components/common/CommonProudctCategoryInput.vue';
Vue.component('CommonProductCategoryInput', CommonProudctCategoryInput);

import VueCookies from 'vue-cookies';
Vue.use(VueCookies);

import { config } from '@/config/index.js';

/* eslint-disable no-new */
const app = new Vue({
  el: '#app',
  i18n,
  router,
  store,
  components: {
    App,
  },
  template: '<App/>',
  created() {
    //动态域名
    const token = VueCookies.get('token');
    if (token) {
      config.requestRemoteIp().finally((res) => {
        // console.log('asdasdad3')
      });
    }
  },
});

app.$store.commit('unite/setDynamicDomain');

export default app;
