/**
 * author 文全
 */

import axios from "axios";
import router from "../router/index";
import VueCookies from "vue-cookies";
import i18n from "@/lang/i18n";
import Vue from "vue";
import { urlBase } from "@/config";
import { Message, MessageBox } from "element-ui";
import debounce from "lodash.debounce";

let time = 0;

function toLine(name) {
  return name.replace(/([A-Z])/g, "_$1").toLowerCase();
}

function caseToLineByObject(obj) {
  if (!obj || Array.isArray(obj)) {
    return obj;
  }
  const re = JSON.parse(JSON.stringify(obj));

  const keys = Object.keys(re);
  keys.forEach((key) => {
    const LineKey = toLine(key);
    if (key !== LineKey) {
      const val = re[key];
      re[LineKey] = val;
    }
  });
  return re;
}
// import storage from './storage.serving';
// import LangServing from './lang.serving';
/**
 * 封装的全局ajax请求
 */
// http request 拦截器
axios.interceptors.request.use(
  (config) => {
    // ;
    const token = VueCookies.get("token");
    if (token) {
      // 判断是否存在token，如果存在的话，则每个http header都加上token
      config.headers.Authorization = `${token}`;
      config.headers["mgnt-token"] = token;
    }
    config.headers.locale = "zh-cn";
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  async (res) => {
    let data = res.data;
    const code = data.code;
    const loginCodes = [4004, 4005, 4006, 4007];
    if (code == 4008) {
      const now = new Date().getTime();
      console.log(now - time, time);
      if (now - time > 1000) {
        MessageBox.alert("您无权操作~", {
          confirmButtonText: "我知道了",
        });
        time = now;
      }
      return Promise.reject({ code: 4008, message: "您无权操作" });
    } else if (loginCodes.includes(code)) {
      location.href = "/login";
    } else {
      return res;
    }

    // try {
    //   let code = res.data.code;
    //   if (code == 401 || res.status == 401) {
    //     // if (window.location.pathname !== '/') {
    //     // 非首页跳转登录
    //     location.href = "/login";
    //     // }
    //   } else if (code == 403) {
    //     Message.error("连接超时，请检查网络或者重试！");
    //   } else if (code == 4001) {
    //     //无权限操作情况

    //     if(new Vue().hasPermissionManage()){
    //       MessageBox.alert('您无权操作~',{
    //         confirmButtonText: '我知道了',
    //         callback: action => {

    //         }
    //       })
    //       console.log('bb---',res.data)

    //       return Promise.reject(res.data);
    //     }else{
    //       MessageBox.alert(`当前页面中存在部分还未添加的功能，请前往【应用中心】添加该功能后再进行操作。
    //         如您已经添加该功能，请重新登录后再试
    //       `,{
    //         confirmButtonText: '我知道了',
    //         callback: action => {

    //         }
    //       })
    //       return Promise.reject(res.data.message);
    //     }
    //   }

    // } catch (err) {
    //   console.log("----", err);
    // }
    // return res;
  },
  (err) => {
    if (process.env.VUE_APP_LANG == "en") {
      Message.error("The server opened a little difference!");
    } else {
      Message.error("服务器开了点小差！");
    }

    return Promise.reject(err);
  }
);
class Http {
  constructor(url = urlBase) {
    axios.defaults.baseURL = url;

    axios.defaults.timeout = 100000;
  }

  /**
   * GET 请求 {es6解构赋值}
   * @param type 包含url信息
   * @param data 需要发送的参数
   * @returns {Promise}
   * @constructor
   */
  async httpGet(url, resData = {}) {
    // 创建一个promise对象
    // resData = Object.assign(resData, this.locale)
    let params = resData;
    params = caseToLineByObject(resData);

    try {
      let { data } = await axios.get(url, {
        params,
      });
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  /**
     下载
  */
  async exportGet(url, resData = {}) {
    // 创建一个promise对象
    // resData = Object.assign(resData, this.locale)
    try {
      let { data } = await axios({
        url,
        method: "get",
        params: resData,
        responseType: "blob",
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  /**
     下载
  */
  async exportPost(url, resData = {}) {
    // 创建一个promise对象
    // resData = Object.assign(resData, this.locale)
    try {
      let { data } = await axios({
        url,
        method: "post",
        data: {
          ...resData,
        },
        responseType: "blob",
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * POST 请求
   * @param type Object 包含url信息
   * @param data Object 需要发送的参数
   * @returns {Promise}
   * @constructor
   */
  async httpPost(url, resData = {}, unCase = false) {
    // 创建一个promise对象

    try {
      let params = resData;
      if (!unCase) {
        params = caseToLineByObject(resData);
      }
      // resData = Object.assign(resData, this.locale)
      let { data } = await axios.post(url, params);
      if (data.code == 500) {
        Message.error("接口异常");
        console.error(500);
        //router.push('/')
      }
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * formDate
   */

  async formPost(url, resData = {}) {
    // 创建一个promise对象
    let formDate = new FormData();
    for (let i in resData) {
      formDate.append(i, resData[i]);
    }
    try {
      // resData = Object.assign(resData, this.locale)
      let { data } = await axios.post(
        url,

        formDate,

        {
          headers: {
            "Content-type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (data.code == 500) {
        console.error(500);
        //router.push('/')
      }
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  async get(url, resData = {}) {
    const params = caseToLineByObject(resData);

    // 创建一个promise对象
    try {
      let { data } = await axios.get(url, {
        params,
      });
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async post(url, resData = {}) {
    // 创建一个promise对象
    try {
      const params = caseToLineByObject(resData);

      let { data } = await axios.post(url, params);
      if (data.code == 500) {
        Message.error("系统异常");
        console.error(500);
      }
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async put(url, resData = {}) {
    // 创建一个promise对象
    try {
      let { data } = await axios.put(url, resData);
      if (data.code == 500) {
        Message.error("系统异常");
        console.error(500);
      }
      return data;
    } catch (error) {
      console.log(error);
    }
  }
  async delete(url, resData = {}) {
    // 创建一个promise对象
    try {
      let { data } = await axios.delete(url, resData);
      if (data.code == 500) {
        Message.error("系统异常");
        console.error(500);
      }
      return data;
    } catch (error) {
      console.log(error);
    }
  }
  async patch(url, resData = {}) {
    // 创建一个promise对象
    try {
      let { data } = await axios.patch(url, resData);
      if (data.code == 500) {
        Message.error("系统异常");
        console.error(500);
      }
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  get locale() {
    return {
      locale: "zh",
    };
  }
}
export default new Http();
