/*
 * @Author: sky.li
 * @LastEditors: sky.li
 * @Description:
 * @可以输入预定的版权声明、个性签名、空行等
 */
export default function() {
  if (process.env.VUE_APP_TYPE === 'mini') {
    return [
      {
        key: "home",
        title: "首页",
        img: "https://img.merculet.cn/home:tabbar:home.png",
        activeImg: "https://img.merculet.cn/home:tabbar:home-active.png",
      },
      {
        key: "mall",
        title: "星球店",
        img: "https://img.merculet.cn/home:tabbar:mall.png",
        activeImg: "https://img.merculet.cn/home:tabbar:mall-active.png",
      },
      {
        key: "my",
        title: "我的",
        img: "https://img.merculet.cn/home:tabbar:my.png",
        activeImg: "https://img.merculet.cn/home:tabbar:my-active.png",
      },
    ];
  }
  return [
    {
      key: "home",
      title: "首页",
      img: "https://img.merculet.cn/home:tabbar:home.png",
      activeImg: "https://img.merculet.cn/home:tabbar:home-active.png"
    },
    {
      key: "mall",
      title: "星球店",
      img: "https://img.merculet.cn/home:tabbar:mall.png",
      activeImg: "https://img.merculet.cn/home:tabbar:mall-active.png"
    },
    {
      key: "news",
      title: "视频号",
      img: "https://img.merculet.cn/home:tabbar:news.png",
      activeImg: "https://img.merculet.cn/home:tabbar:news-active.png"
    },
    {
      key: "group",
      title: "星会员",
      img: "https://img.merculet.cn/home:tabbar:group.png",
      activeImg: "https://img.merculet.cn/home:tabbar:group-active.png"
    },
    {
      key: "my",
      title: "我的",
      img: "https://img.merculet.cn/home:tabbar:my.png",
      activeImg: "https://img.merculet.cn/home:tabbar:my-active.png"
    }
  ];
}
