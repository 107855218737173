export default {
  path: "distribution",
  component: () => import("@/components/main-children.vue"),
  mata: {
    name: "推广链接"
  },
  children: [
    {
      path: "link",
      name: "distribution.name",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: () => import("../views/activityCenter/distribution/index")
    }
  ]
};
