import { render, staticRenderFns } from "./money.vue?vue&type=template&id=13133dad&scoped=true"
import script from "./money.vue?vue&type=script&lang=js"
export * from "./money.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13133dad",
  null
  
)

/* custom blocks */
import block0 from "./money.vue?vue&type=custom&index=0&blockType=money&%3AhasSpan=true&%3AclassName='aaa'&%3Anum=3333333"
if (typeof block0 === 'function') block0(component)
import block1 from "./money.vue?vue&type=custom&index=1&blockType=money&%3Anum=33"
if (typeof block1 === 'function') block1(component)

export default component.exports