import { dynamicDomainReplaceOption } from '@/plugins/tool';
const state = dynamicDomainReplaceOption();

let data = [
  {
    name: `${state.domainDynamicText}首页`,
    permissionType: 0,
    url: '/main/homePage',
    icon: 'icon-dashboard',
    childrenShow: false,
  },
  {
    name: '星球数据分析',
    permissionType: 0,
    url: '/main/eco/eco-transaction',
    icon: 'icon-yunyingzhushou',
    childrenShow: false,
  },
  {
    name: `${state.domainDynamicText}可视化`,
    permissionType: 0,
    url: '/main/homepage-manager',
    icon: 'icon-xingqiupeizhi',
    childrenShow: true,
    children: [
      {
        name: `${state.domainDynamicText}首页设计`,
        permissionType: 0,
        url: '/main/planet/visual_setting',
        childrenShow: false,
      },
      {
        name: '页面自定义图片',
        permissionType: 0,
        url: '/main/planet/background-setting',

        childrenShow: false,
      },
      {
        name: '自定义邀请海报',
        permissionType: 0,
        url: '/main/planet/invite-poster',
        childrenShow: false,
      },
      {
        name: '自定义文章海报',
        permissionType: 0,
        url: '/main/planet/article-poster',
        childrenShow: false,
      },
      {
        name: '自定义活动海报',
        permissionType: 0,
        url: '/main/planet/activity-poster',
        childrenShow: false,
      },
      {
        name: '自定义商品海报',
        permissionType: 0,
        url: '/main/planet/product-poster',
        childrenShow: false,
      },
      {
        name: '自定义分享文案图标',
        permissionType: 0,
        url: '/main/planet/share-custom',
        childrenShow: false,
      },
      {
        name: '设置',
        permissionType: 0,
        url: '/main/planet/setting',
        childrenShow: false,
      },
    ],
  },
  {
    name: '内容管理',
    permissionType: 0,
    url: '/main/article-manager',
    icon: 'icon-neirongguanli1',
    childrenShow: true,
    children: [
      {
        name: '文章管理',
        permissionType: 0,
        url: '/main/spt/article',
        childrenShow: false,
      },
      {
        name: '文章分类',
        permissionType: 0,
        url: '/main/spt/article-class',
        childrenShow: false,
      },
      {
        name: '文章模版',
        permissionType: 0,
        url: '/main/spt/article-template',
        childrenShow: false,
      },
      {
        name: '广告位管理',
        permissionType: 0,
        url: '/main/spt/article-banners',
        childrenShow: false,
      },
      {
        name: '文章统计',
        permissionType: 0,
        url: '/main/spt/article-statistics',
        childrenShow: false,
      },

      {
        name: '内容订单管理',
        permissionType: 0,
        url: '/main/spt/article-pay',
        childrenShow: false,
      },

      {
        name: '视频号管理',
        permissionType: 0,
        url: '/main/spt/friendCircle-forward',
        childrenShow: false,
      },
      // {
      //   name: "推荐页管理",
      //   permissionType: 0,
      //   url: "/main/spt/recomend-page-managent",
      //   childrenShow: false,
      // },
      {
        name: '内容素材库',
        permissionType: 0,
        url: '/main/vod/index',
        childrenShow: false,
      },
      {
        // name: "审核失败记录",
        permissionType: 0,
        url: '/main/vod/auth-failed',
        childrenShow: false,
      },
      {
        name: '人物索引管理',
        permissionType: 1,
        url: '/main/personal/index',
        childrenShow: false,
      },
    ],
  },

  {
    code: 'menu.sys',
    name: '商城管理',
    permissionType: 0,
    url: '/main/mall-manager',
    icon: 'icon-shangchengguanli',
    childrenShow: true,
    children: [
      {
        name: '商品管理',
        permissionType: 0,
        url: '/main/mall/goods',
        childrenShow: false,
      },
      {
        name: '上下架管理',
        permissionType: 0,
        url: '/main/mall/goods-market',
        childrenShow: false,
      },
      {
        name: '商品分类管理',
        permissionType: 0,
        url: '/main/mall/category',
        childrenShow: false,
      },
      {
        name: '邮费管理',
        permissionType: 0,
        url: '/main/mall/freight-template',
        childrenShow: false,
      },
      {
        name: '门店管理',
        permissionType: 0,
        url: '/main/mall/stores-manage',
        childrenShow: false,
      },
      {
        name: '订单管理',
        permissionType: 0,
        url: '/main/mall/order',
        childrenShow: false,
      },
      {
        name: '售后订单',
        url: '/main/mall/refund',
        permissionType: 0,
        childrenShow: false,
      },

      {
        name: '购物奖励管理',
        permissionType: 0,
        url: '/main/mall/awards',
        childrenShow: false,
      },

      {
        name: '搜索热词管理',
        permissionType: 0,
        url: '/main/mall/hot-word',
        childrenShow: false,
      },
    ],
  },
  {
    name: '直播管理',
    permissionType: 0,
    url: '/mall/live-manager',
    icon: 'icon-shangchengguanli',
    childrenShow: true,
    children: [
      {
        name: '直播列表',
        permissionType: 0,
        url: '/main/live/list',
        childrenShow: false,
      },
      {
        name: '直播账户管理',
        permissionType: 0,
        url: '/main/live/manage',
        childrenShow: false,
      },
    ],
  },
  {
    code: 'menu.sys',
    name: '活动报名',
    permissionType: 0,
    url: '/main/activity-manager',
    icon: 'icon-shangchengguanli',
    childrenShow: true,
    children: [
      {
        name: '活动报名',
        permissionType: 0,
        url: '/main/activity_sign/list',
        childrenShow: false,
      },
      {
        name: '活动分销设置',
        permissionType: 0,
        url: '/main/activity_sign/distribution_list',
        childrenShow: false,
      },
      {
        name: '活动分销订单',
        permissionType: 0,
        url: '/main/activity_sign/order',
        childrenShow: false,
      },
    ],
  },
  {
    code: 'menu.sys',
    name: '课程管理',
    permissionType: 0,
    url: '/main/class-manager',
    icon: 'icon-zhuanshuijingsudutisheng',
    childrenShow: true,
    children: [
      {
        name: '课程管理',
        permissionType: 1,
        url: '/main/mall/class-administration',
        childrenShow: false,
      },
      {
        name: '课程上架',
        permissionType: 0,
        url: '/main/mall/class-goods',
        childrenShow: false,
      },
      {
        name: '课程订单',
        permissionType: 0,
        url: '/main/mall/class-order',
        childrenShow: false,
      },
    ],
  },

  {
    name: '景区管理',
    permissionType: 0,
    icon: 'icon-jingdian',
    childrenShow: true,
    isHard: 'ticket',
    url: '/main/spot-manager',
    children: [
      {
        name: '景区列表',
        permissionType: 0,
        url: '/main/mall/scenic',
        childrenShow: false,
        isHard: 'ticket',
      },
      {
        name: '门票订单',
        permissionType: 0,
        url: '/main/mall/scenic/ticket-list',
        childrenShow: false,
        isHard: 'ticket',
      },
      {
        name: '门票分销',
        permissionType: 0,
        url: '/main/distribute/tickets',
        childrenShow: false,
        isHard: 'ticket',
      },
      {
        name: '门票分销订单',
        permissionType: 0,
        url: '/main/distribute/ticket-order',
        childrenShow: false,
        isHard: 'ticket',
      },
    ],
  },
  {
    name: '用户管理',
    permissionType: 0,
    icon: 'icon-yonghuguanli',
    url: '/main/user-manager',
    childrenShow: true,
    children: [
      {
        name: '用户列表',
        url: '/main/user/user-list',
        permissionType: 0,
        childrenShow: false,
      },

      {
        name: '用户水晶报告',
        url: '/main/user/user-score-report',
        permissionType: 0,
        childrenShow: false,
      },
      {
        name: '标签管理',
        url: '/main/user/user-tag',
        permissionType: 0,
        childrenShow: false,
      },
      {
        name: '白名单设置',
        url: '/main/user/whitelist',
        permissionType: 0,
        childrenShow: false,
      },
      {
        name: '黑名单设置',
        url: '/main/user/blacklist',
        permissionType: 0,
        childrenShow: false,
      },
      {
        name: '用户查询',
        url: '/main/user/for-query-user',
        permissionType: 0,
        childrenShow: false,
      },
      {
        name: '生成用户码',
        permissionType: 0,
        url: '/main/operating-assistant/generate-usercode',
        childrenShow: false,
        children: null,
      },
      {
        name: '二维码验证',
        permissionType: 0,
        url: '/main/operating-assistant/verification-qrcode',
        childrenShow: false,
        children: null,
      },
    ],
  },
  {
    name: '会员管理',
    icon: 'icon-yonghuguanli',
    permissionType: 0,
    url: '/main/vip-manager',
    childrenShow: true,
    children: [
      {
        name: '会员卡设置',
        url: '/main/vip/setting',
        permissionType: 0,
        childrenShow: false,
      },

      {
        name: '会员列表',
        url: '/main/vip/vip-list',
        permissionType: 0,
        childrenShow: false,
      },
      {
        name: '会员订单',
        url: '/main/vip/orders',
        permissionType: 0,
        childrenShow: false,
      },
      {
        name: '会员卡分销',
        permissionType: 0,
        url: '/main/distribute/vipCards',
        childrenShow: false,
      },
      {
        name: '会员卡分销订单',
        permissionType: 0,
        url: '/main/distribute/vipcards-order',
        childrenShow: false,
      },
    ],
  },

  {
    name: '星战队',
    url: '/main/distribute-manager',
    permissionType: 0,
    icon: 'icon-xingzhanduiguanli',
    childrenShow: true,
    children: [
      {
        name: '分销设置',
        permissionType: 0,
        url: '/main/distribute/setting',
        childrenShow: false,
      },
      {
        name: '战队关系设置',
        permissionType: 0,
        url: '/main/distribute/team-relationship-settings',
        childrenShow: false,
      },
      {
        name: '商品供应激励',
        permissionType: 0,
        url: '/main/distribute/supply-incentives',
        childrenShow: false,
      },
      {
        name: '商品会员返利',
        permissionType: 0, // 提交
        url: '/main/distribute/product-rebate',
        childrenShow: false,
      },
      {
        name: '会员返利订单',
        permissionType: 0, // 提交
        url: '/main/distribute/product-rebate-order',
        childrenShow: false,
      },
      {
        name: '商品分销设置',
        permissionType: 0,
        url: '/main/distribute/goods',
        childrenShow: false,
      },
      {
        name: '商品分销订单',
        permissionType: 0,
        url: '/main/distribute/order',
        childrenShow: false,
      },

      {
        name: '分销员等级',
        permissionType: 0,
        url: '/main/distribute/level',
        childrenShow: false,
      },
      {
        name: '分销业绩',
        permissionType: 0,
        url: '/main/distribute/income',
        childrenShow: false,
      },

      {
        name: '合伙人业绩',
        permissionType: 0,
        url: '/main/distribute/partner-performance',
        childrenShow: false,
      },
      {
        name: '直接组队',
        permissionType: 0,
        url: '/main/operating-assistant/team-up/list',
        childrenShow: false,
      },
      {
        name: '解绑战队',
        permissionType: 0,
        url: '/main/operating-assistant/unbundling-team/index',
        childrenShow: false,
        children: null,
      },
      {
        name: '调整战队',
        permissionType: 0,
        url: '/main/operating-assistant/adjust-team/index',
        childrenShow: false,
        children: null,
      },
      {
        name: '战队关系',
        permissionType: 0,
        url: '/main/distribute/team-relation',
        childrenShow: false,
        children: null,
      },
    ],
  },
  {
    name: `${state.domainDynamicText}赋能`,
    permissionType: 0,
    url: '/main/cooperation-manager',
    icon: 'icon-funeng',
    childrenShow: true,
    children: [
      {
        name: `${state.domainDynamicText}内容赋能`,
        permissionType: 0,
        url: '/main/planet-cooperation/unit/list',
        childrenShow: false,
        children: null,
      },
      {
        name: `${state.domainDynamicText}商品赋能`,
        permissionType: 0,
        url: '/main/planet-cooperation/unit/product-list',
        childrenShow: false,
        children: null,
      },
      {
        name: `${state.domainDynamicText}课程赋能`,
        permissionType: 1,
        url: '/main/planet-cooperation/unit/class-goods-list',
        childrenShow: false,
        children: null,
      },
      {
        name: `礼品卡赋能`,
        permissionType: 1,
        url: '/main/planet-cooperation/unit/gift-card-list',
        childrenShow: false,
        children: null,
      },
      {
        name: '被赋能管理',
        permissionType: 0,
        url: '/main/planet-cooperation/unit/unit-articles',
        childrenShow: false,
        children: null,
      },
    ],
  },
  {
    name: `${state.domainDynamicText}任务`,
    permissionType: 0,
    url: '/main/task-manager',
    icon: 'icon-shequnguanli',
    childrenShow: true,
    children: [
      {
        name: '任务管理',
        permissionType: 0,
        url: '/main/planet/task',
        childrenShow: false,
      },
    ],
  },
  {
    name: `${state.domainDynamicText}配置`,
    permissionType: 0,
    url: '/main/star-config-manager',
    icon: 'icon-shequnguanli',
    childrenShow: true,
    children: [
      {
        name: `${state.domainDynamicText}介绍`,
        permissionType: 0,
        url: '/main/planet/introduce',
        childrenShow: false,
      },
      // {
      //   name: "品牌页设置",
      //   permissionType: 0,
      //   url: "/main/planet/brand-setting",
      //   childrenShow: false
      // },

      {
        name: `${state.domainDynamicText}主设置`,
        url: '/main/user/star-owner-setting',
        permissionType: 0,
        childrenShow: false,
      },

      {
        name: `首页${state.domainDynamicText}主链接`,
        url: '/main/user/home-owner-link',
        permissionType: 0,
        childrenShow: false,
      },
      {
        name: '公众号设置',
        permissionType: 0,
        url: '/main/operating-assistant/public-account-settings',
        childrenShow: false,
        children: null,
      },
      {
        name: '客服设置',
        permissionType: 0,
        url: '/main/mall/customer_service',
        childrenShow: false,
      },
      {
        name: '社群设置',
        permissionType: 0,
        url: '/main/community/robot_setting',
        childrenShow: false,
      },
    ],
  },
  {
    name: '数据统计',
    permissionType: 0,
    url: '/main/statistics-manager',
    icon: 'icon-shujutongji',
    childrenShow: true,
    children: [
      {
        name: `浏览数据统计`,
        permissionType: 0,
        url: '/main/order-statistics/view-data-list',
        childrenShow: false,
        children: null,
      },
      {
        name: `${state.domainDynamicText}订单表`,
        permissionType: 0,
        url: '/main/order-statistics/star-order-list',
        childrenShow: false,
        children: null,
      },
      {
        name: `${state.domainDynamicText}售后订单表`,
        permissionType: 0,
        url: '/main/order-statistics/ps-star-order-list',
        childrenShow: false,
        children: null,
      },
      {
        name: '分销业绩表',
        permissionType: 0,
        url: '/main/order-statistics/income-list',
        childrenShow: false,
        children: null,
      },
      {
        name: '交易对账单',
        permissionType: 0,
        url: '/main/order-statistics/transation-statement-list',
        childrenShow: false,
        children: null,
      },
      {
        name: '核心数据统计',
        permissionType: 0,
        url: '/main/eco/eco-transaction',
        childrenShow: false,
        children: null,
      },
      {
        name: '流水对账',
        permissionType: 0,
        url: '/main/order-statistics/accounting-records-list',
        childrenShow: false,
        children: null,
      },
      {
        name: '商品核销记录',
        permissionType: 0,
        url: '/main/mall/writeoff-record',
        childrenShow: false,
      },

      {
        name: '分润统计',
        permissionType: 0,
        url: '/main/order-statistics/mark-score-statisitics',
        isCreateGeek: 'open',
        childrenShow: false,
      },
      {
        name: '发布白名单数据统计',
        permissionType: 0,
        url: '/main/order-statistics/publish-whitelist',
        childrenShow: false,
        openWhiteList: 'open',
        children: null,
      },
      {
        name: '合伙人统计',
        permissionType: 1,
        url: '/main/order-statistics/partner-statistic',
        childrenShow: false,
        // openWhiteList: "open",
        children: null,
      },
      {
        name: '统计明细',
        permissionType: 1,
        url: '/main/order-statistics/statistic-detail',
        childrenShow: false,
        // openWhiteList: "open",
        children: null,
      },
      {
        name: 'X计划统计',
        permissionType: 1,
        url: '/main/order-statistics/xplan-statistics',
        childrenShow: false,
        // openWhiteList: "open",
        children: null,
      },
      {
        name: '合伙人数据',
        permissionType: 1,
        url: '/main/order-statistics/partners',
        childrenShow: false,
        // openWhiteList: "open",
        children: null,
      },
      {
        name: '项目数据',
        permissionType: 1,
        url: '/main/order-statistics/projects',
        childrenShow: false,
        // openWhiteList: "open",
        children: null,
      },
      {
        name: '她守护活动',
        permissionType: 1,
        url: '/main/order-statistics/activity-statistics',
        childrenShow: false,
        // openWhiteList: "open",
        children: null,
      },
      // {
      //   name: "用户数据",
      //   permissionType: 1,
      //   url: "/main/order-statistics/users",
      //   childrenShow: false,
      //   // openWhiteList: "open",
      //   children: null,
      // },
      // {
      //   name: "X计划概览",
      //   permissionType: 0,
      //   url: "/main/order-statistics/xplan-overview",
      //   childrenShow: false,
      //   // openWhiteList: "open",
      //   children: null,
      // },
    ],
  },

  {
    name: '营销插件',
    permissionType: 0,
    url: '/main/sale-plugin-manager',
    icon: 'icon-huodongzhongxin1',
    childrenShow: true,
    children: [
      {
        name: '优惠券',
        permissionType: 0,
        url: '/main/coupon/list',
        childrenShow: false,
      },
      {
        name: '批量发送优惠券',
        permissionType: 0,
        url: '/main/operating-assistant/send-coupon',
        childrenShow: false,
        children: null,
      },
      {
        name: '新人礼包',
        permissionType: 0,
        url: '/main/userGift/list',
        childrenShow: false,
        children: null,
      },
      {
        name: '购物红包',
        permissionType: 0,
        url: '/main/rebateCoupon/list',
        childrenShow: false,
      },
      {
        name: '水晶礼包',
        permissionType: 0,
        url: '/main/crystal-red-pack/list',
        childrenShow: false,
      },
      {
        name: '标注水晶礼包',
        permissionType: 0,
        url: '/main/mark-red-pack/list',
        childrenShow: false,
        isCreateGeek: 'open',
      },
      {
        name: '多人拼团',
        permissionType: 0,
        url: '/main/pinMall/list',
        childrenShow: false,
      },

      {
        name: '好友助力',
        permissionType: 0,
        url: '/main/friendAssistMall/list',
        childrenShow: false,
      },
      {
        name: '签到',
        permissionType: 0,
        url: '/main/signIn/list',
        childrenShow: false,
      },
      {
        name: '抽奖活动',
        permissionType: 0,
        url: '/main/activity-manager/prize/list',
        childrenShow: false,
      },
      {
        name: '推广链接',
        permissionType: 0,
        url: '/main/distribution/link',
        childrenShow: false,
        children: null,
      },
      {
        name: '充值设置',
        permissionType: 1,
        url: '/main/operating-assistant/charge-setting',
        childrenShow: false,
        children: null,
      },
      {
        name: '项目礼包设置',
        permissionType: 0,
        url: '/main/operating-assistant/project-gift-package',
        childrenShow: false,
        children: null,
      },
      {
        name: 'X计划',
        permissionType: 1,
        url: '/main/operating-assistant/activitys-crowdFunding',
        childrenShow: false,
      },
      {
        name: 'X计划战队',
        permissionType: 1,
        url: '/main/operating-assistant/xplan-team',
        childrenShow: false,
      },
      {
        name: '支持留言模板设置',
        permissionType: 1,
        url: '/main/operating-assistant/support-message',
        childrenShow: false,
      },
      {
        name: '战队宣言模板',
        permissionType: 1,
        url: '/main/operating-assistant/team-message',
        childrenShow: false,
      },
      {
        name: '合伙人申请',
        permissionType: 1,
        url: '/main/operating-assistant/partner-applyFor',
        childrenShow: false,
      },
      {
        name: '扫码看积分',
        permissionType: 1,
        url: '/main/operating-assistant/check-points',
        childrenShow: false,
      },
      // {
      //   name: "礼品卡",
      //   permissionType: 1,
      //   url: "/main/operating-assistant/gift-card",
      //   childrenShow: false,
      // },
    ],
  },
  {
    name: '资产管理',
    permissionType: 0,
    url: '/main/assets-manager',
    icon: 'icon-zichanguanli',
    childrenShow: true,
    children: [
      {
        name: '水晶管理',
        permissionType: 0,
        url: '/main/assets/uat_manage',
        childrenShow: false,
      },
      {
        name: '商户资产',
        permissionType: 0,
        url: '/main/assets/mall_assets',
        childrenShow: false,
      },
      {
        name: '支付设置',
        permissionType: 0,
        url: '/main/assets/pay_setting',
        childrenShow: false,
      },
      {
        name: '视频流量充值',
        permissionType: 0,
        url: '/main/assets/vod-recharge',
        childrenShow: false,
      },
      {
        name: '短信统计',
        permissionType: 0,
        url: '/main/assets/sms-statistical',
        childrenShow: false,
      },
    ],
  },

  {
    name: '账户管理',
    permissionType: 0,
    url: '/main/account-manager',
    icon: 'icon-yunyingzhushou',
    childrenShow: true,
    children: [
      {
        name: '子账户管理',
        permissionType: 0,
        url: '/main/system-setup/account-management',
        childrenShow: false,
        children: null,
      },
      {
        name: '角色权限管理',
        permissionType: 1,
        url: '/main/system-setup/role-permissions-management',
        childrenShow: false,
        children: null,
      },
      {
        name: '核销员管理',
        permissionType: 0,
        url: '/main/operating-assistant/write-off-new',
        childrenShow: false,
        children: null,
      },

      {
        name: '供应商管理',
        permissionType: 0,
        url: '/main/system-setup/supply',
        childrenShow: false,
        children: null,
      },
      // {
      //   name: "商户端管理员",
      //   permissionType: 0,
      //   url: "/main/operating-assistant/business-admin",
      //   childrenShow: false,
      //   children: null,
      // },
      {
        name: '运营人员列表',
        url: '/main/user/operator-list',
        permissionType: 0,
        childrenShow: false,
      },
      {
        name: '订单通知',
        permissionType: 0,
        url: '/main/operating-assistant/order-notification',
        childrenShow: false,
        children: null,
      },
    ],
  },

  {
    name: '系统设置',
    permissionType: 0,
    url: '/main/config-manager',
    icon: 'icon-icon-shezhimoren',
    childrenShow: true,
    children: [
      {
        name: '设置',
        permissionType: 0,
        url: '/main/system-setup/setting',
        childrenShow: false,
        children: null,
      },
      {
        name: '账户中心',
        permissionType: 0,
        url: '/main/topics/accountCenterTop',
        childrenShow: false,
        children: null,
      },
      {
        name: 'Q&A管理',
        permissionType: 0,
        url: '/main/system-setup/qa-manage',
        childrenShow: false,
        children: null,
      },
    ],
  },

  {
    name: `${state.domainDynamicText}共创管理`,
    permissionType: 0,
    url: '/main/star-co-creation-manage',
    icon: 'icon-jingdian',
    childrenShow: false,
    children: [
      {
        name: `${state.domainDynamicText}共创管理`,
        permissionType: 0,
        url: '/main/star-co-creation-manage',
        childrenShow: false,
      },
      {
        name: '项目详情',
        permissionType: 0,
        url: '/main/star-co-creation-manage/info',
        childrenShow: false,
      },
      {
        name: '项目详情',
        permissionType: 0,
        url: '/main/star-co-creation-manage/sub-project-list',
        childrenShow: false,
      },
    ],
  },
  {
    name: '分账管理',
    permissionType: 1,
    url: '/main/ledger',
    icon: 'icon-yunyingzhushou',
    childrenShow: true,
    children: [
      {
        name: '分账记录',
        permissionType: 1,
        url: '/main/ledger/ledger-record-list',
        childrenShow: false,
        children: null,
      },
      {
        name: '分账操作',
        permissionType: 1,
        url: '/main/ledger/allocate-ledger-list',
        childrenShow: false,
        children: [
          {
            name: '补发分账',
            permissionType: 1,
            url: '/main/ledger/allocate-ledger-operation-add',
            childrenShow: false,
            children: null,
          },
          {
            name: '分账操作详情',
            permissionType: 1,
            url: '/main/ledger/allocate-ledger-operation-detail',
            childrenShow: false,
            children: null,
          },
        ],
      },
      {
        name: '余额充值记录',
        permissionType: 1,
        url: '/main/ledger/balance-charge-record-list',
        childrenShow: false,
        children: null,
      },
      {
        name: '分账账户管理',
        permissionType: 1,
        url: '/main/ledger/ledger-account-list',
        childrenShow: false,
        children: [
          {
            name: '分账账户详情',
            permissionType: 1,
            url: '/main/ledger/ledger-account-detail',
            childrenShow: false,
            children: null,
          },
        ],
      },
      {
        name: '星球账户管理',
        permissionType: 0,
        url: '/main/ledger/star-account-management',
        childrenShow: false,
        children: null,
      },
      // {
      //   name: "微信分账提现",
      //   permissionType: 0,
      //   url: "/main/ledger/weChat-account-withdrawal",
      //   childrenShow: false,
      //   children: null,
      // },
    ],
  },
  {
    name: '分账管理',
    permissionType: 0,
    url: '/main/ledgerWx',
    icon: 'icon-yunyingzhushou',
    childrenShow: true,
    children: [
      {
        name: '微信分账提现',
        permissionType: 0,
        url: '/main/ledgerWx/weChat-account-withdrawal',
        childrenShow: false,
        children: null,
      },
    ],
  },
  {
    name: '礼品卡',
    permissionType: 1,
    url: '/main/gift-card',
    icon: 'icon-yunyingzhushou',
    childrenShow: true,
    children: [
      {
        name: '礼品卡管理',
        permissionType: 1,
        url: '/main/gift-card/list',
        childrenShow: false,
        children: null,
      },
      {
        name: '礼品卡订单',
        permissionType: 1,
        url: '/main/gift-card/all-order-list',
        childrenShow: false,
        children: null,
      },
    ],
  },
  {
    name: '生态',
    permissionType: 0,
    url: '/main/eco',
    icon: 'icon-yunyingzhushou',
    childrenShow: true,
    children: [
      {
        name: '生态等级身份管理',
        permissionType: 0,
        url: '/main/eco/role-manage',
        childrenShow: false,
        children: null,
      },
      {
        name: 'X计划战队设置',
        permissionType: 0,
        url: '/main/eco/X-team-settings',
        childrenShow: true,
        children: [
          {
            name: '管理奖设置',
            permissionType: 0,
            url: '/main/eco/X-distribute-setting',
            childrenShow: false,
            children: null,
          },
        ],
      },
      {
        name: '生态战队设置',
        permissionType: 0,
        url: '/main/eco/eco-team-manage',
        childrenShow: false,
        children: null,
      },
      {
        name: '身份分销设置',
        permissionType: 0,
        url: '/main/eco/eco-role-distribution-manage',
        childrenShow: false,
        children: null,
      },
      {
        name: '生态用户身份管理',
        permissionType: 0,
        url: '/main/eco/eco-identity-management',
        childrenShow: false,
        children: null,
      },
      {
        name: '生态关系',
        permissionType: 0,
        url: '/main/eco/eco-relation',
        childrenShow: false,
        children: null,
      },
      {
        name: '预导入生态关系',
        permissionType: 0,
        url: '/main/eco/eco-relation-import',
        childrenShow: false,
        children: null,
      },
      {
        name: '生态用户',
        permissionType: 0,
        url: '/main/eco/eco-users',
        childrenShow: false,
        children: null,
      },
      {
        name: 'X计划战队',
        permissionType: 0,
        url: '/main/eco/eco-xplan-team',
        childrenShow: false,
        children: null,
      },
      {
        name: 'X计划战队收益记录',
        permissionType: 0,
        url: '/main/eco/eco-xplan-team-earnings-record',
        childrenShow: false,
        children: null,
      },
      {
        name: 'X计划统计',
        permissionType: 1,
        url: '/main/eco/xplan-statistics',
        childrenShow: false,
        children: null,
      },
      {
        name: '加入生态',
        permissionType: 0,
        url: '/main/eco/join-eco',
        childrenShow: false,
        children: null,
      },
      {
        name: '星球列表',
        permissionType: 0,
        url: '/main/eco/star-list',
        childrenShow: false,
        children: null,
      },
      {
        name: '生态申请',
        permissionType: 0,
        url: '/main/eco/eco-apply-list',
        childrenShow: false,
        children: null,
      },
      {
        name: '邀请码管理',
        permissionType: 0,
        url: '/main/eco/invitation-code-management',
        childrenShow: false,
        children: null,
      },
      {
        name: '分账列表',
        permissionType: 0,
        url: '/main/ledger/sub-ledger-list',
        childrenShow: false,
        children: null,
      },
      {
        name: '申诉记录',
        permissionType: 0,
        url: '/main/eco/appeal-record',
        childrenShow: false,
        children: null,
      },
      {
        name: '用户提现申请',
        permissionType: 0,
        url: '/main/eco/user-withdrawal-application',
        childrenShow: false,
        children: null,
      },
      {
        name: '商户提现申请',
        permissionType: 0,
        url: '/main/eco/merchant-withdrawal-application',
        childrenShow: false,
        children: null,
      },
      {
        name: '星球统计',
        permissionType: 0,
        url: '/main/eco/star-statistic',
        childrenShow: false,
        children: null,
      },
      {
        name: '合伙人统计',
        permissionType: 0,
        url: '/main/eco/partner-statistic',
        childrenShow: false,
        children: null,
      },
      {
        name: '合伙人申请',
        permissionType: 0,
        url: '/main/eco/partner-applyFor',
        childrenShow: false,
        children: null,
      },
      {
        name: '统计明细',
        permissionType: 0,
        url: '/main/eco/statistic-detail',
        childrenShow: false,
        children: null,
      },
      {
        name: '小天使管理',
        permissionType: 0,
        url: '/main/eco/little-angel-manage',
        childrenShow: false,
        children: null,
      },
      {
        name: '项目管理',
        permissionType: 0,
        url: '/main/eco/project-manage',
        childrenShow: false,
        children: null,
      },
      {
        name: '项目管理',
        permissionType: 0,
        url: '/main/eco/project-manage-detail',
        childrenShow: false,
        children: null,
      },
      {
        name: '联盟公司数据',
        permissionType: 0,
        url: '/main/eco/eco-star',
        childrenShow: false,
        children: null,
      },
      {
        name: '合伙人数据',
        permissionType: 0,
        url: '/main/eco/eco-partner',
        childrenShow: false,
        children: null,
      },
      {
        name: '项目数据',
        permissionType: 0,
        url: '/main/eco/eco-project',
        childrenShow: false,
        children: null,
      },
      {
        name: '用户数据',
        permissionType: 0,
        url: '/main/eco/eco-users-data',
        childrenShow: false,
        children: null,
      },
    ],
  },
];

export default data;
