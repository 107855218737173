
/**
 * 检测当前版本，如果版本不对，跳到对应的版本
 */
const isMiniType=process.env.VUE_APP_TYPE==='mini' // 系统的版本，代表了当前工程属于哪个版本

function gotoNewPath(to,isMini){
    const {fullPath}=to
    const {origin}=location
    if(isMini){
        location.href=origin+'/mini'+fullPath
    }else{
        location.href=origin+fullPath

    }


}

export default (sys_version,to)=>{
    if (!sys_version) {
        return true
    }
    const isMini=sys_version==='I_MINI' // 用户版本，代表用户属于哪个版本，如果与系统版本不一致，则跳到用户对应的版本
    if(isMini!==isMiniType){
      gotoNewPath(to,isMini)
      return false
    }
    return true
}