/**
 * author 文全
 */

import PublicApi from "@/api/public";
import {
  hasTicket,
  isCreateGeek,
  hasQueryUser,
  openPublishWhitelist,
  hasCrowdFund,
  hasQueryUserLec,
  hasActivityCrowdFund,
  hasXplanTeam,
  hasPartnerStatistic,
  hasStatisticDetail,
  hasXplanOverview,
  hasActivityMessage,
  hasLedger,
  hasHerActivity,
  hasGiftCard,
  hasChargeSetting,
  hasPermission,
  hasEco,
  hasTeamMessage,
  hasXplanStatistics,
  statisticsManagerhasXplanStatistics,
  hasPartnerData,
  hasProjectData,
  hasStarTransaction,
  hasCheckPoints,
  hasPartnerApplyFor,
  hasRolePermissionMenu
} from "@/config/hardCode";
import dicksMenuData from "@/dicks/menu";
import { hasTeamRelation } from "../config/hardCode";
/**
 * 左边菜单模块
 */

let state = {
  topMenu: false,
  data: [],
  type: "",
  appId: 1,
  appName: "",
  uatId: 1,
  mesType: "",
  appKey: "",
  tokenName: "",
  appType: "", //app类型 simplet还是应用
};
export default {
  state,
  mutations: {
    setAppType(state, type) {
      state.appType = type;
    },
    setAppKey(state, key) {
      state.appKey = key;
    },
    setTokenName(state, name) {
      state.tokenName = name;
    },
    setMesType(state, type) {
      state.mesType = type;
    },
    setUAatId(state, id) {
      state.uatId = id;
    },
    setAppId(state, id) {
      state.appId = id;
    },
    setAppName(state, name) {
      state.appName = name;
    },
    setLeftMenu(state, data) {
      state.data = data;
    },
    setTopMenu(state, data) {
      state.topMenu = data;
    },
    setType(state, data) {
      state.type = data;
    },
    removeLeftMenu(state) {
      state.data = [];
      state.type = "app";
      state.topMenu = false;
    },
  },
  actions: {
    async setMainLeftMenu(store, permission) {
      // let data = await PublicApi.appList()
      // 超级后台
      let robot = {};
      let sptMenu = [robot];
      let userInfo = sessionStorage.getItem("userInfo");
      try {
        userInfo = JSON.parse(userInfo);
      } catch (e) {}
      let goodsMenu = [];
      let unitGoodsMenu = [];
      if (userInfo.ecshop_mode === "SIMPLE") {
        goodsMenu = [
          {
            name: "menu.mall_manage.goods_manage",
            permissionType: 0,
            url: "/main/mall/goods-market",
            childrenShow: false,
          },
        ];
      } else {
        goodsMenu = [
          {
            name: "menu.mall_manage.goods_manage",
            permissionType: 0,
            url: "/main/mall/goods",
            childrenShow: false,
          },
          {
            name: "上下架管理",
            permissionType: 0,
            url: "/main/mall/goods-market",
            childrenShow: false,
          },
        ];
        unitGoodsMenu = [
          {
            name: "星球商品赋能",
            permissionType: 0,
            url: "/main/planet-cooperation/unit/product-list",
            childrenShow: false,
            children: null,
          },
          {
            name: "星球礼品卡赋能",
            permissionType: 0,
            url: "/main/planet-cooperation/unit/gift-card-list",
            childrenShow: false,
            children: null,
          },
        ];
      }
      let data = JSON.parse(JSON.stringify(dicksMenuData));
      // debugger;
      const shortName = userInfo.app_uat.short_name;
      data = hasCrowdFund(data, shortName);
      data = hasQueryUserLec(data, shortName);

      //不是生态星球，就不显示星球数据分析
      data = hasStarTransaction(data, userInfo.eco_group_member_type);

      /* 生态 START */
      data = hasActivityCrowdFund(data, userInfo.eco_group_member_type);
      data = hasXplanTeam(data, userInfo.eco_group_member_type);
      data = hasActivityMessage(data, userInfo.eco_group_member_type);
      data = hasTeamMessage(data, userInfo.eco_group_member_type);
      data = hasChargeSetting(data, userInfo.eco_group_member_type);
      data = hasXplanStatistics(data, userInfo.eco_group_member_type);

      //数据统计
      data = hasPartnerStatistic(data, userInfo.eco_group_member_type);
      data = hasStatisticDetail(data, userInfo.eco_group_member_type);
      data = hasXplanOverview(data, userInfo.eco_group_member_type);
      data = statisticsManagerhasXplanStatistics(data, userInfo.eco_group_member_type);
      data = hasPartnerData(data, userInfo.eco_group_member_type); //合伙人数据
      data = hasProjectData(data, userInfo.eco_group_member_type); //项目数据

      /* 生态 END */
      // data = hasTeamRelation(data, shortName);
      data = hasLedger(data, shortName);
      data = hasHerActivity(data, shortName);
      data = hasGiftCard(data, shortName);
      // data = hasPermission(data, shortName);

      // 营销插件下是否需要显示“扫码查看积分”页面
      data = hasCheckPoints(data, userInfo.eco_group_member_type);

      // 营销插件下是否需要显示“合伙人申请”页面
      data = hasPartnerApplyFor(data, userInfo.eco_group_member_type);
      console.log("data----", data);

      // 根据后台配置的权限选择是否显示角色权限管理菜单
      data = hasRolePermissionMenu(data, permission);

      const routeNodes = {};
      // 景区，目前为hardcode
      const hasTicketStatus = hasTicket(shortName);
      const isCreateGeekStatus = isCreateGeek(userInfo.sys_mode);
      const hasPublishWhiteListStatus = openPublishWhitelist(shortName);
      data.forEach((item) => {
        if (item.url) {
          routeNodes[item.url.toLocaleLowerCase()] = item;
        }
        if (item.children) {
          item.children.forEach((subitem) => {
            if (subitem.url) {
              routeNodes[subitem.url.toLocaleLowerCase()] = subitem;
              routeNodes[subitem.url.toLocaleLowerCase()].father = item;
            }
          });
        }
      });

      permission.forEach((item) => {
        if (!item.path) return;
        const path = item.path.toLocaleLowerCase();
        // 遇到带有 item.isHard 标记的路由，只验证hardcode
        if (routeNodes[path]) {
          const hasTicket = hasTicketStatus && routeNodes[path].isHard === "ticket";
          const hasGeek = isCreateGeekStatus && routeNodes[path].isCreateGeek === "open";
          const unHasTicket = routeNodes[path].isHard !== "ticket";
          const unHasGeek = routeNodes[path].isCreateGeek !== "open";
          const hasPublishWhiteList = hasPublishWhiteListStatus && routeNodes[path].openWhiteList === "open";
          const unHasPublishWhiteList = routeNodes[path].openWhiteList !== "open";
          if (hasTicket || hasGeek || hasPublishWhiteList || (unHasTicket && unHasGeek && unHasPublishWhiteList)) {
            routeNodes[path].permissionType = 1;
            if (routeNodes[path].father) {
              routeNodes[path].father.permissionType = 1;
            }
          }
        }
        // ticket 只有
      });
      store.commit("setLeftMenu", data);
      store.commit("setTopMenu", false);
      store.commit("setType", "app");
    },
    // async getAppDetails(store) {
    //   let { data } = await PublicApi.loadMenu();
    //   store.commit("setLeftMenu", data);
    //   store.commit("setTopMenu", true);
    //   store.commit("setType", "details");
    // },
  },
};
