/*
 * @Author: sky.li
 * @LastEditors  : sky.li
 * @Description:
 * @可以输入预定的版权声明、个性签名、空行等
 */
import axios from "axios";

let base = "/v1/merculetManagement";

let urlBase;
let docBase;
let scBase;
let walletBaseUrlH5;
let kolBaseUrlH5;
let picDomain = "https://img.mvpcs.cn/";
let bucket = "merculet-img-cn";
let wid = 2;

// 线上生产环境
if (process.env.VUE_APP_EVENT == "prod") {
  // 线上生产环境 国内
  urlBase = "https://mb-mgnt.merculet.cn";
  docBase = "https://mb-helpcenter.merculet.cn";
  scBase = "https://scorecommunity.merculet.cn";
  kolBaseUrlH5 = "https://community.merculet.cn";
  walletBaseUrlH5 = "https://w.merculet.cn";
  picDomain = "https://img.merculet.cn/";
} else if (process.env.VUE_APP_EVENT == "preprod") {
  // 预生产
  if (process.env.VUE_APP_LANG == "en") {
    urlBase = "https://preprodmerculet-mgnt.merculet.io";
    docBase = "https://merculet-helpcenter.merculet.io";
  } else {
    urlBase = "https://preprodmb-mgnt.magicwindow.cn";
    docBase = "https://mb-helpcenter.merculet.cn";
  }
  wid = 2;
  scBase = "https://scorecommunity.merculet.cn";
  kolBaseUrlH5 = "https://community.merculet.cn";
  walletBaseUrlH5 = "https://w.merculet.cn";
  picDomain = "https://img.merculet.cn/";
} else if (process.env.VUE_APP_EVENT == "dev-java") {
  console.log("process.env.VUE_APP_EVENT", process.env.VUE_APP_EVENT)
  urlBase = "http://merculet-mgnt-cn.mvpcs.cn";
  docBase = "http://mb-helpcenter.mvpcs.cn";

  wid = 7;
  scBase = "https://score-community.mvpcs.cn";
  kolBaseUrlH5 = "https://tokencn-kol.mvpcs.cn";
  // walletBaseUrlH5 = 'https://wallet-cn.mvpcs.cn'
  walletBaseUrlH5 = "https://merculet-auth.mvpcs.cn";
  bucket = "merculet-img-cn-test";

  urlBase = "http://192.168.255.214:15062";
} else if (process.env.VUE_APP_EVENT === "alpha") {
  // 线上生产环境 国内

  urlBase = "https://mb-mgnt.merculet.cn/alpha";
  docBase = "https://mb-helpcenter.merculet.cn";
  scBase = "https://scorecommunity.merculet.cn/alpha";
  kolBaseUrlH5 = "https://community.merculet.cn/alpha";
  walletBaseUrlH5 = "https://w.merculet.cn/alpha";
  picDomain = "https://img.merculet.cn/";

  base = "/v1/merculetManagement";
} else {
  // 测试版

  urlBase = "https://merculet-mgnt-cn.mvpcs.cn";
  // urlBase = "http://192.168.255.215:15062";
  docBase = "http://mb-helpcenter.mvpcs.cn";
  wid = 7;
  scBase = "https://score-community.mvpcs.cn";
  kolBaseUrlH5 = "https://tokencn-kol.mvpcs.cn";
  // walletBaseUrlH5 = 'https://wallet-cn.mvpcs.cn'
  walletBaseUrlH5 = "https://merculet-auth.mvpcs.cn";
  bucket = "merculet-img-cn-test";
}

let config = {
  requestRemoteIp: () => {
    // 动态获取
    return new Promise((resolve, reject) => {
      axios
        .get(urlBase + "/v1/merculetManagement/setting/getDomainConfig")
        .then(
          (response) => {
            if (response.data.code === 0 && response.data.data) {
              let data = response.data.data;
              walletBaseUrlH5 = "https://" + data.auth_domain;
              scBase = "https://" + data.community_domain;
              kolBaseUrlH5 = "https://" + data.h5_domain;
            }
            resolve();
          },
          (err) => {
            reject();
          }
        );
    });
  },
};

urlBase = "";

export {
  base,
  urlBase,
  docBase,
  scBase,
  wid,
  walletBaseUrlH5,
  kolBaseUrlH5,
  picDomain,
  bucket,
  config,
};
