/*
 * @Author: your name
 * @Date: 2021-06-25 15:46:22
 * @LastEditTime: 2021-11-19 11:00:04
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /mw-merculet-saas-web/src/router/eco.js 嘿嘿
 */

export default {
  path: "eco",
  name: "生态",
  component: () => import("@/components/main-children.vue"),
  children: [
    {
      path: "role-manage",
      meta: {
        name: "生态等级身份管理",
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/eco/role-manage.vue"),
    },
    {
      path: "X-team-settings",
      meta: {
        name: "X计划战队设置",
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/eco/X-team-settings.vue"),
    },
    {
      path: "management-award-list",
      meta: {
        name: "管理奖设置",
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/eco/management-award-list.vue"),
    },
    {
      path: "eco-team-manage",
      meta: {
        name: "生态战队设置",
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/eco/eco-team-manage.vue"),
    },
    {
      path: "eco-role-distribution-manage",
      meta: {
        name: "身份分销设置",
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/eco/role-distribution-settings.vue"),
    },
    {
      path: "eco-identity-management",
      meta: {
        name: "生态用户身份管理",
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/eco/identity-management.vue"),
    },
    {
      path: "eco-identity-management-grade",
      meta: {
        name: "授予生态用户等级",
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/eco/identity-management-grade.vue"),
    },
    {
      path: "eco-identity-management-detail",
      meta: {
        name: "授予生态用户详情",
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/eco/identity-managementt-detail.vue"),
    },
    {
      path: "eco-relation",
      meta: {
        name: "生态关系",
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/eco/eco-relation.vue"),
    },
    {
      path: "eco-relation-import",
      meta: {
        name: "预导入生态关系",
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/eco/eco-relation-import.vue"),
    },
    {
      path: "eco-users",
      meta: {
        name: "生态用户",
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/eco/eco-users.vue"),
    },
    {
      path: "eco-user-detail",
      meta: {
        name: "生态用户详情",
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/eco/eco-user-detail.vue"),
    },
    {
      path: "partner-applyFor-detail",
      meta: {
        name: "合伙人申请详情",
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/eco/partner-applyFor-detail.vue"),
    },
    {
      path: "eco-xplan-team",
      meta: {
        name: "X计划战队",
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/eco/eco-xplan-team.vue"),
    },
    {
      path: "team-info-summary",
      meta: {
        name: "战队信息汇总",
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/components/team-info-summary/list.vue"),
    },
    {
      path: "team-relation",
      meta: {
        name: "战队关系图",
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/components/relation-tree/index.vue"),
    },
    {
      path: "xplan-statistics",
      meta: {
        name: "X计划统计",
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/components/xplan-statistics/list.vue"),
    },
    {
      path: "xplan-statistics/refund-detail",
      meta: {
        name: "退款详情",
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () =>
        import("@/components/xplan-statistics/refund-detail.vue"),
    },
    {
      path: "eco-xplan-detail",
      meta: {
        name: "X计划战队详情",
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/eco/eco-xplan-detail.vue"),
    },
    {
      path: "eco-xplan-team-earnings-record",
      meta: {
        name: "X计划战队收益记录",
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/eco/eco-xplan-team-earnings-record.vue"),
    },
    {
      path: "join-eco",
      meta: {
        name: "加入生态",
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/eco/join-eco.vue"),
    },
    {
      path: "star-list",
      meta: {
        name: "星球列表",
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/eco/star-list.vue"),
    },
    {
      path: "star-account-detail",
      meta: {
        name: "星球详情",
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/eco/star-account-detail.vue"),
    },
    {
      path: "eco-apply-list",
      meta: {
        name: "生态申请",
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/eco/eco-apply-list.vue"),
    },
    {
      path: "eco-apply-detail",
      meta: {
        name: "生态申请详情",
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/eco/eco-apply-detail.vue"),
    },
    {
      path: "invitation-code-management",
      meta: {
        name: "邀请码管理",
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/eco/invitation-code-management.vue"),
    },
    {
      path: "dismiss-record-list",
      meta: {
        name: "解除记录",
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/eco/dismiss-record-list.vue"),
    },
    {
      path: "appeal-record",
      meta: {
        name: "申诉记录",
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/eco/appeal-record.vue"),
    },
    {
      path: "appeal-record-detail",
      meta: {
        name: "申诉详情",
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/eco/appeal-record-detail.vue"),
    },
    {
      path: "appeal-record-exceed-detail",
      meta: {
        name: "超期提现详情",
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/eco/appeal-record-exceed-detail.vue"),
    },
    {
      path: "user-withdrawal-application",
      name: "用户提现申请",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/eco/user-withdrawal-application.vue"),
    },
    {
      path: "user-withdrawal-application-detail",
      name: "用户提现详情",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () =>
        import("@/views/eco/user-withdrawal-application-detail.vue"),
    },
    {
      path: "merchant-withdrawal-application",
      name: "商户提现申请",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () =>
        import("@/views/eco/merchant-withdrawal-application.vue"),
    },
    {
      path: "merchant-withdrawal-application-detail",
      name: "商户提现详情",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () =>
        import("@/views/eco/merchant-withdrawal-application-detail.vue"),
    },
    {
      path: "star-statistic",
      name: "星球统计",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/eco/star-statistic.vue"),
    },
    {
      path: "partner-statistic",
      name: "合伙人统计",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/eco/partner-statistic.vue"),
    },
    {
      path: "partner-applyFor",
      name: "合伙人申请",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/eco/partner-applyFor.vue"),
    },
    {
      path: "partner-statistic-detail",
      name: "合伙人历史详情",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () =>
        import("@/components/common/partner-statistic-detail/list.vue"),
    },
    {
      path: "statistic-detail",
      name: "统计明细",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/eco/statistic-detail.vue"),
    },
    {
      path: "little-angel-manage",
      name: "小天使管理",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/eco/little-angel-manage.vue"),
    },
    {
      path: "project-manage",
      name: "项目管理", // xiangmuguanli
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/eco/project-manage.vue"),
    },
    {
      path: "project-manage-detail",
      name: "项目管理",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/eco/project-manage-detail.vue"),
    },
    {
      path: "eco-star",
      name: "联盟公司数据",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () =>
        import("@/views/dashBoard/qitu-home-page/pages/star.vue"),
    },
    {
      path: "eco-transaction",
      name: "核心交易数据分析",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () =>
        import("@/views/eco/transaction.vue"),
    },
    {
      path: "eco-partner",
      name: "合伙人数据",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () =>
        import("@/views/dashBoard/qitu-home-page/pages/partner.vue"),
    },
    {
      path: "eco-partner-detail",
      name: "合伙人数据-详情",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () =>
        import("@/views/dashBoard/qitu-home-page/pages/partnerDetail.vue"),
    },
    {
      path: "eco-project",
      name: "项目数据",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () =>
        import("@/views/dashBoard/qitu-home-page/pages/project.vue"),
    },
    {
      path: "eco-project-detail",
      name: "项目数据-详情",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () =>
        import("@/views/dashBoard/qitu-home-page/pages/projectDetail.vue"),
    },
    {
      path: "eco-users-data",
      name: "用户数据",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () =>
        import("@/views/dashBoard/qitu-home-page/pages/user.vue"),
    },
  ],
};
