const MainChildren = () => import("@/components/main-children.vue");

//奖励金详情
const bonusDetail = () => import("../views/bonus/bonus-detail");

//新建奖励金
const bonusAdd = () => import("../views/bonus/bonus-add");

// 已结束奖励金
const overBonus = () => import("../views/bonus/over-bonus");

// 瓜分奖金详情
const pastBonusDetail = () => import("../views/bonus/past-bonus-detail");

export default {
  path: "bonus",
  name: "奖励金",
  component: MainChildren,
  children: [
    {
      path: "bonus-detail",
      name: "奖励金管理",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: bonusDetail
    },
    {
      path: "bonus-add",
      name: "配置奖励金",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: bonusAdd
    },
    {
      path: "over-bonus",
      name: "奖励金列表",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: overBonus
    },
    {
      path: "past-bonus-detail",
      name: "奖励金详情",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false
      },
      component: pastBonusDetail
    }
  ]
};
