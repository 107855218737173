/*
 * @Author: sky.li
 * @LastEditors: sky.li
 * @Description:
 * @可以输入预定的版权声明、个性签名、空行等
 */
/**
 * SPT水晶星球大板块
 */
// import SptSimplet from './spt-simplet'
import SptCms from "./spt-cms";
import Market from "./market";

// 活动中心

export default [
  {
    path: "activeCenter",
    name: "menu.spt.activity",
    meta: {
      login: true,
      Authentication: true
    },
    component: () => import("@/views/homePage/index")
  },
  // ...SptSimplet,
  ...SptCms,
  ...Market
];
